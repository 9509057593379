import React from "react";
import { Typography, Backdrop, CircularProgress,Snackbar, } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
export const Loader = ({ backdrop, open }) => {
  return (
    <Backdrop className={backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export const PageHeader = ({ title }) => {
  return (
    <div>
      <Box display="flex" flexDirection="row" p={2} m={1} bgcolor="#e5e8f1">
        <Typography
          style={{
            fontFamily: "Futura",
            fontWeight: "400px",
            fontSize: "1.15rem",
          }}
          variant={"h5"}
        >
          {" "}
          {title}{" "}
        </Typography>
      </Box>
    </div>
  );
};

export const Placeholder = ({ children, placeholder }) => {
  return <div className={placeholder}>{children}</div>;
};

export const CustomSnack = ({color,snackOpen,setSnackOpen,message}) => {
  return(
    <Snackbar
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={1500}
          onClose={() => setSnackOpen(false)}
        >
          <MuiAlert
            onClose={() => setSnackOpen(false)}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
  );
}