import Dashboard from '../components/dashboard/dashboard';
import Search from '../components/enquiry/SearchComponent';
import CreateIssue from '../components/createIssue/CreateIssue';
import ReferrelLinks from '../components/ReferrelLinks/ReferrelLinks';
import PolicyReports from '../components/PolicyReport/PolicyReports';
import PaymentDashBoard from '../components/PaymentDashBoard/PaymentDashBoard';
import MyBusiness from '../components/MyBusiness/MyBusiness';
import AdminPage from '../components/Admin/AdminPage';
import ReferrelDashBoard from '../components/ReferrelDashbord/ReferrelDashBoard'
import RefferalCreate from '../components/ReferrelDashbord/ReferrelCreate'
import RefferalUpdate from '../components/ReferrelDashbord/ReferrelUpdate'
import PolicyDownload from '../components/PolicyReport/PolicyDownload'
import CreateInward from '../components/createInward/CreateInward';
import InwardDashBoard from '../components/inwardDashboard/inwardDashboard';
import EditInward from '../components/editInward/EditInward';
const routes = [
    {
        path: "/statusenquiry",
        component: Search
    },
    {
        path: "/dashboard",
        component: Dashboard
    },
    {
        path: "/createissue",
        component: CreateIssue
    },
    {
        path: "/referrellinks",
        component: ReferrelLinks
    },
    {
        path: "/policyreports",
        component: PolicyReports
    },
    {
        path: "/paymentdashboard",
        component: PaymentDashBoard
    },
    {
        path: "/mybusiness",
        component: MyBusiness
    },
    {
        path: "/userRoles",
        component: AdminPage
    },
    {
        path: "/refferaldashboard",
        component: ReferrelDashBoard
    },
    {
        path: "/refferalcreate",
        component: RefferalCreate
    },
    {
        path: "/refferalupdate/:id",
        component: RefferalUpdate
    },
    {
        path: "/policydownload",
        component: PolicyDownload
    },
    {
        path: "/createInward",
        component: CreateInward,
    },
    {
        path: "/inwardDashBoard",
        component: InwardDashBoard,
    },
    {
        path: "/updateInward/:inwardNo",
        component: EditInward,
    },
];

export default routes;