import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import ListIcon from "@material-ui/icons/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import logo from "./star.png";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from "clsx";
import InfoSharpIcon from "@material-ui/icons/InfoSharp";
import BugReportSharpIcon from "@material-ui/icons/BugReportSharp";
import HomeIcon from "@material-ui/icons/Home";
import "./sidebar.css";
import LockIcon from "@material-ui/icons/Lock";
import CloseIcon from "@material-ui/icons/Close";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  DialogActions,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  FormHelperText,
  Snackbar,
  Typography
} from "@material-ui/core";
import axios from "axios";
import { RESET_PASSWORD } from "../../config/config";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import MuiAlert from "@material-ui/lab/Alert";
let drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  image: {
    marginLeft: "20px",
    marginTop: "20px"
  },
  drawerPaper: {
    backgroundColor: "#007bff",
    borderRight: "none",
    backgroundImage: "linear-gradient(#2252e5,#01afee)",
    color: "white",
    width: drawerWidth
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  boxForlist: {
    marginLeft: 10,
    marginRight: 25,
    marginTop: 8
  },
  margin: {
    margin: theme.spacing(1)
  },
  textField: {
    width: "47ch",
    height: "65px"
  },
  icon: {
    marginRight: "10px"
  },
  fontSizing: {
    fontSize: ".875rem"
  }
}));

export default function Sidebar(props) {
  const classes = useStyles();
  props.drawerOpen ? (drawerWidth = 240) : (drawerWidth = 0);
  const [openDialog, setOpenDialog] = useState(false);
  const [isReferrer, setIsReferrer] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [activeError, setActiveError] = useState(false);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [notifyDialog, setNotifyDialog] = useState(false);
  const listItems = [
    {
      title: "Issue Dashboard",
      icon: "dashBoard",
      url: "/dashboard",
      roles: ["Agent", "L1User", "L2User"],
      show: false
    },
    {
      title: "Proposal Status Enquiry",
      icon: "enquiry",
      url: "/statusenquiry",
      roles: ["Agent", "L1User", "L2User"],
      show: false
    },
    {
      title: "Create Issue",
      icon: "bug",
      url: "/createissue",
      roles: ["Agent"],
      show: false
    },
    {
      title: "Home",
      icon: "myBusiness",
      url: "/mybusiness",
      roles: [],
      show: false
    },
    {
      title: "Referral DashBoard",
      icon: "dashBoard",
      url: "/refferaldashboard",
      roles: ["Admin"],
      show: false
    },
    {
      title: "Policy DashBoard",
      icon: "policyReports",
      url: "/policyreports",
      roles: ["Referral", "Admin", "referrer2"],
      show: false
    },
    {
      title: "Payment DashBoard",
      icon: "payments",
      url: "/paymentdashboard",
      roles: ["Referral", "Admin", "referrer2"],
      show: false
    },
    {
      title: "Referral Links",
      icon: "policyLink",
      url: "/referrellinks",
      roles: ["Referral", "referrer2"],
      show: false
    },
    {
      title: "User Roles Upload",
      icon: "CloudUploadIcon",
      url: "/userRoles",
      roles: ["UserUpload"],
      show: false
    },
    {
      title: "Policy Doc Download",
      icon: "CloudDownloadIcon",
      url: "/policydownload",
      roles: ["Admin", "referrer2"],
      show: false
    },
    {
      title: "Inward Dashboard",
      icon: "dashBoard",
      url: "/inwardDashBoard",
      roles: ["BranchUser", "CorporateUser"],
      show: false
    },
  ];

  const role = sessionStorage.getItem("role");
  const isFirstTimeLogin = sessionStorage.getItem("firstTimeLogin");
  useEffect(() => {
    if (role === "Referral" || role === "referrer2" || role === "Admin") {
      setIsReferrer(true);
    }
    if (isFirstTimeLogin == "true") {
      setOpenDialog(true);
    }
  });

  listItems.map(item => {
    if (item.roles.includes(role)) {
      item.show = true;
    }
  });
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const handleSnackClose = (event, reason) => {
    setSnackOpen(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleSubmit = () => {
    setActiveError(true);
    if (newPassword === confirmPassword && oldPassword !== "") {
      let userId = sessionStorage.getItem("userId");
      axios({
        method: "post",
        url: RESET_PASSWORD,
        data: {
          newPassword,
          oldPassword,
          userId
        },
        headers: { token: sessionStorage.getItem("token") }
      })
        .then(response => {
          if (response.status === 200) {
            setOpenDialog(false);
            setNotifyDialog(!notifyDialog);
            sessionStorage.removeItem("firstTimeLogin");
          } else {
            setSnackOpen(true);
            setMessage("Update Failed, !Please Try Again");
            setSnack(true);
            setColor(false);
          }
        })
        .catch(error => {
          if (error.response.data.errorMessages[0] === "Invalid old password") {
            setSnackOpen(true);
            setMessage(error.response.data.errorMessages[0]);
            setSnack(true);
            setColor(false);
          } else {
            setSnackOpen(true);
            setMessage("Update Failed, !Please Try Again");
            setSnack(true);
            setColor(false);
          }
        });
    }
  };

  const handleNotifyClose = () => {
    setNotifyDialog(!notifyDialog);
    sessionStorage.clear();
    props.history.push('/');
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleOldPassword = event => {
    setShowOldPassword(!showOldPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const getIcon = name => {
    switch (name) {
      case "dashBoard":
        return <ListIcon className={classes.icon} />;
      case "enquiry":
        return <InfoSharpIcon className={classes.icon} />;
      case "bug":
        return <BugReportSharpIcon className={classes.icon} />;
      case "myBusiness":
        return <HomeIcon className={classes.icon} />;
      case "policyLink":
        return <ListIcon className={classes.icon} />;
      case "policyReports":
        return <ListIcon className={classes.icon} />;
      case "payments":
        return <ListIcon className={classes.icon} />;
      case "CloudUploadIcon":
        return <CloudUploadIcon className={classes.icon} />;
      case "CloudDownloadIcon":
        return <CloudDownloadIcon className={classes.icon} />;
    }
  };

  return (
    <div id="grad1">
      {snack && (
        <Snackbar
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right"
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
        >
          <MuiAlert
            onClose={handleSnackClose}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      <Drawer
        className={classes.drawer}
        style={{ width: drawerWidth }}
        variant="persistent"
        anchor="left"
        open={props.drawerOpen}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <img
          className={classes.image}
          src={logo}
          alt="star logo"
          width="116"
          height="56"
        ></img>
        <List className={classes.boxForlist}>
          {listItems.map((item, index) => {
            if (item.show) {
              return (
                <ListItem
                  key={index}
                  selected={props.location.pathname.includes(item.url)}
                  className={"spacing"}
                  button
                  onClick={() => props.history.push(item.url)}
                >
                  {getIcon(item.icon)}
                  <ListItemText className={classes.fontSizing}>
                    {item.title}
                  </ListItemText>
                </ListItem>
              );
            } else {
              return;
            }
          })}
          {isReferrer ? (
            <ListItem
              className={"spacing"}
              button
              onClick={() => handleOpenDialog()}
            >
              <LockIcon className={classes.icon} />{" "}
              <ListItemText className={classes.fontSizing}>
                Password Change
              </ListItemText>
            </ListItem>
          ) : null}
        </List>
      </Drawer>
      {openDialog ? (
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          style={{ marginLeft: "450px", width: "500px", overflow: "hidden" }}
        >
          <DialogTitle id="alert-dialog-title" style={{ paddingLeft: "30px" }}>
            <strong>Change Password</strong>
            {isFirstTimeLogin != "true" && (
            <Button
              simple
              key="close"
              aria-label="Close"
              onClick={() => handleDialogClose()}
              style={{
                color: `#000`,
                float: `right`,
                marginTop: "-5px"
              }}
            >
              <CloseIcon />
            </Button>
              )}
          </DialogTitle>

          <DialogContent style={{}}>
            <Grid container spacing={1} style={{}}>
              <Grid item xs={12} sm={12}>
                {/* <TextField style={{ width: '100%' }} id="oldPassword" name="oldPassword" value={oldPassword} onChange={e => setOldPassword(e.target.value)} label="Old Password *" variant="outlined" /> </Grid> */}
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Old Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showOldPassword ? "text" : "password"}
                    value={oldPassword}
                    onChange={e => setOldPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleOldPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={110}
                  />
                  <FormHelperText
                    id="filled-weight-helper-text"
                    error={activeError & (oldPassword === "")}>
                    {activeError & (oldPassword === "")
                      ? "Old Pssword is Mandatory"
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={120}
                  />
                  <FormHelperText
                    id="filled-weight-helper-text"
                    error={activeError & (newPassword === "")}
                  >
                    {activeError & (newPassword === "")
                      ? "New Pssword is Mandatory"
                      : " "}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl
                  className={clsx(classes.margin, classes.textField)}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Confirm Password *
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={e => setConfirmPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={140}
                  />
                  <FormHelperText
                    id="filled-weight-helper-text"
                    error={
                      activeError & (newPassword !== confirmPassword) ||
                      confirmPassword === "" || newPassword !== confirmPassword
                    }
                  >
                    {activeError & (confirmPassword === "")
                      ? "Confirm Password is Mandatory"
                      : newPassword !== confirmPassword
                        ? "New Password and Confirm Passowrd should be same"
                        : " "}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <DialogActions
              style={{ paddingBottom: "20px", paddingLeft: "10px" }}
            >
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  color="primary"
                >
                  UPDATE
                </Button>
              </Grid>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : null}

      {notifyDialog ? (
        <Dialog
          open={notifyDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText style={{ marginBottom: '-12px' }} id="alert-dialog-description">
              <CheckCircleIcon style={{ color: 'green', fontSize: '75px', marginLeft: '133px' }}></CheckCircleIcon>
            </DialogContentText>
            <DialogTitle style={{ textAlign: 'center' }} id="alert-dialog-title">
              {"Password Updated!"}
            </DialogTitle>
            <Typography style={{ textAlign: 'center' }}>Your password has been changed successfully<br />
                            Use your new passowrd to login</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleNotifyClose} color="primary" autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </div>
  );
}
