import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Paper,
  Typography,
  Snackbar,
  Backdrop,
  CircularProgress, Select, MenuItem, FormControl, InputLabel,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import {
  staticPolicyData,
  isValidValue,
  formatDateDDMMYYYY,
  isValidValueDate,
  formatMMDDYYY
} from "./helper";
import { policyTypesList, inwardTypeList, modeOfPayList } from "./formData";
import useStyles from "./style";
import { CREATE_INWARD, FETCH_ALL_PRODUCTS, GET_POLICY_DETAILS } from "../../config/config.js";


export default function CreateInward(props) {
  const classes = useStyles();

  const [officeCode, setOfficeCode] = useState(
    sessionStorage.getItem("officeCode")
  );

  const [officeName, setOfficeName] = useState(
    sessionStorage.getItem("officeName")
  );
  const [policyInfo, setPolicyInfo] = useState({});
  const [productList, setProductList] = useState([]);

  const [open, setOpen] = useState(true);
  const [activeError, setActiveError] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [color, setColor] = useState(true);
  const [message, setMessage] = useState("");

  const [disableField, setDisabled] = useState({
    customerName: false,
    customerMobile: false,
    customerCode: false,
    productName: false,
    smName: false,
    smCode: false,
    agentName: false,
    agentCode: false,
    modeOfPay: false,
  });
  const [dynamicLabel, setLabels] = useState({
    policyType: "Select Policy Type",
    inwardType: "Select Inward Type",
    productName: "Select Product Name",
    modeOfPay: "Select Mode of Pay",
  });

  const [inwardType, setInwardType] = useState("");

  const [inwardDate, setInwardDate] = useState(null);

  const [premiumReceiptDate, setPremiumReceiptDate] = useState("");

  const [customerName, setCustomerName] = useState("");

  const [customerMobile, setCustomerMobile] = useState("");

  const [customerCode, setCustomerCode] = useState("");

  const [policyType, setPolicyType] = useState("");

  const [policyNo, setPolicyNo] = useState("");

  const [proposalNo, setProposalNo] = useState("");

  const [productName, setProductName] = useState("");

  const [SMName, setSMName] = useState("");

  const [SMCode, setSMCode] = useState("");

  const [agentName, setAgentName] = useState("");

  const [agentCode, setAgentCode] = useState("");
  const [refRemark, setRemarks] = useState("");

  const [intermediaryReceivedDate, setReceivedDate] = useState(null);
  const [modeOfPay, setModeOfPay] = useState("");
  const [amountCollected, setAmountCollected] = useState("");

  useEffect(function () {
    getProductList();
    if (sessionStorage.getItem("token") === null) {
      props.history.push("/inwardDashBoard");
    }
  }, []);

  useEffect(() => {
    if (!snackOpen && message === "Inward created Successfully") {
      props.history.push("/inwardDashBoard");
    }
  }, [message, snackOpen]);

  const getProductList = () => {
    axios({
      method: "get",
      url: FETCH_ALL_PRODUCTS,
    })
      .then((response) => {
        const data =
          response?.data?.masterProductDetails?.map((option) => ({
            productName: `${option?.productName?.replaceAll("(Individual)", "") ||
              option?.productName?.replaceAll("Floater", "")
              }${option?.productType === "I" ? "(Individual)" : "(Floater)"}`,
          })) || [];

        setProductList([...data]);
        setOpen(false);
      })
      .catch((err) => {
        setOpen(false);
      });
  };

  const getPolicyInfo = (policyId) => {
    axios({
      method: "get",
      url: GET_POLICY_DETAILS + policyId,
    })
      .then((response) => {
        const {
          customerName,
          customerMobile,
          customerCode,
          proposalNO,
          smCode,
          smName,
          agentCode,
          agentName,
          productName,
          policyNumber,
          modeOfPay,
        } = response?.data;
        setPolicyInfo(response?.data);
        setCustomerName(customerName || "");
        setCustomerMobile(customerMobile || "");
        setProposalNo(proposalNO || "");
        setSMCode(smCode || "");
        setSMName(smName || "");
        setAgentCode(agentCode || "");
        setAgentName(agentName || "");
        setProductName(productName || "");
        setPolicyNo(policyNumber || "");
        setCustomerCode(customerCode || "");
        setModeOfPay(modeOfPay || "");
        setDisabled({
          ...disableField,
          ...(isValidValue(customerName) && { customerName: true }),
          ...(isValidValue(customerMobile) && { customerMobile: true }),
          ...(isValidValue(customerCode) && { customerCode: true }),
          ...(isValidValue(productName) && { productName: true }),
          ...(isValidValue(smName) && { smName: true }),
          ...(isValidValue(smCode) && { smCode: true }),
          ...(isValidValue(agentName) && { agentName: true }),
          ...(isValidValue(agentCode) && { agentCode: true }),
          ...(isValidValue(modeOfPay) && { modeOfPay: true }),
        });
        setLabels({
          ...dynamicLabel,
          ...(isValidValue(productName) && { productName: "Product Name" }),
          ...(isValidValue(modeOfPay) && { modeOfPay: "Mode of Pay" }),
        });
        setOpen(false);
      })
      .catch((error) => {
        setPolicyInfo("");
        setCustomerName("");
        setCustomerMobile("");
        setSMCode("");
        setSMName("");
        setAgentCode("");
        setAgentName("");
        setProductName("");
        setModeOfPay("");
        setDisabled(false);
        setOpen(false);
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            alert("session expired");
            sessionStorage.clear();
            props.history.push("/");
          } else if (error.response.status === 401) {
            setSnackOpen(true);
            setMessage("Unauthorized");
            setSnack(true);
            setColor(false);
          }
          else if (error?.response?.data?.errorMessages?.length > 0) {
            setSnackOpen(true);
            setMessage(
              error?.response?.data?.errorMessages[0] ||
              "Something Went Wrong"
            );
            setSnack(true);
            setColor(false);
          } else {
            setSnackOpen(true);
            setMessage(error?.response?.error || "Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        } else {
          setSnackOpen(true);
          setMessage(error?.response?.error || "Something Went Wrong");
          setSnack(true);
          setColor(false);
        }
      });
  };

  const resetForm = (type) => {
    setPolicyInfo("");
    type === "policyNo" && setProposalNo("");
    type === "proposalNo" && setPolicyNo("");
    setPremiumReceiptDate(null);
    setCustomerName("");
    setCustomerMobile("");
    setCustomerCode("");
    setProductName("");
    setSMName("");
    setSMCode("");
    setAgentName("");
    setAgentCode("");
    setModeOfPay("");
    setDisabled({
      customerName: false,
      customerMobile: false,
      customerCode: false,
      productName: false,
      smName: false,
      smCode: false,
      agentName: false,
      agentCode: false,
      modeOfPay: false,
    });
    setLabels({
      ...dynamicLabel,
      productName: "Select Product Name",
      modeOfPay: "Select Mode of Pay",
    });
  };

  const checkPremiumDate = (date) => {
    if (isValidValue(date)) {
      if (
        isValidValueDate(date) &&
        date < new Date() &&
        date !== null &&
        date?.toString()
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const checkIntermediaryDate = (date) => {
    if (isValidValue(date)) {
      if (
        isValidValueDate(date) &&
        date < new Date() &&
        date !== null &&
        date?.toString() &&
        isValidValueDate(intermediaryReceivedDate) &&
        isValidValueDate(inwardDate) &&
        moment(formatMMDDYYY(intermediaryReceivedDate)).isSameOrBefore(formatMMDDYYY(inwardDate))
      ) {
        return true;
      }
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userId = sessionStorage.getItem("userId");

    const payload = {
      ...policyInfo,
      ...(Object.keys(policyInfo).length === 0 && { ...staticPolicyData }),
      officeCode: officeCode || "",
      officeName: officeName || "",
      inwardType: inwardType || "",
      inwardDate: isValidValueDate(inwardDate)
        ? formatDateDDMMYYYY(inwardDate)
        : "",
      premiumReceiptDate: isValidValueDate(premiumReceiptDate)
        ? formatDateDDMMYYYY(premiumReceiptDate)
        : "",
      customerName: customerName || "",
      customerMobile: customerMobile || "",
      customerCode: customerCode || "",
      policyType: policyType || "",
      policyNumber: policyNo || "",
      proposalNumber: proposalNo || "",
      productName: productName || "",
      smName: SMName || "",
      smCode: SMCode || "",
      agentName: agentName || "",
      agentCode: agentCode || "",
      referenceRemark: refRemark || "",
      createdBy: userId || "",
      updatedBy: userId || "",
      // totalPremium: totalPremium || "",
      modeOfPay: modeOfPay || "",
      intermediaryReceivedDate: isValidValueDate(intermediaryReceivedDate)
        ? formatDateDDMMYYYY(intermediaryReceivedDate)
        : "",
      amountCollected: amountCollected || "",
    };

    const validateField =
      (Object?.keys(policyInfo)?.length === 0
        ? isValidValue(customerName)
        : true) &&
      isValidValue(customerMobile) && customerMobile?.length === 10
      &&
      isValidValue(refRemark) &&
      /^[A-Za-z0-9- /\/.,;:]+$/g.test(refRemark) &&
      isValidValueDate(inwardDate) &&
      inwardDate < new Date() &&
      isValidValue(inwardType) &&
      policyType !== "Select Type" &&
      modeOfPay !== "Select Type" &&
      checkPremiumDate(premiumReceiptDate) &&
      checkIntermediaryDate(intermediaryReceivedDate) &&
      /^[0-9.,]*$/g.test(amountCollected);

    setSnackOpen(false);

    if (validateField) {
      setOpen(true);
      axios({
        method: "post",
        url: CREATE_INWARD,
        data: payload,
        headers: { token: sessionStorage?.getItem("token") },
      })
        .then((response) => {
          if (response.data.status === "SUCCESS") {
            setSnackOpen(true);
            setOpen(false);
            setMessage("Inward created Successfully");
            setSnack(true);
            setColor(true);
            setOfficeCode("");
            setOfficeName("");
            setInwardType("");
            setInwardDate(null);
            setPremiumReceiptDate(null);
            setCustomerName("");
            setCustomerMobile("");
            setPolicyNo("");
            setProposalNo("");
            setProductName("");
            setSMName("");
            setSMCode("");
            setAgentName("");
            setAgentCode("");
            setRemarks("");
            setModeOfPay("");
            setReceivedDate(null);
            setAmountCollected("");
            setActiveError(false);
            // props.history.push("/inwardDashBoard");
          }
        })
        .catch((error) => {
          setOpen(false);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              alert("session expired");
              sessionStorage.clear();
              props.history.push("/inwardDashboard");
            } else if (error?.response?.data?.errorMessages?.length > 0) {
              setSnackOpen(true);
              setMessage(
                error?.response?.data?.errorMessages[0] ||
                "Something Went Wrong"
              );
              setSnack(true);
              setColor(false);
            } else {
              setSnackOpen(true);
              setMessage(error?.response?.error || "Something Went Wrong");
              setSnack(true);
              setColor(false);
            }
          } else {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        });
    } else {
      setActiveError(true);
    }
  };

  const Placeholder = ({ children }) => {
    return <div className={classes.placeholder}>{children}</div>;
  };

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Box display="flex" flexDirection="row" p={2} m={1} bgcolor="#e5e8f1">
          <Typography
            style={{
              fontFamily: "Futura",
              fontWeight: "400px",
              fontSize: "1.15rem",
            }}
            variant={"h5"}
          >
            {" "}
            Create Inward{" "}
          </Typography>
        </Box>
      </div>
      {snack && (
        <Snackbar
          onMouseEnter={() => {
            setTimeout(() => {
              setSnackOpen(false);
            }, 100);
          }}
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={1500}
          onClose={() => setSnackOpen(false)}
        >
          <MuiAlert
            onClose={() => setSnackOpen(false)}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      <Paper className={classes.createIssueFrom}>
        <form onSubmit={handleSubmit} method="post">
          <div className={classes.createIssue}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  inputProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="officeCode"
                  name="officeCode"
                  value={officeCode}
                  onChange={(e) => {
                    setOfficeCode(e.target.value);
                  }}
                  label="Office Code"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  inputProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="officeName"
                  name="officeName"
                  value={officeName}
                  onChange={(e) => {
                    setOfficeName(e.target.value);
                  }}
                  label="Office Name"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  {dynamicLabel.policyType === "Policy Type" && (
                    <InputLabel id="demo-simple-select-outlined-label">
                      Policy Type
                    </InputLabel>
                  )}
                  <Select
                    value={policyType}
                    label="Policy Type"
                    style={{ width: "100%" }}
                    displayEmpty
                    placeholder="Select"
                    onFocus={() => {
                      setLabels({ ...dynamicLabel, policyType: "Policy Type" });
                    }}
                    onBlur={() => {
                      if (policyType === "") {
                        setLabels({ ...dynamicLabel, policyType: "Select Policy Type" });
                      }
                    }}
                    onChange={(event) => setPolicyType(event.target.value)}
                    renderValue={
                      policyType !== ""
                        ? undefined
                        : () => <Placeholder>Select Policy Type</Placeholder>
                    }
                    error={
                      activeError &&
                      isValidValue(policyType) &&
                      policyType === "Select Type"
                    }
                    helperText={
                      activeError &&
                        isValidValue(policyType) &&
                        policyType === "Select Type"
                        ? "Policy Type is not valid"
                        : ""
                    }
                  >
                    {policyTypesList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  required
                  id="outlined-select-currency-native"
                  value={inwardType}
                  inputValue={inwardType}
                  options={inwardTypeList}
                  getOptionLabel={(option) => option?.label || ""}
                  onChange={(event, value, reason) => {
                    if (reason === "select-option") {
                      setInwardType(value?.value);
                    } else if (reason === "clear") {
                      setInwardType("");
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setInwardType(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      onFocus={() => setLabels({ ...dynamicLabel, inwardType: "Inward Type" })}
                      onBlur={() => {
                        if (inwardType === "") {
                          setLabels({ ...dynamicLabel, inwardType: "Select Inward Type" });
                        }
                      }}
                      label={dynamicLabel.inwardType}
                      placeholder="Select Inward Type"
                      variant="outlined"
                      error={activeError && inwardType === ""}
                      helperText={
                        activeError && inwardType === ""
                          ? "Inward Type is Mandatory"
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Inward Date"
                      style={{ width: "100%" }}
                      value={inwardDate || null}
                      onChange={(date) => setInwardDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{ shrink: true }}
                      openTo="date"
                      placeholder={"MM/DD/YYYY"}
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      required
                      disableFuture
                      error={
                        (activeError &&
                          (inwardDate === null || inwardDate === "")) ||
                        inwardDate > new Date() ||
                        (inwardDate !== null &&
                          inwardDate.toString() === "Invalid Date")
                      }
                      helperText={
                        activeError & (inwardDate === null || inwardDate === "")
                          ? "Inward Date is Mandatory"
                          : inwardDate > new Date()
                            ? "Inward Date should not be in future"
                            : (inwardDate !== null && inwardDate.toString()) ===
                              "Invalid Date"
                              ? "Invalid Date Format"
                              : ""
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Intermediary Received Date"
                      style={{ width: "100%" }}
                      value={intermediaryReceivedDate || null}
                      onChange={(date) => setReceivedDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{ shrink: true }}
                      openTo="date"
                      placeholder={"MM/DD/YYYY"}
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      disableFuture
                      error={
                        (isValidValueDate(intermediaryReceivedDate) && moment(intermediaryReceivedDate).isAfter(new Date())) ||
                        (intermediaryReceivedDate !== null &&
                          intermediaryReceivedDate.toString() ===
                          "Invalid Date") ||
                        (isValidValueDate(intermediaryReceivedDate) &&
                          isValidValueDate(inwardDate) &&
                          moment(formatMMDDYYY(intermediaryReceivedDate)).isAfter(formatMMDDYYY(inwardDate)))
                      }
                      helperText={
                        (isValidValueDate(intermediaryReceivedDate) && moment(intermediaryReceivedDate).isAfter(new Date()))
                          ? "Intermediary Received Date should not be in future"
                          : (intermediaryReceivedDate !== null &&
                            intermediaryReceivedDate.toString()) ===
                            "Invalid Date"
                            ? "Invalid Date Format"
                            : isValidValueDate(intermediaryReceivedDate) &&
                              isValidValueDate(inwardDate) &&
                              moment(formatMMDDYYY(intermediaryReceivedDate)).isAfter(formatMMDDYYY(inwardDate))
                              ? "Intermediary date cannot be greater then inward date"
                              : ""
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="propNo"
                  name="propNo"
                  value={proposalNo}
                  onChange={(e) => {
                    setProposalNo(e.target.value?.toLocaleUpperCase());
                    if (
                      e.target.value.length >= 23 &&
                      e.target.value?.match(
                        /^[R]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                    ) {
                      setOpen(true);
                      getPolicyInfo(e.target.value?.toLocaleUpperCase());
                    }
                    if (e.target.value.length === 22) {
                      resetForm("proposalNo");
                    }
                    if (e.target.value.length === 0) {
                      resetForm("proposalNo");
                    }
                  }}
                  label="Proposal Number"
                  variant="outlined"
                  error={
                    isValidValue(proposalNo)
                      ? !proposalNo.match(
                        /^[R]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                      : false
                  }
                  helperText={
                    isValidValue(proposalNo)
                      ? !proposalNo.match(
                        /^[R]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                        ? "Invalid proposal number"
                        : ""
                      : ""
                  }
                  inputProps={{
                    maxLength: 23,
                    shrink: true,
                    style: { textTransform: "capitalize" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="polNo"
                  name="polNo"
                  value={policyNo}
                  onChange={(e) => {
                    setPolicyNo(e.target.value?.toLocaleUpperCase());
                    if (
                      e.target.value?.length >= 23 &&
                      e.target.value?.match(
                        /^[P]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                    ) {
                      setOpen(true);
                      getPolicyInfo(e.target.value?.toLocaleUpperCase());
                    }
                    if (e.target.value.length === 22) {
                      resetForm("policyNo");
                    }
                    if (e.target.value.length === 0) {
                      resetForm("policyNo");
                    }
                  }}
                  label="Policy Number"
                  variant="outlined"
                  error={
                    isValidValue(policyNo)
                      ? !policyNo?.match(
                        /^[P]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                      : false
                  }
                  helperText={
                    isValidValue(policyNo)
                      ? !policyNo.match(
                        /^[P]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                        ? "Invalid policy number"
                        : ""
                      : ""
                  }
                  inputProps={{
                    maxLength: 23,
                    shrink: true,
                    style: { textTransform: "capitalize" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Premium Receipt Date"
                      style={{ width: "100%" }}
                      value={premiumReceiptDate || null}
                      onChange={(date) => setPremiumReceiptDate(date)}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture
                      InputLabelProps={{ shrink: true }}
                      openTo="date"
                      placeholder={"MM/DD/YYYY"}
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      error={
                        premiumReceiptDate > new Date() ||
                        (premiumReceiptDate !== null &&
                          premiumReceiptDate.toString() === "Invalid Date")
                      }
                      helperText={
                        premiumReceiptDate > new Date()
                          ? "Premium Date should not be in future"
                          : (premiumReceiptDate !== null &&
                            premiumReceiptDate.toString()) === "Invalid Date"
                            ? "Invalid Date Format"
                            : ""
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  disabled={disableField.customerName}
                  id="custName"
                  name="custName"
                  value={customerName}
                  onChange={(e) => {
                    setCustomerName(e.target.value);
                  }}
                  label="Customer Name"
                  variant="outlined"
                  required={Object?.keys(policyInfo)?.length === 0}
                  error={
                    activeError &&
                    Object?.keys(policyInfo)?.length === 0 &&
                    customerName === ""
                  }
                  helperText={
                    activeError &&
                      Object?.keys(policyInfo)?.length === 0 &&
                      customerName === ""
                      ? "Customer Name is Mandatory"
                      : ""
                  }
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  disabled={disableField.customerMobile}
                  id="custMob"
                  name="custMob"
                  value={customerMobile}
                  onChange={(e) => {
                    setCustomerMobile(e.target.value);
                  }}
                  label="Customer Mobile Number"
                  variant="outlined"
                  required
                  error={
                    (activeError &&
                      customerMobile === "") ||
                    (customerMobile !== "" && customerMobile.length < 10)
                  }
                  helperText={
                    activeError &&
                      customerMobile === ""
                      ? "Customer Mobile number is Mandatory"
                      : customerMobile.length < 10 &&
                        customerMobile !== ""
                        ? "Mobile number is not valid"
                        : ""
                  }
                  inputProps={{ maxLength: 10, shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.customerCode}
                  style={{ width: "100%" }}
                  id="custCode"
                  name="custCode"
                  value={customerCode}
                  onChange={(e) => {
                    setCustomerCode(e.target.value);
                  }}
                  label="Customer Code"
                  variant="outlined"
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disabled={disableField.productName}
                  id="combo-box-demo"
                  value={productName}
                  inputValue={productName}
                  options={productList}
                  getOptionLabel={(option) => option?.productName || ""}
                  onChange={(event, value, reason) => {
                    if (reason === "select-option") {
                      setProductName(value?.productName);
                    } else if (reason === "clear") {
                      setProductName("");
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setProductName(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onFocus={() => setLabels({ ...dynamicLabel, productName: "Product Name" })}
                      onBlur={() => {
                        if (productName === "") {
                          setLabels({ ...dynamicLabel, productName: "Select Product Name" });
                        }
                      }}
                      label={dynamicLabel.productName}
                      placeholder="Select Product Name"
                      variant="outlined"
                    />
                  )}
                />

              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  type="number"
                  id="amountCollected"
                  name="amountCollected"
                  value={amountCollected}
                  onChange={(e) => {
                    setAmountCollected(e.target.value);
                  }}
                  label="Amount Collected"
                  variant="outlined"

                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>

                <Autocomplete
                  disabled={disableField.modeOfPay}
                  id="combo-box-demo"
                  value={modeOfPay}
                  inputValue={modeOfPay}
                  options={modeOfPayList}
                  getOptionLabel={(option) => option?.label || ""}
                  onChange={(event, value, reason) => {
                    if (reason === "select-option") {
                      setModeOfPay(value?.value);
                    } else if (reason === "clear") {
                      setModeOfPay("");
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setModeOfPay(value);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onFocus={() => setLabels({ ...dynamicLabel, modeOfPay: "Mode of Pay" })}
                      onBlur={() => {
                        if (modeOfPay === "") {
                          setLabels({ ...dynamicLabel, modeOfPay: "Select Mode of Pay" });
                        }
                      }}
                      label={dynamicLabel.modeOfPay}
                      placeholder="Select Mode of Pay"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.smName}
                  style={{ width: "100%" }}
                  id="Smname"
                  name="Smname"
                  value={SMName}
                  onChange={(e) => {
                    setSMName(e.target.value);
                  }}
                  label="SM Name"
                  variant="outlined"

                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.smCode}
                  style={{ width: "100%" }}
                  id="SMCode"
                  name="SMCode"
                  value={SMCode}
                  onChange={(e) => {
                    setSMCode(e.target.value);
                  }}
                  label="SM Code"
                  variant="outlined"

                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.agentName}
                  style={{ width: "100%" }}
                  id="agName"
                  name="agName"
                  value={agentName}
                  onChange={(e) => {
                    setAgentName(e.target.value);
                  }}
                  label="Agent Name"
                  variant="outlined"

                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.agentCode}
                  style={{ width: "100%" }}
                  id="agCode"
                  name="agCode"
                  value={agentCode}
                  onChange={(e) => {
                    setAgentCode(e.target.value);
                  }}
                  label="Agent Code"
                  variant="outlined"

                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  multiline
                  rows={4}
                  id="refRemark"
                  required
                  name="refRemark"
                  value={refRemark}
                  onChange={(e) => setRemarks(e.target.value)}
                  label="Reference Remark"
                  variant="outlined"
                  error={
                    activeError || refRemark !== ""
                      ? !/^[A-Za-z0-9- /\/.,;:]+$/g.test(refRemark)
                      : false
                  }
                  helperText={
                    activeError && refRemark === ""
                      ? "Reference Remark is Mandatory"
                      : refRemark !== "" &&
                        refRemark &&
                        !/^[A-Za-z0-9- /\/.,;:]+$/g.test(refRemark)
                        ? "Invalid Reference Remark"
                        : ""
                  }
                  inputProps={{ maxLength: 100, shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  className={classes.button}
                  color="primary"
                >
                  Save{" "}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    </React.Fragment>
  );
}
