import React from "react";
import Word from "../../src/images/Word.png";
import Excel from "../../src/images/Excel.png";
import Jpg from "../../src/images/Jpg.png";
import Pdf from "../../src/images/PDF.svg";

export const getFileExtension3 = (filename) => {
    if (filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "doc" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "docx" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "odt") {
        return (
            <img
                src={Word}
                style={{ width: "30px", paddingRight: "10px" }}
                alt="Word Icon"
            />
        );
    }
    if (filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "xls" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "xlsx") {
        return (
            <img
                src={Excel}
                style={{ width: "30px", paddingRight: "10px" }}
                alt="Excel Icon"
            />
        );
    }

    if (filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "png" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "jpg" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "jpeg" || 
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "jfif" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "pjpeg" ||
        filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "pjp") {
        return (
            <>
                <img
                    src={Jpg}
                    style={{ width: "30px", paddingRight: "10px" }}
                    alt="Jpg Icon"
                />{" "}
            </>
        );
    }
    if (filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2) === "pdf") {
        return (
            <div style={{width: '40px', paddingRight: '5px'}}>
                <img
                    src={Pdf}
                    style={{ width: "30px", paddingRight: "10px" }}
                    alt="Pdf Icon"
                />{" "}
            </div>
        );
    }
};