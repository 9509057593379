import axios from "axios";
import { EXPORT_REFERRALS, PAYMENT_LINK_API, CHECK_POLICY_DOWNLOAD, EDIT_PROPOSAL_LINK, GET_PRODUCTLIST, DISCOUNT_PRODUCTS } from "../config/config";

export const exportReferrals = (filterKey) => {
    let responseObj = axios({
        method: 'get',
        url: EXPORT_REFERRALS,
        params: {
            filterBy: filterKey === 'all' ? null : filterKey
        },
        headers: { token: sessionStorage.getItem('token') },
        responseType: 'blob'
    });

    return responseObj;
}

export const getReferrals = (filterKey) => {
    let responseObj = axios({
        method: 'get',
        url: EXPORT_REFERRALS,
        params: {
            filterBy: filterKey === 'all' ? null : filterKey
        },
        headers: { token: sessionStorage.getItem('token') },
        responseType: 'blob'
    });

    return responseObj;
}

export const generatePaymentLinkApi = (request) => {

    let responseObj = axios({
        method: 'POST',
        url: PAYMENT_LINK_API,
        data: request,
        headers: {  token: sessionStorage.getItem('token')  },
    });

    return responseObj;
}

export const checkPolicDownloadStatus = (referrCode) => {

    let responseObj = axios({
        method: 'GET',
        url: CHECK_POLICY_DOWNLOAD,
        params: {
            referrerCode: referrCode,
        },
        headers: { token: sessionStorage.getItem('token') },
    });

    return responseObj;
}

export const getProposalLinkApi = (id) => {

    let responseObj = axios({
        method: 'POST',
        url: EDIT_PROPOSAL_LINK,
        data: {
            proposalId: id.toString(),
            notificationType: "",
            mobileNo: "",
        },
        headers: {  token: sessionStorage.getItem('token') },
    })

    return responseObj;
}

export const getproductList = async () => {

    let responseObj = axios({
        method: "get",
        url: GET_PRODUCTLIST,
        headers: { token: sessionStorage.getItem("token") }
    }).then(response => {
        return response;
    }).catch(error => {
        return error;
    })

    return responseObj;
}

export const getDiscountedProducts = async () => {

    let responseObj = axios({
        method: "get",
        url: DISCOUNT_PRODUCTS,
        headers: { token: sessionStorage.getItem("token") }
    }).then(response => {
        return response;
    }).catch(error => {
        return error;
    })

    return responseObj;
}