import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Layout from './components/layout/layout';
import Login from './components/login/Login';
import routes from './Routes/route';
import LoginRedirect from './components/login/LoginRedirect';

function App(props) {
  return (
    <div className="App">
      {console.log('props in app.js: ', props)}
      <Router basename={'/dashboardportal'}>
        <Switch>
          <Route path={'/login'} component={Login} />
          <Route path={'/reportIssue/:userId/:token'} component={LoginRedirect} />
          <Route path={'/'} render={props => {
            return (
              <Layout {...props}>
                <Switch>
                  {routes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      component={route.component}
                    />
                  ))}
                  <Redirect from="/" to="/login" />
                </Switch>
              </Layout >
            )
          }} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
