import MUIDataTable from "mui-datatables";
import { Button, Typography, Grid, Backdrop, CircularProgress, TextField, Snackbar, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from "react";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LaunchIcon from '@material-ui/icons/Launch';
import { POLICY_DASHBOARD, GET_USERS, DOWNLOAD_PROPOSAL_FORM } from '../../config/config.js';
import { POLICY_EXPORT, POLICY_EXPORT_ALL } from '../../config/config.js';
import MuiAlert from '@material-ui/lab/Alert';
import '../dashboard/dashboard.css';
import { generatePaymentLinkApi, getProposalLinkApi } from "../../api/referralService.js";
import { Link } from "@material-ui/core";
import SendIcon from '@material-ui/icons/Send';
import { useRef } from "react";

const PolicyReports = (props) => {

    const columns = [
        {
            name: "proposalId", label: "Proposal\u00a0Id",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="Click To View" interactive>
                            <Link style={{ cursor: "pointer" }}>{value}</Link>
                        </Tooltip>
                    )
                }
            }
        },
        {
            name: "proposalNumber", label: "Proposal\u00a0Number",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposalDate", label: "Proposal\u00a0Date",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyNumber", label: "Policy\u00a0Number",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyDate", label: "Policy\u00a0Date",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "premiumAmount", label: "Premium",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyStatus", label: "Policy\u00a0Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyType", label: "Policy\u00a0Type",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "product", label: "Product",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title={value}>
                            <Typography component={'span'} noWrap={true}>
                                {(value !== null && value !== "" && value.length > 20) ? value.substring(0, 20) + "..." : value}
                            </Typography>
                        </Tooltip>
                    )
                }
            }
        },
        {
            name: "partyCode", label: "Party\u00a0Code",
            options: {
                filter: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposerName", label: "Proposer\u00a0Name",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "mobileNumber", label: "Phone",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposerEmail", label: "Proposer\u00a0Email",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposalNumber", label: "Payment\u00a0Link",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="Send Payment Link" interactive>
                            <IconButton disabled={value === '' || value === null || tableMeta.rowData[16] === 'SUCCESS' ? true : false} style={{ marginLeft: '15px', cursor: 'pointer' }} value={tableMeta} onClick={() => generatePaymentLink(tableMeta)}>
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            }
        },
        {
            name: "proposalNumber", label: "Proposal\u00a0Link",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="Open Link" interactive>
                            <IconButton disabled={value === '' || value === null ? false : true} style={{ marginLeft: '15px' }} value={value}>
                                <LaunchIcon onClick={(event) => getProposalLink(event, tableMeta)} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            }
        },
        {
            name: "proposalNumber", label: "Download",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="Download Proposal Form" interactive>
                            <IconButton disabled={value === '' || value === null ? true : false} style={{ marginLeft: '15px' }} value={value}>
                                <CloudDownloadIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            }
        },
        {
            name: "paymentStatus", label: "Proposer\u00a0Email",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },

    ]

    const useStyles = makeStyles((theme) => ({
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        formControl: {
            minWidth: 249,
        },
    }));

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    root: {
                        backgroundColor: "#ffff !important",
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: ` 1px 10px !important`,
                        borderBottom: 'none',
                    },
                    head: {
                        height: '10px !important',
                    },
                    body: {
                        height: '10px !important',
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        textTransform: "uppercase",
                        fontFamily: 'FuturaLight',

                        fontSize: '0.815rem',
                        backgroundColor: "#DCDCDC !important",
                    }
                },
                MuiTypography: {
                    body1: {
                        fontFamily: 'TableData',
                        fontSize: '0.815rem',

                    }
                },
            }

        });
    const [data, setData] = useState([]);
    const [user, setUser] = useState(sessionStorage.getItem('userId'));
    const [discountedReferrals, setDiscountedReferrals] = useState([]);
    const [nonDiscountedReferrals, setNonDiscountedReferrals] = useState([]);
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    let mydate = new Date();
    const [fromDate, setFromDate] = useState(new Date(mydate.setMonth(mydate.getMonth(), mydate.getDate() - 3)));
    const [toDate, setToDate] = useState(new Date());
    const [showSelectUser, setShowSelect] = useState(false)
    const [value, setFilterValue] = useState('');
    const [selectCustomerDetails, setSelectCustomerDetails] = useState('');
    const [snackOpen, setSnackOpen] = useState(false)
    const [snack, setSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState(true)
    const [policyStatus, setPolicyStatus] = useState('all');
    const [copyUrl, setCopyUrl] = useState("");
    const textAreaRef = useRef(null);
    const handleClose = (event, reason) => {
        setSnackOpen(false)

    };

    let initialTime = ' 00:00:00';
    let endTime = ' 23:59:59';
    let userGrid = null;


    const Results = () => (

        <Grid item xs={12} sm={3} >
            <TextField style={{ width: '100%' }} id="outlined-select-currency-native" value={user} onChange={e => setUser(e.target.value)} name="issueType" select label="Select User" SelectProps={{ native: true, }} variant="standard" >
                <option></option>
                <option key={"all"} value={"all"} >
                    ALL
                </option>
                <optgroup label='DisocuntedLinks'>
                    <option key={"discounted"} value={"discounted"} >
                        ALL Discounted
                </option>
                    {discountedReferrals.sort().map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </optgroup>
                <optgroup label='NonDisocuntedLinks'>
                    <option key={"nonDiscounted"} value={"nonDiscounted"} >
                        ALL Non-Discounted
                    </option>
                    {nonDiscountedReferrals.sort().map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </optgroup>
            </TextField>
        </Grid>
    )

    const customerDetails = [{ value: '', label: '' }, { value: 'CustomerName', label: 'CustomerName' }, { value: 'Email', label: 'CustomerEmail' }, { value: 'PhoneNumber', label: 'PhoneNumber' }]

    useEffect(function () {
        if (sessionStorage.getItem('token') === null) {
            props.history.push('/');
        }
        //setOpen(true);
        if (sessionStorage.getItem('role') === 'L1User' || sessionStorage.getItem('role') === 'Admin' || sessionStorage.getItem('role') === 'L2User') {
            setShowSelect(true)
            getReferralUsers();
            fetchPolicies();
        }
        else {
            fetchPolicies();
        }

    }, []);

    const afterRightClick = (event, tableMeta) => {
        console.log('in right click method: ')
        event.preventDefault()
        setOpen(true);
        getProposalLinkApi(tableMeta.rowData?.[0]).then(response => {
            if (response?.data?.status === "success") {
                console.log('in response status: ', response)
                let el = document.createElement('input');
                el.value = response.data.url;
                let copyText = document.getElementsByName('input');
                copyText.select();
                copyText.setSelectionRange(0, 99999)
                document.execCommand("copy");
                console.log('copied text: ', copyText.value);
                //window.navigator.clipboard.writeText(response.data.url);
                setOpen(false);
                setSnackOpen(true)
                setMessage("Link Copied");
                setSnack(true)
                setColor(true)
            } else {
                console.log('in response status else condition: ')
                setOpen(false);
                setSnackOpen(true)
                setMessage("Something went wrong");
                setSnack(true)
                setColor(false)
            }
            setOpen(false);
        }).catch(error => {
            console.log('in error response catch: ', error)
            setOpen(false);
            setSnackOpen(true)
            setMessage(error?.response?.data?.message)
            setSnack(true)
            setColor(false)
        })

    }
    const getProposalLink = (event, tableMeta) => {
        setOpen(true);
        getProposalLinkApi(tableMeta.rowData?.[0]).then(response => {
            if (response?.data?.status === "success") {
                let link = document.createElement("a");
                link.href = response.data.url;
                link.target = '_blank';
                link.click();
            } else {
                setOpen(false);
                setSnackOpen(true)
                setMessage("Something went wrong");
                setSnack(true)
                setColor(false)
            }
            setOpen(false);
        }).catch(error => {
            setOpen(false);
            setSnackOpen(true)
            setMessage(error?.response?.data?.message)
            setSnack(true)
            setColor(false)
        })
    }

    const generatePaymentLink = (tableMeta) => {

        if ((tableMeta?.rowData[11] === "" || tableMeta?.rowData[11] === null) &&
            (tableMeta?.rowData[12] === "" || tableMeta?.rowData[12] === null)) {
            setSnackOpen(true)
            setMessage("Contact info is missing")
            setSnack(true)
            setColor(false)
        } else {
            setOpen(true)
            let request = {
                proposalId: tableMeta?.rowData[0].toString(),
                notificationType: tableMeta?.rowData[11] === "" || tableMeta?.rowData[11] === null ? "EMAIL" : "EMAIL,SMS"
            }
            generatePaymentLinkApi(request).then(response => {
                setOpen(false);
                setSnackOpen(true)
                setMessage(response?.data?.message)
                setSnack(true)
                setColor(true)
            }).catch(error => {
                setOpen(false);
                setSnackOpen(true)
                setMessage("Unable to send the proposal link")
                setSnack(true)
                setColor(false)
            });
        }
    }

    const getReferralUsers = () => {
        setOpen(true);
        axios({
            method: 'get',
            url: GET_USERS,
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            let discountedIds = [];
            let nonDiscountedIds = [];
            if (response.data) {
                response.data.map(item => {
                    if (item.isDiscountedLink) {
                        discountedIds.push(item.referrerCode)
                    } else {
                        nonDiscountedIds.push(item.referrerCode)
                    }
                })
            }
            setDiscountedReferrals(discountedIds);
            setNonDiscountedReferrals(nonDiscountedIds);
            setOpen(false);
        }).catch(error => {
            setOpen(false);
            if (error.response !== undefined) {
                if (error.response.status === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage('Something Went Wrong')
                    setSnack(true)
                    setColor(false)
                }
            }
        })
    }

    const downloadProposalForm = (value) => {
        setOpen(true);
        axios({
            method: 'get',
            url: DOWNLOAD_PROPOSAL_FORM,
            params: {
                proposalNumber: value.props.children.props.value,

            },
            headers: { token: sessionStorage.getItem('token') },
            responseType: 'blob'
        }).then(function (response) {
            setOpen(false);
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                const link = document.createElement('a');
                link.href = base64data;
                link.setAttribute('download', value.props.children.props.value + '_ProposalForm');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        }).catch(error => {
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.message)
                    setSnack(true)
                    setColor(false)
                }
                setOpen(false);
            } else {
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    const [activeError, setActiveError] = useState(false);
    const fetchPolicies = () => {
        let diffDays = Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24);
        if (diffDays > 90) {
            setActiveError(true);
        } else {
            setOpen(true)
            axios({
                method: 'get',
                url: POLICY_DASHBOARD,
                params: {
                    partyCode: user,
                    fromDate: convert(fromDate) + initialTime,
                    toDate: convert(toDate) + endTime,
                    filterValue: value !== "" ? value : null,
                    filterParam: selectCustomerDetails !== "" ? selectCustomerDetails : null,
                    policyStatus: policyStatus === 'all' ? null : policyStatus
                },
                headers: { token: sessionStorage.getItem('token') }
            }).then(response => {
                setData(response.data);
                setOpen(false)
            }).catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 403) {
                        alert('session expired')
                        sessionStorage.clear();
                        props.history.push('/')
                    }
                    else {
                        setSnackOpen(true)
                        setMessage(error.response.data.message)
                        setSnack(true)
                        setColor(false)
                    }
                    setOpen(false);
                } else {
                    setOpen(false)
                    setSnackOpen(true)
                    setMessage('Something Went Wrong')
                    setSnack(true)
                    setColor(false)
                }
            })
        }
    }

    const cancelAction = () => {

    }
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    const options = {
        filterType: 'multiselect',
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 40, 50],
        responsive: "scroll",
        sort: false,
        download: false,
        viewColumns: false,
        selectableRows: 'single',
        onCellClick: (colData, cellMeta) => {
            if (cellMeta.colIndex === 15) {
                downloadProposalForm(colData);
            }
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return (
                null
            );
        },
    }
    const exportFunction = () => {
        setOpen(true);
        axios({
            method: 'get',
            url: POLICY_EXPORT,
            params: {
                fromDate: convert(fromDate) + initialTime,
                toDate: convert(toDate) + endTime,
                partyCode: user,
                filterValue: value !== "" ? value : null,
                filterParam: selectCustomerDetails !== "" ? selectCustomerDetails : null,
                policyStatus: policyStatus === 'all' ? null : policyStatus
            },
            headers: { token: sessionStorage.getItem('token') },
            responseType: 'blob'
        }).then(function (response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                const link = document.createElement('a');
                link.href = base64data;
                link.setAttribute('download', `PolicyReports_${user}_${policyStatus}_${convert(fromDate)}_${convert(toDate)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setOpen(false);
            }
        }).catch(error => {
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.message)
                    setSnack(true)
                    setColor(false)
                }
                setOpen(false);
            } else {
                setOpen(false);
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    const goToExport = () => {
        if (user === 'all' || user === 'discounted' || user === 'nonDiscounted') {
            exportAllFunction();
        } else {
            exportFunction();
        }
    }
    const exportAllFunction = () => {
        setOpen(true);
        axios({
            method: 'get',
            url: POLICY_EXPORT_ALL,
            params: {
                fromDate: convert(fromDate) + initialTime,
                toDate: convert(toDate) + endTime,
                linkType: user === 'all' ? null : user,
                filterValue: value !== "" ? value : null,
                filterParam: selectCustomerDetails !== "" ? selectCustomerDetails : null,
                policyStatus: policyStatus === 'all' ? null : policyStatus
            },
            headers: { token: sessionStorage.getItem('token') },
            responseType: 'blob'
        }).then(function (response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                const link = document.createElement('a');
                link.href = base64data;
                link.setAttribute('download', `PolicyReports_${user}_${policyStatus}_${convert(fromDate)}_${convert(toDate)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setOpen(false);
            }
        }).catch(error => {
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.message)
                    setSnack(true)
                    setColor(false)
                }
                setOpen(false);
            } else {
                setOpen(false);
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }

    return (
        <div id={'dashboard-mui-table'} style={{ margin: 30 }}>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <textarea
                hidden={true}
                ref={textAreaRef}
                value="Copied url"
            />
            {snack && <Snackbar
                anchorOrigin={{
                    vertical: (color) ? 'bottom' : 'top',
                    horizontal: 'right',
                }}
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleClose}>
                <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
                    {message}
                </MuiAlert>

            </Snackbar>
            }
            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Policy Dashboard </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                    {showSelectUser ? <Results /> : null}
                    <Grid item xs={12} sm={3}  >
                        <KeyboardDatePicker
                            margin="normal"
                            id="from date"
                            label="From Date"
                            maxDate={new Date()}
                            value={fromDate}
                            error={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 90)}
                            helperText={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 90) ? "Select dates within range of 3 months" : null}
                            onChange={date => setFromDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ marginTop: '0px', marginBottom: '0px' }}
                        />

                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <KeyboardDatePicker
                            margin="normal"
                            id="to date"
                            label="To Date"
                            value={toDate}
                            maxDate={new Date()}
                            onChange={date => setToDate(date)}
                            //style={{ width: 225 }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ marginTop: '0px', marginBottom: '0px' }}
                            error={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 90)}
                            helperText={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 90) ? "Select dates within range of 3 months" : null}
                        />

                    </Grid>

                    <Grid item xs={12} sm={3} >
                        <TextField style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            value={policyStatus}
                            onChange={e => setPolicyStatus(e.target.value)}
                            name="policyStatus"
                            select
                            label="Select Policy Status"
                            SelectProps={{ native: true, }}
                            variant="standard" >
                            <option key="all" value="all">ALL</option>
                            <option key="converted" value="converted">Converted</option>
                            <option key="nonConverted" value="nonConverted">Non Converted</option>
                        </TextField>
                    </Grid>

                </Grid>
                <div style={{ paddingBottom: 10 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3} >
                            {!showSelectUser ? <TextField style={{ width: '100%' }}
                                id="outlined-select-currency-native"
                                value={selectCustomerDetails}
                                onChange={e => setSelectCustomerDetails(e.target.value)}
                                name="selectCustomerDetails"
                                select label="Search By"
                                SelectProps={{ native: true, }}
                                variant="standard" >
                                {
                                    customerDetails.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))
                                }
                            </TextField> : null}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            {!showSelectUser ? <TextField
                                style={{ width: '235px' }}
                                id="filterValue"
                                type="text"
                                name="filterValue"
                                value={value}
                                onChange={(e) => setFilterValue(e.target.value)}
                                label="FilterValue" >

                            </TextField> : null}
                        </Grid>
                        {sessionStorage.getItem('role') === 'Referral' ? null : <Grid item xs={12} sm={3}></Grid>}
                        <Grid item xs={12} sm={1} >
                            <Button
                                style={{ marginTop: '12px' }}
                                type="submit"
                                variant="contained"
                                className={classes.button}
                                onClick={fetchPolicies}
                                color="primary"
                                disabled={user === 'all' || user === 'discounted' || user === 'nonDiscounted' ? true : false}>Go </Button>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                style={{ marginTop: '12px', marginLeft: '25px' }}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                startIcon={<CloudDownloadIcon />}
                                onClick={goToExport}
                            >
                                EXPORT</Button></Grid>
                    </Grid>
                </div>
            </MuiPickersUtilsProvider>
            <div >
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options} />
                </MuiThemeProvider>
            </div>
        </div>
    )
}

export default PolicyReports;