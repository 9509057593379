import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Header from '../header/header';
import Sidebar from '../sidebar/sidebar';
import './layout.css'
let drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    content: {
      overflow:'hidden',
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
      backgroundColor: '#e5e8f1',
      borderTopLeftRadius: 10,
      height:'calc(100vh - 64px)', 
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
}));

function Layout(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleDrawer = (action) => {
      setOpen(action);
      if(action){
        drawerWidth = 240;
      }
      else{
        drawerWidth = 0;
      }
    };
    return (
      <div style={{backgroundColor:'#2252e5'}}>
        <Header {...props} handleDrawer={handleDrawer}/>
        <Sidebar {...props} drawerOpen={open}/>
        <div style={{marginLeft:240, marginTop:64,backgroundColor:'#0878fb'}}>
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: open,
            })}
          ><div className={'container'} style={{height:'100%',overflowY:'scroll'}}>{props.children}</div>
          </main>
        </div>
      </div>
    );
  }
  
  export default Layout;