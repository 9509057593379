import moment from "moment";

export const staticPolicyData = {
  agentCode: "",
  agentName: "",
  chequeNumber: "",
  convertPolicyNo: "",
  convertedDate: "",
  customerCode: "",
  customerMobile: "",
  customerName: "",
  gst: "",
  instrumentDate: "",
  policyFromDate: "",
  policyNumber: "",
  policyToDate: "",
  policyType: "",
  premium: "",
  productName: "",
  proposalAcceptedDate: "",
  proposalNO: "",
  queryDate: "",
  querySubmittedDate: "",
  smCode: "",
  smName: "",
  status: "",
  toDate: "",
  totalPremium: "",
};

export const checkPolicyTypeExists = (policyType, policyTypeLOV) => {
  return (
    policyTypeLOV.filter((value) => value.value === policyType)?.length || 0
  );
};

export const isValidValue = (value) => {
  return value !== "" && value !== undefined && value !== null;
};

export const isValidValueDate = (value) => {
  return (
    value !== "" &&
    value !== undefined &&
    value !== null &&
    moment(value).isValid()
  );
};

export const formatDateDDMMYYYY = (value) => {
  return moment(value).format("DD/MM/YYYY");
};

export const checkPremiumDate = (date) => {
  if (isValidValue(date)) {
    if (
      isValidValueDate(date) &&
      date < new Date() &&
      date !== null &&
      date?.toString()
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export const checkIntermediaryDate = (intermediaryReceivedDate, inwardDate) => {
  if (isValidValue(intermediaryReceivedDate)) {
    if (
      isValidValueDate(intermediaryReceivedDate) &&
      intermediaryReceivedDate < new Date() &&
      intermediaryReceivedDate !== null &&
      intermediaryReceivedDate?.toString() &&
      isValidValueDate(intermediaryReceivedDate) &&
      isValidValueDate(inwardDate) &&
      moment(intermediaryReceivedDate).isSameOrBefore(inwardDate)
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export const validateForm = (inwardForm, policyInfo) => {
  const {
    inwardType,
    inwardDate,
    premiumReceiptDate,
    customerName,
    customerMobile,
    policyType,
    refRemark,
    modeOfPay,
    intermediaryReceivedDate,
    amountCollected,
  } = inwardForm;
  return (
    (Object?.keys(policyInfo)?.length === 0
      ? isValidValue(customerName)
      : true) &&
    (Object?.keys(policyInfo)?.length === 0
      ? isValidValue(customerMobile) && customerMobile?.length === 10
      : true) &&
    isValidValue(refRemark) &&
    /^[A-Za-z0-9- /\/.,;:]+$/g.test(refRemark) &&
    isValidValueDate(inwardDate) &&
    inwardDate < new Date() &&
    isValidValue(inwardType) &&
    policyType !== "Select Type" &&
    modeOfPay !== "Select Type" &&
    checkPremiumDate(premiumReceiptDate) &&
    checkIntermediaryDate(intermediaryReceivedDate, inwardDate) &&
    /^[0-9.,]*$/g.test(amountCollected)
  );
};

export const bindCreateInwardPayload = (inwardForm, policyInfo, userId) => {
  const {
    officeCode,
    officeName,
    inwardType,
    inwardDate,
    premiumReceiptDate,
    customerName,
    customerMobile,
    customerCode,
    policyType,
    policyNo,
    proposalNo,
    productName,
    SMName,
    SMCode,
    agentName,
    agentCode,
    refRemark,
    modeOfPay,
    intermediaryReceivedDate,
    amountCollected,
  } = inwardForm;
  return {
    ...policyInfo,
    ...(Object.keys(policyInfo).length === 0 && { ...staticPolicyData }),
    officeCode: officeCode || "",
    officeName: officeName || "",
    inwardType: inwardType || "",
    inwardDate: isValidValueDate(inwardDate)
      ? formatDateDDMMYYYY(inwardDate)
      : "",
    premiumReceiptDate: isValidValueDate(premiumReceiptDate)
      ? formatDateDDMMYYYY(premiumReceiptDate)
      : "",
    customerName: customerName || "",
    customerMobile: customerMobile || "",
    customerCode: customerCode || "",
    policyType: policyType || "",
    policyNumber: policyNo || "",
    proposalNumber: proposalNo || "",
    productName: productName || "",
    smName: SMName || "",
    smCode: SMCode || "",
    agentName: agentName || "",
    agentCode: agentCode || "",
    referenceRemark: refRemark || "",
    createdBy: userId || "",
    updatedBy: userId || "",
    modeOfPay: modeOfPay || "",
    intermediaryReceivedDate: isValidValueDate(intermediaryReceivedDate)
      ? formatDateDDMMYYYY(intermediaryReceivedDate)
      : "",
    amountCollected: amountCollected || "",
  };
}

export const formatMMDDYYY = (value) => {
  if (isValidValueDate(value)) {
    return moment(value).format('MM/DD/YYYY')
  }
  return value;
}