import React from 'react'
export default function Footer() {
  return (
    <footer
      style={{
        height: "calc(100vh - 130)",
        color: "#fff",
        marginLeft: "45px",
        fontSize: "14px",
        fontWeight: "100",
      }}
    >
      <a
        href="https://www.starhealth.in/disclaimer"
        style={{
          color: "#fff",
          textDecoration: "none",
          fontStyle: "Futura",
          fontWeight: "400",
        }}
      >
        Disclaimer
      </a>
      &nbsp;|{" "}
      <a
        href="https://www.starhealth.in/aboutUs"
        style={{ color: "#fff", textDecoration: "none" }}
      >
        About Us
      </a>
      &nbsp;|{" "}
      <a
        href="https://www.starhealth.in/contact-us"
        style={{ color: "#fff", textDecoration: "none" }}
      >
        Contact Us
      </a>{" "}
      &nbsp;| &copy;2018 All Right Reserved.Star Health Insurance. &nbsp;
    </footer>
  );
}
