import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Snackbar,
  Dialog,
  DialogContent,
  Grid,
  TextField
} from "@material-ui/core";
import axios from "axios";
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles
} from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from "@material-ui/lab/Alert";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleSharp";
import EditSharpIcon from "@material-ui/icons/EditSharp";
import { GET_ALL_REFERRERS, DELETE_REFERRER } from "../../config/config.js";
import { toast } from "react-toastify";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { exportReferrals, getDiscountedProducts, getproductList } from '../../api/referralService.js'
import { Link } from "@material-ui/core";
import { ViewReferralDialog } from "./ViewReferralDashboard.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
toast.configure();

let selectableRows = [];

let testFunction;
const RferrelDashBoard = props => {
  const [open, setOpen] = React.useState(false);
  const useStyles = makeStyles(theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff"
    }
  }));

  const classes = useStyles();
  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiGridListTile: {
          tile: {
            width: "150px"
          }
        },
        MuiTableCell: {
          root: {
            padding: ` 1px 10px !important`,
            borderBottom: 'none',
          },
          head: {
            height: '10px',
          },
          body: {
            height: '10px',
          },
        },
        MuiFormControl: {
          fullWidth: {
            width: "100%"
          }
        },
        // MUIDataTable: {
        //   height: "410px",
        //   responsiveScroll: { maxHeight: `410px !important` }
        // },
        MUIDataTableToolbar: {
          filterPaper: {
            left: "888px"
          }
        },
        MUIDataTableSelectCell: {
          root: {
            backgroundColor: "#ffff !important"
          }
        },
        MUIDataTableHeadCell: {
          root: {
            textTransform: "uppercase",
            fontFamily: "FuturaLight",

            fontSize: "0.815rem",
            backgroundColor: "#DCDCDC !important"
          }
        },
        // MUIDataTableBodyCell: {
        //   root: {
        //     height: "auto",
        //   }
        // },
        MuiTypography: {
          body1: {
            fontFamily: "TableData",
            fontSize: "0.815rem"
          }
        }
      }
    });

  const columns = [
    {
      name: "referrerCode",
      label: "Referrer\u00a0Code",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title="Click To View" interactive>
              <Link onClick={() => handleViewDetails(tableMeta)} style={{ cursor: "pointer" }}>{value}</Link>
            </Tooltip>
          )
        }
      }
    },
    {
      name: "partnerName",
      label: "Referrer\u00a0Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "intermediaryCode",
      label: "Intermediary\u00a0code",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },

    {
      name: "officeCode",
      label: "Office\u00a0Code",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "fulfillerCode",
      label: "Fulfiller\u00a0Code",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "createdOn",
      label: "Created\u00a0Date",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "firstPolicyDate",
      label: "First\u00a0Policy\u00a0Date",
      options: {
        filter: false,
        display: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "lastPolicyDate",
      label: "Last\u00a0Policy\u00a0Date",
      options: {
        filter: false,
        display: false,
        print: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        }
      }
    },
    {
      name: "referrerId",
      label: "Action",
      options: {
        filter: false,
        display: sessionStorage.getItem('role') === 'Admin' ? true : false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title="Edit Referrer Code" interactive>
              <IconButton value={value}>
                <EditSharpIcon onClick={editReferrerCode} />
              </IconButton>
            </Tooltip>
          );
        }
      }
    },
    {
      name: "email", label: "Email",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "mobileNumber", label: "MobileNumber",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "spCode", label: "SPCode",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "subAgentCode", label: "SubAgentCode",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "spocName", label: "SPOCName",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "spocEmail", label: "SPOCEmail",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "spocMobileNumber", label: "SPOCMobileNumber",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "isProposalDiscount", label: "IsProposalDiscount",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "canDownloadPolicy", label: "CanDownloadPolicy",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "allowedProducts", label: "AllowedProducts",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
    {
      name: "discountedProducts", label: "DisocuntedProducts",
      options: {
        display: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={'span'} noWrap={true}>
              {value}
            </Typography>
          )
        }
      }
    },
  ];
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [filterkey, setFilterKey] = useState("all")
  const [openViewDialog, setOpenViewDialog] = useState(false)
  const [viewDataObj, setViewDataObj] = useState({});
  const handleClose = (event, reason) => {
    setSnackOpen(false);
    setViewDataObj({});
  };


  const [data, setData] = useState();

  useEffect(function () {
    selectableRows = [];
    //setOpen(true)
    if (sessionStorage.getItem("token") === null) {
      props.history.push("/");
    }
    getAllReferral();
    //setOpen(false);
  }, []);

  const getAllReferral = () => {
    setOpen(true);
    axios({
      method: "get",
      url: GET_ALL_REFERRERS,
      params: {
        filterBy: filterkey === 'all' ? null : filterkey
      },
      headers: { token: sessionStorage.getItem("token") }
    })
      .then(response => {
        setData(response.data);
        setOpen(false);
      })
      .catch(error => {
        setOpen(false)
        if (error.response !== undefined) {
          if (error.response.status === 403 || error.response.status === 400) {
            alert("session expired");
            sessionStorage.clear();
            props.history.push("/");
          } else {
            setSnackOpen(true);
            setMessage(error.response.data.error);
            setSnack(true);
            setColor(false);
          }
        } else {
          setSnackOpen(true);
          setMessage("Something Went Wrong");
          setSnack(true);
          setColor(false);
        }
      });
  };
  const nullcheck = data => {
    if (data == null) {
      return "";
    }
    return data;
  };
  const editReferrerCode = id => {
    props.history.push(`/refferalupdate/${id}`);
  };
  const handleViewDetails = async (tableMeta) => {
    let products = [];
    let alwdProducts = [];
    let discproducts = [];
    if (tableMeta.rowData[18]) {
      if (tableMeta.rowData[16]) {
        products = await getDiscountedProducts();
        products.data.map(item => {
          tableMeta.rowData[18].map(item1 => {
            if (item1 === item.productId) {
              discproducts.push(item.referrerProductName);
            }
          })
        })
      } else {
        products = await getproductList();
        products.data.map(item => {
          tableMeta.rowData[18].map(item1 => {
            if (item1 === item.productId) {
              alwdProducts.push(item.referrerProductName);
            }
          })
        })
      }
    }
    let viewData = {
      referrerCode: nullcheck(tableMeta.rowData[0]),
      partnerName: nullcheck(tableMeta.rowData[1]),
      officeCode: nullcheck(tableMeta.rowData[3]),
      intermediaryCode: nullcheck(tableMeta.rowData[2]),
      fullFillerCode: nullcheck(tableMeta.rowData[4]),
      createdAt: nullcheck(tableMeta.rowData[5]),
      email: nullcheck(tableMeta.rowData[9]),
      mobileNumber: nullcheck(tableMeta.rowData[10]),
      spCode: nullcheck(tableMeta.rowData[11]),
      subAgentCode: nullcheck(tableMeta.rowData[12]),
      spocName: nullcheck(tableMeta.rowData[13]),
      spocEmail: nullcheck(tableMeta.rowData[14]),
      spocMobileNumber: nullcheck(tableMeta.rowData[15]),
      isProposalDiscounted: tableMeta.rowData[16],
      canDownloadPolicy: tableMeta.rowData[17],
      allowedProducts: alwdProducts,
      discountedProducts: discproducts,
      firstPolicyDate: nullcheck(tableMeta.rowData[6]),
      lastPolicyDate: nullcheck(tableMeta.rowData[7]),
    }
    setOpenViewDialog(true);
    setViewDataObj(viewData);
  }
  const sendReferrerCode = e => { };
  const AddReferrerCode = () => {
    props.history.push("/refferalcreate");
  };
  const [deletedRows, setDeletedRows] = useState([]);

  const deletedReferral = (selectRows, displayData, setSelectedRows) => {
    setOpenDialog(true);
    setDeletedRows(selectRows.data?.map(t => t.dataIndex));
  }

  const deleteConfirmed = () => {
    delt(data[deletedRows[0]].referrerCode);
  };
  const delt = p => {
    setOpen(true);
    setOpenDialog(false);
    axios({
      method: "DELETE",
      url: DELETE_REFERRER + '/' + p,
      headers: { token: sessionStorage.getItem("token") }
    })
      .then(response => {
        setOpen(false);
        console.log('response: ', response)
        if (response?.data.code === 200) {
          getAllReferral();
          setSnackOpen(true);
          setMessage(response.data.message);
          setSnack(true);
          setColor(true);
          setDeletedRows([]);
        }
      })
      .catch(error => {
        setOpen(false);
        if (error.response !== undefined) {
          if (error.response.data.referrerCode === 403) {
            alert("session expired");
            props.history.push("/");
          } else {
            setSnackOpen(true);
            setMessage(error.response.data.message);
            setSnack(true);
            setColor(false);
          }
          setOpen(false);
        } else {
          setSnackOpen(true);
          setMessage("Something Went Wrong");
          setSnack(true);
          setColor(false);
          setOpen(false);
        }
      });

  };
  const onCellClick = (colData, cellMeta) => {
    if (cellMeta.colIndex === 8) {
      editReferrerCode(colData.props.children.props.value);
    }
  };
  const options = {
    responsive: "scroll",
    filterType: "multiselect",
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 40, 50],
    sort: false,
    print: false,
    download: false,
    viewColumns: false,
    selectableRows: 'single',
    onCellClick: onCellClick,
    //onRowsSelect: onRowsSelect,
    rowsSelected: deletedRows,
    //onRowsDelete: onRowsDelete,
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        sessionStorage.getItem('role') === 'Admin' ?
          <Tooltip title="Delete Referrer" interactive>
            <IconButton>
              <DeleteIcon onClick={() => deletedReferral(selectedRows, displayData, setSelectedRows)
              } />
            </IconButton>
          </Tooltip>
          : null
      );
    },
    customToolbar: () => {
      return (
        sessionStorage.getItem('role') === 'Admin' ?
          <span>
            {" "}
            <Tooltip title="Add Referrer" interactive>
              <IconButton>
                <AddCircleOutlineIcon onClick={AddReferrerCode} />
              </IconButton>
            </Tooltip>
          </span>
          : null
      );
    },
  };
  const handleDialog = () => {
    setOpenViewDialog(false);
  }

  const exportFunction = () => {
    setOpen(true);
    exportReferrals(filterkey).then(
      function (response) {
        var reader = new FileReader();
        reader.readAsDataURL(response.data);
        reader.onloadend = function () {
          var base64data = reader.result;
          const link = document.createElement('a');
          link.href = base64data;
          link.setAttribute('download', `${filterkey}_Referrals.xlsx`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setOpen(false)
        }
      }

    ).catch(error => {
      setOpen(false)
      if (error.response !== undefined) {
        if (error.response.data.code === 403) {
          alert('session expired')
          sessionStorage.clear();
          props.history.push('/')
        }
        else {
          setSnackOpen(true)
          setMessage(error.response.data.message)
          setSnack(true)
          setColor(false)
        }
      } else {
        setSnackOpen(true)
        setMessage('Something Went Wrong')
        setSnack(true)
        setColor(false)
      }
    })

  }
  return (
    <div id={"dashboard-mui-table"} style={{ margin: 30 }}>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {snack && (
        <Snackbar
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right"
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      <Dialog open={openDialog}>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the selected Referrer codes?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={e => setOpenDialog(false)}
            color="primary"
          >
            no
          </Button>
          <Button autoFocus onClick={e => deleteConfirmed()} color="primary">
            yes
          </Button>
        </DialogActions>
      </Dialog>
      <ViewReferralDialog
        fullScreen={fullScreen}
        openDialog={openViewDialog}
        handleClose={handleDialog}
        viewDataObj={viewDataObj}
      />
      <Typography
        style={{
          textAlign: "left",
          marginBottom: 18,
          fontFamily: "Futura",
          fontWeight: "400px",
          fontSize: "1.15rem"
        }}
        variant={"h5"}
      >
        {" "}
        Referral Dashboard
      </Typography>
      <div style={{ paddingBottom: 10 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <TextField
              style={{ width: '100%' }}
              id="outlined-select-currency-native"
              value={filterkey}
              onChange={e => setFilterKey(e.target.value)}
              name="filterkey"
              select SelectProps={{ native: true, }}
              variant="standard" >
              <option key={"all"} value={"all"}>ALL</option>
              <option key={"discounted"} value={"discounted"}>Discounted</option>
              <option key={"nonDiscounted"} value={"nonDiscounted"}>Non Discounted</option>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={getAllReferral}
            >Go </Button>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              style={{ marginLeft: '28px' }}
              startIcon={<CloudDownloadIcon />}
              type="submit"
              variant="contained"
              onClick={exportFunction}
              color="primary">Export</Button>
          </Grid>
        </Grid>
      </div>
      <div>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
    </div>
  );
};

export default RferrelDashBoard;
