import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, makeStyles, Paper, Typography, Snackbar } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import Tune from '@material-ui/icons/Tune';
import axios from 'axios';
import { PRAPOSAL_STATUS_ENQUIRY } from '../../config/config.js';
import MuiAlert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    textField: {
        marginTop: 10,
        marginBottom: 10,
        [`& fieldset`]: {
            borderRadius: 30,
            backgroundColor: '#e5e8f1'
        },
        [`& input`]: {
            zIndex: 3
        },
    },

    box: {
        marginLeft: '30px',
        marginTop: '30px'
    },

    createIssueFrom: {
        width: '18rem',
        height: 'calc(100vh - 64px)',
        backgroundColor: '#e5e8f1'
    },
    createIssue: {
        marginLeft: '30'
    },
    button: {
        margin: theme.spacing(1),
        backgroundColor: '#007bff'
    },
}));
export default function SearchComponent(props) {

    const [proposalNumber, setProposalNumber] = useState('');
    const [proposalType, setProposalType] = useState('');
    const [premium, setPremium] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [policyStatus, setPolicyStatus] = useState('');
    const [proposerDetails, setProposalDetails] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerMobile, setCustomerMobile] = useState('');
    const [customerEmail, setCustoerEmail] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [showResults, setShowResults] = useState(false)
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchClear, setSearchClear] = useState(false);

    const [snackOpen, setSnackOpen] = useState(false)
    const [snack, setSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState(true)

    const handleSnackClose = (event, reason) => {
        setSnackOpen(false)

    };

    const onClickSubmit = (event) => {

        const userId = sessionStorage.getItem('userId')
        const role = sessionStorage.getItem('role')
        let url = PRAPOSAL_STATUS_ENQUIRY;
        setSearchClear(true);
        if (searchQuery !== '') {
            if (role !== 'L1User') {
                url = url + `${userId}/`
            }
            axios({
                'method': 'GET',
                'url': url + 'proposal-status',
                'params': {
                    'proposalNumber': searchQuery,
                },
                headers: { token: sessionStorage.getItem('token') }
            }).then(response => {
                setCustomerName(response.data.custName)
                setProposalNumber(response.data.proposalNo)
                setProposalType(response.data.proposalType)
                setPremium(response.data.totalPremium)
                setPaymentMode(response.data.paymentMode)
                setPaymentStatus(response.data.paymentStatus)
                setPolicyStatus(response.data.status)
                setProposalDetails(response.data.proposerDetail)
                setCustomerMobile(response.data.mobileNumber)
                setCustoerEmail(response.data.email)
                setPolicyNumber(response.data.policyNumber)
                setShowResults(true)

            }).catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 403) {
                        if (error.response.data.message === 'Not Authorized to view the proposal details') {
                            setSnackOpen(true)
                            setMessage(error.response.data.message)
                            setSnack(true)
                            setColor(false)
                        } else {
                            alert('session expired')
                            props.history.push('/')
                        }
                    }
                    else if (error.response.data.code === 404) {
                        setSnackOpen(true)
                        setMessage(error.response.data.message)
                        setSnack(true)
                        setColor(false)
                    }
                    else {
                        setSnackOpen(true)
                        setMessage(error.response.data.message)
                        setSnack(true)
                        setColor(false)
                    }
                } else {
                    setSnackOpen(true)
                    setMessage('Something Went Wrong')
                    setSnack(true)
                    setColor(false)
                }
            })
        }
    }

    const reset = () => {
        setSearchQuery('');
    }
    const classes = useStyles();
    useEffect(function () {
        if (sessionStorage.getItem('userId') === null) {
            props.history.push('/');
        }
    }, [searchClear]);


    const Results = () => (
        <div className={classes.createIssue}>
            <Grid container spacing={6}>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="proposalNumber" name="proposalNumber" InputProps={{ readOnly: true }} value={proposalNumber} label="Proposal No" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="proposalType" name="proposalType" value={proposalType} InputProps={{ readOnly: true }} label="Proposal Type" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="premium" name="premium" InputProps={{ readOnly: true }} value={premium} label="Premium" variant="outlined" /></Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="paymentMode" name="paymentMode" value={paymentMode} InputProps={{ readOnly: true }} label="Payment Mode" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="paymentStatus" name="paymentStatus" InputProps={{ readOnly: true }} value={paymentStatus} label="Payment Status" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="policyStatus" name="policyStatus" value={policyStatus} InputProps={{ readOnly: true }} label="Policy Status" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="proposerDetails" name="proposerDetails" value={proposerDetails} InputProps={{ readOnly: true }} label="Proposer Details" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="customerName" name="customerName" value={customerName} InputProps={{ readOnly: true }} label="Customer Name" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="customerMobile" name="customerMobile" value={customerMobile} InputProps={{ readOnly: true }} label="Customer Mobile" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="customerEmail" name="customerEmail" value={customerEmail} InputProps={{ readOnly: true }} label="customerEmail" variant="outlined" /> </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField style={{ width: '100%' }} id="policyNumber" name="policyNumber" value={policyNumber} InputProps={{ readOnly: true }} label="Policy Number" variant="outlined" /> </Grid>
            </Grid>
        </div>
    )
    return (
        <React.Fragment>
            <Grid container spacing={12} >
                <Paper className={classes.createIssueFrom} style={{ position: 'absolute' }}>
                    <div className={classes.createIssue}>
                        <Grid container spacing={12} style={{ zIndex: 3 }}>
                            <Grid >
                                <FormControl className={classes.box} component="fieldset">
                                    <Grid container spacing={12} style={{ textAlign: "right" }} >
                                        <Grid item xs={12} sm={6}><Typography style={{ fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem", textAlign: 'left' }} variant={'h5'}>Search By</Typography></Grid>
                                        <Grid item xs={12} sm={6}><RefreshIcon style={{ cursor: 'pointer' }} onClick={() => reset()} ></RefreshIcon><CloseIcon style={{ cursor: 'pointer' }} onClick={() => setSidebarOpen(false)} /></Grid></Grid>
                                    <TextField variant='outlined' size='small' required placeholder='Proposal Number' value={searchQuery} onChange={e => setSearchQuery(e.target.value)} className={classes.textField} error={(searchClear & searchQuery === "")} helperText={searchClear & searchQuery === "" ? 'Proposal number mandatory' : ' '} />
                                    <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                                        <Button
                                            variant="contained"
                                            className={classes.button}
                                            color="primary" onClick={onClickSubmit}>
                                            Search
                                        </Button></Grid>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </div>
                </Paper>
                {snack && <Snackbar
                    anchorOrigin={{
                        vertical: (color) ? 'bottom' : 'top',
                        horizontal: 'right',
                    }}
                    open={snackOpen}
                    autoHideDuration={2000}
                    onClose={handleSnackClose}>
                    <MuiAlert onClose={handleSnackClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
                        {message}
                    </MuiAlert>

                </Snackbar>
                }
                <div style={{ padding: '1.8rem', backgroundColor: '#fff', display: 'flex !important', height: 'calc(100vh - 64px)', marginLeft: 'auto', zIndex: sidebarOpen ? 3 : 4, transition: 'width .3s', position: 'relative', width: sidebarOpen ? 'calc(100% - 18rem)' : '100%' }}>
                    <div style={{ fontFamily: "Futura", fontWeight: "400px", fontSize: "1.5rem", textAlign: 'center', padding: 30 }}>Proposal Status Enquiry <Tune size='medium' style={{ cursor: 'pointer', marginLeft: 25 }} onClick={() => setSidebarOpen(true)} /></div>
                    <div style={{ height: '70%' }}>
                        {showResults ? <Results /> : null}
                    </div>
                </div>
            </Grid>
        </React.Fragment>
    );
}