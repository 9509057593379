import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    color: "#aaa"
  },
  formControl: {
    minWidth: '100%'
  },
  totalPage: {},
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    marginRight: 900,
  },
  input: {
    display: "none",
  },
  createIssueFrom: {
    marginLeft: "30px",
    marginRight: "30px",
  },
  uploadFrom: {
    marginLeft: "30px",
    marginRight: "30px",
    marginTop: "30px",
    marginBottom: "30px",
  },
  createIssue: {
    padding: 25,
  },
  button: {
    padding: theme.spacing(1, 8.5, 1, 8.5),
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "#007bff",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
}));

export default useStyles;