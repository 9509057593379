import React, { useState, useEffect } from "react";
import { Typography, Link, Tooltip, IconButton } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleSharp";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import "./dashboard.css";
import axios from "axios";
import {
  FETCH_ALL_INWARDS,
  DELETE_INWARD,
  VIEW_INWARD_DETAILS,
  INWARD_EXPORT,
} from "../../config/config";
import {
  getDefaultFromDate,
  formateDateDDMMYYYY,
  isValidValue,
  filterDateValidation,
  nameExportFilename,
} from "./helper";
import useStyles from "./style";
import {
  Loader,
  CustomSnackBar,
  ViewDialog,
  ExportFilter,
  DashboardTable,
  ReasonDeleteDialog,
  DeleteReasonDialog,
  defaultTableColumns,
} from "./uiHelper";
import moment from "moment";
import { initialState, deleteDialogInitialState } from "./formData";

let selectedRows = [];

toast.configure();
const InwardDashBoard = (props) => {
  const officeCode = sessionStorage.getItem("officeCode");
  const role = sessionStorage.getItem("role");
  const userId = sessionStorage.getItem("userId");
  const isCorporateUser = role === "CorporateUser";

  const storedFromDate = sessionStorage.getItem("fromDate");
  const storedToDate = sessionStorage.getItem("toDate");

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // snack, dialog & loader
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [viewDataObj, setViewDataObj] = useState(initialState);
  const [data, setData] = useState([]);

  // delete dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [reasonToDelete, setDeleteReason] = useState("");
  const [inwardNoToDelete, setDeletedRows] = useState(initialState);
  const [deleteError, setDeleteError] = useState(false);

  // export error flag
  const [listError, setListError] = useState(false);

  // delete reason dialog
  const [showDeletedReason, toggleDeletedReasonDialog] = useState(false);
  const [deleteDialogData, setDeleteDialogData] = useState(
    deleteDialogInitialState
  );
  const [showDeletedRecords, toggleDeletedRecords] = useState(false);

  // form fields
  const [fromDate, setFromDate] = useState(
    storedFromDate || getDefaultFromDate(new Date(), 30) || null
  );
  const [toDate, setToDate] = useState(storedToDate || new Date() || null);

  useEffect(function () {
    selectedRows = [];
    getDashboardData(showDeletedRecords);
    setOpen(true);
    if (sessionStorage.getItem("token") === null) {
      props.history.push("/");
    }
  }, []);

  const onRowsDelete = (rowsDeleted) => {
    const index = rowsDeleted?.data[0]?.dataIndex;
    setDeletedRows(data[index]?.inwardNumber);
    setOpenDeleteDialog(true);
  };

  const onRowsSelect = (currentRowsSelected, allRowsSelected) => {
    selectedRows = [...allRowsSelected];
  };

  const options = {
    filterType: "multiselect",
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 20, 50, 100],
    sort: false,
    viewColumns: false,
    selectableRows: isCorporateUser ? false : "single",
    onRowsSelect: onRowsSelect,
    rowsSelected: selectedRows.map((item) => item.dataIndex),
    onRowsDelete: onRowsDelete,
    downloadOptions: {
      filename: showDeletedRecords
        ? nameExportFilename(fromDate, toDate)?.local_delete_export
        : nameExportFilename(fromDate, toDate)?.local_inward_export,
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    onChangeRowsPerPage: (numberOfRows) => {
      numberOfRows = numberOfRows;
    },
    customToolbar: () => {
      if (!isCorporateUser) {
        return (
          <span>
            {" "}
            <Tooltip title="Add Entry" interactive>
              <IconButton>
                <AddCircleOutlineIcon
                  onClick={() => {
                    props.history.push("/createInward");
                  }}
                />
              </IconButton>
            </Tooltip>
          </span>
        );
      } else {
        return null;
      }
    },
  };

  const columns = [
    {
      name: "updatedBy",
      label: "Deleted\u00a0By",
      options: {
        display: showDeletedRecords,
        filter: showDeletedRecords,
        search: false,
        download: showDeletedRecords,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "inwardNumber",
      label: "Inward\u00a0Number",
      options: {
        filter: false,
        search: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title="Click To View" interactive>
              <Link
                style={{ cursor: "pointer" }}
                onClick={() => getViewData(tableMeta.rowData[1])}
              >
                {value}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    ...defaultTableColumns,
  ];

  const handleClose = () => {
    setOpenDialog(false);
    setViewDataObj(initialState);
  };

  const getViewData = (inwardNumber) => {
    setOpen(true);
    axios({
      method: "get",
      url: VIEW_INWARD_DETAILS + inwardNumber,
      headers: { token: sessionStorage.getItem("token") },
    })
      .then((response) => {
        setViewDataObj(response.data.data);
        setOpenDialog(true);
      })
      .catch((error) => {
        defaultErrorHandler(error);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const getDashboardData = (showRecordDeleted) => {
    const validate = filterDateValidation(fromDate, toDate);
    if (!validate) {
      setListError(true);
    } else {
      sessionStorage.setItem("fromDate", fromDate);
      sessionStorage.setItem("toDate", toDate);
      setListError(false);
      setOpen(true);
      selectedRows = [];
      const frmDate = formateDateDDMMYYYY(fromDate);
      const tdate = formateDateDDMMYYYY(toDate);
      axios({
        method: "get",
        url: FETCH_ALL_INWARDS,
        params: {
          fromDate: frmDate,
          inwardRecordStatus: showRecordDeleted ? 'INACTIVE' : 'ACTIVE',
          officeCode: officeCode,
          role,
          toDate: tdate,
        },
        headers: { token: sessionStorage.getItem("token") },
      })
        .then((response) => {
          setData(response?.data?.data || []);
        })
        .catch((error) => {
          setData([]);
          defaultErrorHandler(error);
        })
        .finally(() => {
          setOpen(false);
        });
    }
  };

  const defaultErrorHandler = (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 403) {
        alert("session expired");
        sessionStorage.clear();
        props.history.push("/");
      } else if (error?.response?.data?.errorMessages?.length > 0) {
        setSnackOpen(true);
        setMessage(
          error?.response?.data?.errorMessages[0] || "Something Went Wrong"
        );
        setSnack(true);
        setColor(false);
      } else {
        setSnackOpen(true);
        setMessage(error.response.data.error);
        setSnack(true);
        setColor(false);
      }
    } else {
      setSnackOpen(true);
      setMessage("Something Went Wrong");
      setSnack(true);
      setColor(false);
    }
  };

  const deleteRecord = React.useCallback(() => {
    if (isValidValue(reasonToDelete)) {
      setOpenDeleteDialog(false);
      setOpen(true);
      const deleteUrl = DELETE_INWARD + inwardNoToDelete;
      axios({
        method: "get",
        url: deleteUrl,
        params: {
          officeCode,
          remarks: reasonToDelete,
          role,
          userId
        },
        headers: { token: sessionStorage.getItem("token") },
      })
        .then((response) => {
          setSnackOpen(true);
          setMessage("Record deleted successfully");
          setSnack(true);
          getDashboardData(showDeletedRecords);
          setDeleteReason("");
          setDeleteError(false);
        })
        .catch((error) => {
          defaultErrorHandler(error);
        })
        .finally(() => {
          setOpen(false);
        });
    } else {
      setDeleteError(true);
    }
  }, [inwardNoToDelete, officeCode, reasonToDelete]);

  const exportReport = React.useCallback(() => {
    const validate = filterDateValidation(fromDate, toDate);
    if (!validate) {
      setListError(true);
    } else {
      getDashboardData(showDeletedRecords);
      setListError(false);
      setOpen(true);
      const start = moment(fromDate).format("MM/DD/YYYY");
      const end = moment(toDate).format("MM/DD/YYYY");
      const frmDate = moment(start, "MM/DD/YYYY").format("DD/MM/YYYY");
      const tdate = moment(end, "MM/DD/YYYY").format("DD/MM/YYYY");
      axios({
        method: "get",
        url: INWARD_EXPORT,
        params: {
          fromDate: frmDate,
          inwardRecordStatus: showDeletedRecords ? 'INACTIVE' : 'ACTIVE',
          officeCode: officeCode,
          role,
          toDate: tdate,
        },
        headers: { token: sessionStorage.getItem("token") },
        responseType: "blob",
      })
        .then(function (response) {
          var reader = new FileReader();
          reader.readAsDataURL(response.data);
          reader.onloadend = function () {
            var base64data = reader.result;
            const link = document.createElement("a");
            link.href = base64data;
            const fileName = !showDeletedRecords
              ? nameExportFilename(fromDate, toDate)?.network_inward_export
              : nameExportFilename(fromDate, toDate)?.network_delete_export;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          };
        })
        .catch(async (error) => {
          if (error.response !== undefined) {
            const statusCode = error.response.status
            const responseObj = await (error.response.data.text());
            const parsedError = JSON.parse(responseObj);
            if (statusCode === 403) {
              alert("session expired");
              sessionStorage.clear();
              props.history.push("/");
            } else if (parsedError?.errorMessages?.length > 0) {
              setSnackOpen(true);
              setMessage(
                parsedError?.errorMessages[0] || "Something Went Wrong"
              );
              setSnack(true);
              setColor(false);
            } else {
              setSnackOpen(true);
              setMessage(parsedError.error);
              setSnack(true);
              setColor(false);
            }
          } else {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        })
        .finally(() => {
          setOpen(false);
        });
    }
  }, [fromDate, toDate, showDeletedRecords]);

  return (
    <div id={"dashboard-mui-table"} style={{ margin: 30 }}>
      {snack && (
        <CustomSnackBar
          color={color}
          snackOpen={snackOpen}
          setSnackOpen={setSnackOpen}
          message={message}
        />
      )}
      <DeleteReasonDialog
        showDialog={showDeletedReason}
        closeDialog={toggleDeletedReasonDialog}
        inwardNumber={deleteDialogData?.inwardNumber}
        deletedBy={deleteDialogData?.deletedBy}
        deleteReason={deleteDialogData?.deleteReason}
      />
      <Loader open={open} />
      <ReasonDeleteDialog
        openDeleteDialog={openDeleteDialog}
        setOpenDeleteDialog={setOpenDeleteDialog}
        deleteError={deleteError}
        reasonToDelete={reasonToDelete}
        setDeleteReason={(text) => setDeleteReason(text)}
        deleteRecord={deleteRecord}
        inwardNoToDelete={inwardNoToDelete}
        setDeleteError={setDeleteError}
      />
      <ViewDialog
        fullScreen={fullScreen}
        openDialog={openDialog}
        handleClose={handleClose}
        viewDataObj={viewDataObj}
        showDeletedRecords={showDeletedRecords}
      />
      <Typography className={classes.titleText} variant={"h5"}>
        {" "}
        Inward Register Dashboard{" "}
      </Typography>

      <ExportFilter
        fromDate={fromDate}
        setFromDate={setFromDate}
        listError={listError}
        toDate={toDate}
        setToDate={setToDate}
        exportReport={exportReport}
        getDashboardData={() => getDashboardData(showDeletedRecords)}
        deleteChecked={showDeletedRecords}
        toogleDeleteChecbox={() => {
          toggleDeletedRecords(!showDeletedRecords);
          getDashboardData(!showDeletedRecords);
        }}
        isCorporateUser={isCorporateUser}
      />
      <DashboardTable data={data} columns={columns} options={options} />
    </div>
  );
};

export default InwardDashBoard;
