import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import { Typography, Tooltip, IconButton, Backdrop, CircularProgress, Snackbar } from '@material-ui/core';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import CopyrightIcon from '@material-ui/icons/Copyright';
import { MuiThemeProvider, createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { REFERAL_LINKS } from '../../config/config.js';
import MuiAlert from '@material-ui/lab/Alert';

const ReferrelLinks = (props) => {
    const [open, setOpen] = React.useState(false);
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    root: {
                        backgroundColor: "#ffff !important",
                    }
                },
                MUIDataTableHeadCell: {
                    root: {
                        textTransform: "uppercase",
                        fontFamily: 'FuturaLight',

                        fontSize: '0.815rem',
                        backgroundColor: "#DCDCDC !important",
                    }
                },
                MuiTypography: {
                    body1: {
                        fontFamily: 'TableData',
                        fontSize: '0.815rem',

                    }
                },
            }

        });

    const columns = [
        {
            name: "productName", label: "Product\u00a0Name",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposalLink", label: "Proposal\u00a0Link",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                                        return (
                                                <Typography component={'span'} noWrap={false}>
                            {value && value.length>0?value:'Product revision has happened.After June,Once your branch is migrated to bancs,you`ll be able to sell the product again.'}
                        </Typography>
                    )
                }
            }

        },
        {
            name: "proposalLink", label: "Copy\u00a0Link",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                    <>
                    {tableMeta && tableMeta.rowData && tableMeta.rowData[1]&& tableMeta.rowData[1].length>0 ?(
                        <Tooltip title="COPY LINK" interactive>
                            <CopyToClipboard text={value}
                                onCopy={setCopyText(value)}>
                                <IconButton>
                                    <CopyrightIcon onClick={textCopied} />
                                </IconButton>
                            </CopyToClipboard>
                        </Tooltip>):(null)}
                    </>
                    );
                }
            }
        }
    ]
    const [snackOpen, setSnackOpen] = useState(false)
    const [snack, setSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState(true)

    const handleClose = (event, reason) => {
        setSnackOpen(false)

    };
    const [data, setData] = useState();

    useEffect(function () {
        setOpen(!open);
        if (sessionStorage.getItem('token') === null) {
            props.history.push('/');
        }
        axios({
            method: 'get',
            url: REFERAL_LINKS + sessionStorage.getItem('userId') + '/policy-links',
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            setData(response.data);
            setOpen(false);
        }).catch(error => {
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.message)
                    setSnack(true)
                    setColor(false)
                }
                setOpen(false);
            } else {
                console.log(error)
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
                setOpen(false);
            }
        })
    }, []);

    const [copyText, setCopyText] = useState('');

    const textCopied = () => {
        toast.info('Link Copied To Clipboard', { autoClose: 2000 });
    }

    const options = {
        filterType: 'multiselect',
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 40, 50],
        responsive: "scroll",
        sort: false,
        viewColumns: false
    }
    return (
        <div id={'dashboard-mui-table'} style={{ margin: 30 }}>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {snack && <Snackbar
                anchorOrigin={{
                    vertical: (color) ? 'bottom' : 'top',
                    horizontal: 'right',
                }}
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleClose}>
                <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
                    {message}
                </MuiAlert>

            </Snackbar>
            }
            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Referral Links </Typography>
            <div >
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options} />
                </MuiThemeProvider>
            </div>
        </div>
    )
}

export default ReferrelLinks;