export const inwardTypeList = [
  { value: "Proposal", label: "Proposal form" },
  { value: "Premium", label: "Premium" },
  {
    value: "Proposal form along with premium",
    label: "Proposal form along with premium",
  },
  { value: "Medical Document", label: "Medical Document" },
  {
    value: "Supporting documents",
    label:
      "Supporting documents(Consent letter, Bank details, Age proof and income proof etc.)",
  },
  { value: "Cancelation Request", label: "Cancelation Request" },
  { value: "Service Requests", label: "Service Requests (Endorsement)" },
  { value: "Banker’s letter", label: "Banker’s letter about cheque dishonor" },
  { value: "Claim documents", label: "Claim documents" },
];

export const policyTypesList = [
  { value: "Fresh Policy", label: "Fresh Policy" },
  { value: "Portability Policy", label: "Portability Policy" },
  { value: "Renewal Policy", label: "Renewal Policy" },
];

export const modeOfPayList = [
  { value: "Cash", label: "Cash" },
  { value: "Cheque", label: "Cheque" },
  { value: "DD", label: "DD" },
  { value: "Online", label: "Online" },
];

export const initialState = {
  officeCode: "",
  officeName: "",
  inwardType: "",
  inwardDate: null,
  premiumReceiptDate: null,
  customerName: "",
  customerMobile: "",
  customerCode: "",
  policyType: "",
  policyNo: "",
  proposalNo: "",
  productName: "",
  SMName: "",
  SMCode: "",
  agentName: "",
  agentCode: "",
  refRemark: "",
  intermediaryReceivedDate: "",
  modeOfPay: "",
  amountCollected: "",
};

export const initialDisabledField = {
  customerName: false,
  customerMobile: false,
  customerCode: false,
  productName: false,
  smName: false,
  smCode: false,
  agentName: false,
  agentCode: false,
  modeOfPay: false,
}
