
import CryptoJS from "crypto-js";
import { APP_PASS_PHRASE, APP_SALT_KEY, APP_IV_KEY, APP_PSWDENC_SALT_KEY, APP_PSWDENC_IV_KEY, APP_PSWDENC_PASS_PHRASE } from "../config/config";

const iterationCount = 1000;
const keySize = 128;
const passPhrase = APP_PASS_PHRASE;

const salt = APP_SALT_KEY;
const iv = APP_IV_KEY;

const pswdPassPhrase= APP_PSWDENC_PASS_PHRASE;
const pswdSalt= APP_PSWDENC_SALT_KEY;
const pswdIv= APP_PSWDENC_IV_KEY;

const AesUtil = function (keySize, iterationCount) {
    this.keySize = keySize / 32;
    this.iterationCount = iterationCount;
};

AesUtil.prototype.generatePasswordKey = function () {
    const key = CryptoJS.PBKDF2(
        pswdPassPhrase,
        CryptoJS.enc.Hex.parse(pswdSalt),
        { keySize: this.keySize, iterations: this.iterationCount });
    return key;
};

AesUtil.prototype.encryptPassword = function (plainText) {
    const key = this.generatePasswordKey();
    const encrypted = CryptoJS.AES.encrypt(
        plainText,
        key,
        { iv: CryptoJS.enc.Hex.parse(pswdIv) }
    );
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

AesUtil.prototype.generateKey = function () {
    const key = CryptoJS.PBKDF2(
        passPhrase,
        CryptoJS.enc.Hex.parse(salt),
        { keySize: this.keySize, iterations: this.iterationCount });
    return key;
};

AesUtil.prototype.encrypt = function (plainText) {
    const key = this.generateKey();
    const encrypted = CryptoJS.AES.encrypt(
        plainText,
        key,
        { iv: CryptoJS.enc.Hex.parse(iv) }
    );
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
};

AesUtil.prototype.decrypt = function (cipherText) {
    const key = this.generateKey();
    console.log('cipher text in decrypt function: ', cipherText)
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Base64.parse(cipherText)
    });
    const decrypted = CryptoJS.AES.decrypt(
        cipherParams,
        key,
        { iv: CryptoJS.enc.Hex.parse(iv) }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
};

export default new AesUtil(keySize, iterationCount)