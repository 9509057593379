import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import moment from "moment";
import useStyles from "./style";
import {
  isValidValue,
  isValidValueDate,
  validateForm,
  bindCreateInwardPayload,
} from "../createInward/helper";
import {
  policyTypesList,
  inwardTypeList,
  modeOfPayList,
  initialState,
  initialDisabledField,
} from "../createInward/formData";
import {
  Loader,
  PageHeader,
  Placeholder,
  CustomSnack,
} from "../createInward/uiHelper";
import {
  EDIT_INWARD,
  FETCH_ALL_PRODUCTS,
  UPDATE_INWARD,
  GET_POLICY_DETAILS,
} from "../../config/config";

export default function EditInward(props) {
  const classes = useStyles();
  // props
  const inwardId = props.match.params.inwardNo;
  // loader & toaster
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [open, setOpen] = useState(true);

  // dropdown lov
  const [productList, setProductList] = useState([]);

  // field handlers
  const [activeError, setActiveError] = useState(false);
  const [disableField, setDisabled] = useState(initialDisabledField);
  const [dynamicLabel, setLabels] = useState({
    policyType: "Select Policy Type",
    inwardType: "Select Inward Type",
    productName: "Select Product Name",
    modeOfPay: "Select Mode of Pay",
  });

  // form fields
  const [inwardForm, setInwardForm] = useState({
    ...initialState,
    officeCode: sessionStorage.getItem("officeCode"),
    officeName: sessionStorage.getItem("officeName"),
  });
  const [policyInfo, setPolicyInfo] = useState("");

  const [inwardInfo, setInwardInfo] = useState("");

  const {
    officeCode,
    officeName,
    inwardType,
    inwardDate,
    premiumReceiptDate,
    customerName,
    customerMobile,
    customerCode,
    policyType,
    policyNo,
    proposalNo,
    productName,
    SMName,
    SMCode,
    agentName,
    agentCode,
    refRemark,
    modeOfPay,
    intermediaryReceivedDate,
    amountCollected,
  } = inwardForm;

  useEffect(function () {
    if (sessionStorage.getItem("token") === null) {
      props.history.push("/inwardDashBoard");
    }
    getInwardInfo(inwardId);
    getProductList();
  }, []);

  useEffect(() => {
    console.log("message: ", message, snackOpen);
    if (!snackOpen && message === "Inward Updated Successfully") {
      props.history.push("/inwardDashBoard");
    }
  }, [message, snackOpen]);

  const getProductList = () => {
    axios({
      method: "get",
      url: FETCH_ALL_PRODUCTS,
    })
      .then((response) => response?.data?.masterProductDetails)
      .then((item) => {
        setProductList(
          item.map((option) => ({
            productName: `${option?.productName?.replaceAll("(Individual)", "") ||
              option?.productName?.replaceAll("Floater", "")
              }${option?.productType === "I" ? "(Individual)" : "(Floater)"}`,
          })) || []
        );
      })
      .catch((err) => { })
      .finally(() => {
        setOpen(false);
      });
  };

  const getInwardInfo = (inwardNo) => {
    axios({
      method: "get",
      url: EDIT_INWARD + inwardNo,
      headers: { token: sessionStorage?.getItem("token") },
    })
      .then((response) => response?.data)
      .then((item) => {
        const {
          customerName,
          customerMobile,
          proposalNumber,
          smCode,
          smName,
          agentCode,
          agentName,
          policyType,
          productName,
          policyNumber,
          referenceRemark,
          customerCode,
          officeCode,
          officeName,
          premiumReceiptDate,
          inwardDate,
          inwardType,
          intermediaryReceivedDate,
          modeOfPay,
          amountCollected,
        } = item;
        setInwardInfo(item || "");
        setInwardForm({
          ...inwardForm,
          policyType: policyType || "",
          inwardType: inwardType || "",
          inwardDate: isValidValueDate(inwardDate)
            ? moment(inwardDate, "DD/MM/YYYY").toDate()
            : isValidValue(inwardDate)
              ? moment(inwardDate, "DD/MM/YYYY").toDate()
              : null,
          intermediaryReceivedDate: isValidValueDate(intermediaryReceivedDate)
            ? moment(intermediaryReceivedDate, "DD/MM/YYYY").toDate()
            : isValidValue(intermediaryReceivedDate)
              ? moment(intermediaryReceivedDate, "DD/MM/YYYY").toDate()
              : null,
          proposalNo: proposalNumber || "",
          policyNo: policyNumber || "",
          premiumReceiptDate: isValidValueDate(premiumReceiptDate)
            ? moment(premiumReceiptDate, "DD/MM/YYYY").toDate()
            : isValidValue(premiumReceiptDate)
              ? moment(premiumReceiptDate, "DD/MM/YYYY").toDate()
              : null,
          customerName: customerName || "",
          customerMobile: customerMobile || "",
          customerCode: customerCode || "",
          productName: productName || "",
          amountCollected: amountCollected || "",
          modeOfPay: modeOfPay || "",
          SMName: smName || "",
          SMCode: smCode || "",
          agentName: agentName || "",
          agentCode: agentCode || "",
          refRemark: referenceRemark || "",
        });
        setLabels({
          ...(isValidValue(policyType) && { policyType: "Policy Type" }),
          ...(isValidValue(productName) && { productName: "Product Name" }),
          ...(isValidValue(modeOfPay) && { modeOfPay: "Mode of Pay" }),
          ...(isValidValue(inwardType) && { inwardType: "Inward Type" }),
        });
        setPolicyInfo(item);
        if (isValidValue(policyNumber) || isValidValue(proposalNumber)) {
          setDisabled({
            ...disableField,
            ...(isValidValue(customerName) && { customerName: true }),
            ...(isValidValue(customerMobile) && { customerMobile: true }),
            ...(isValidValue(customerCode) && { customerCode: true }),
            ...(isValidValue(policyType) && { policyType: true }),
            ...(isValidValue(productName) && { productName: true }),
            ...(isValidValue(smName) && { smName: true }),
            ...(isValidValue(smCode) && { smCode: true }),
            ...(isValidValue(agentName) && { agentName: true }),
            ...(isValidValue(agentCode) && { agentCode: true }),
            ...(isValidValue(modeOfPay) && { modeOfPay: true }),
          });
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            alert("session expired");
            sessionStorage.clear();
            props.history.push("/");
          } else {
            setSnackOpen(true);
            setMessage(error?.response?.error || "Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        } else {
          setSnackOpen(true);
          setMessage(error?.response?.error || "Something Went Wrong");
          setSnack(true);
          setColor(false);
        }
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const getPolicyInfo = (policyId) => {
    axios({
      method: "get",
      url: GET_POLICY_DETAILS + policyId,
    })
      .then((response) => response?.data)
      .then((item) => {
        const {
          customerName,
          customerMobile,
          customerCode,
          proposalNO,
          smCode,
          smName,
          agentCode,
          agentName,
          productName,
          policyNumber,
          modeOfPay,
        } = item;
        setPolicyInfo(item);
        setInwardForm({
          ...inwardForm,
          customerName: customerName || "",
          customerMobile: customerMobile || "",
          customerCode: customerCode || "",
          proposalNo: proposalNO,
          SMCode: smCode || "",
          SMName: smName || "",
          agentCode: agentCode || "",
          agentName: agentName || "",
          productName: productName || "",
          policyNo: policyNumber,
          modeOfPay,
        });
        setDisabled({
          ...disableField,
          ...(isValidValue(customerName) && { customerName: true }),
          ...(isValidValue(customerMobile) && { customerMobile: true }),
          ...(isValidValue(customerCode) && { customerCode: true }),
          ...(isValidValue(productName) && { productName: true }),
          ...(isValidValue(smName) && { smName: true }),
          ...(isValidValue(smCode) && { smCode: true }),
          ...(isValidValue(agentName) && { agentName: true }),
          ...(isValidValue(agentCode) && { agentCode: true }),
          ...(isValidValue(modeOfPay) && { modeOfPay: true }),
        });
        setLabels({
          ...dynamicLabel,
          ...(isValidValue(productName) && { productName: "Product Name" }),
          ...(isValidValue(modeOfPay) && { modeOfPay: "Mode of Pay" }),
        });
      })
      .catch((error) => {
        setPolicyInfo("");
        resetForm("", "");
        setDisabled(false);
        errorHandler(error);
      })
      .finally(() => {
        setOpen(false);
      });
  };

  const resetForm = (type, value = "") => {
    setPolicyInfo("");
    setInwardForm({
      ...inwardForm,
      policyNo: type === "proposalNo" ? "" : value,
      proposalNo: type === "policyNo" ? "" : value,
      premiumReceiptDate: null,
      customerName: "",
      customerMobile: "",
      customerCode: "",
      productName: "",
      amountCollected: "",
      modeOfPay: "",
      SMName: "",
      SMCode: "",
      agentName: "",
      agentCode: "",
    });
    setDisabled(initialDisabledField);
    setLabels({
      ...dynamicLabel,
      productName: "Select Product Name",
      modeOfPay: "Select Mode of Pay",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userId = sessionStorage.getItem("userId");
    const validateField = validateForm(inwardForm, policyInfo);
    setSnackOpen(false);

    if (validateField) {
      const payload = {
        ...bindCreateInwardPayload(inwardForm, policyInfo, userId),
        inwardId: inwardId,
      };
      setOpen(true);
      axios({
        method: "post",
        url: UPDATE_INWARD,
        data: payload,
        headers: { token: sessionStorage?.getItem("token") },
      })
        .then((response) => response?.data?.status)
        .then((status) => {
          if (status === "SUCCESS") {
            setSnackOpen(true);
            setMessage("Inward Updated Successfully");
            setSnack(true);
            setColor(true);
            setInwardForm({
              ...initialState,
            });
            setActiveError(false);
          }
        })
        .catch((error) => {
          errorHandler(error);
        })
        .finally(() => {
          setOpen(false);
        });
    } else {
      setActiveError(true);
    }
  };

  const errorHandler = (error) => {
    if (error.response !== undefined) {
      if (error.response.status === 403) {
        alert("session expired");
        sessionStorage.clear();
        props.history.push("/inwardDashboard");
      } else if (error?.response?.data?.errorMessages?.length > 0) {
        setSnackOpen(true);
        setMessage(
          error?.response?.data?.errorMessages[0] || "Something Went Wrong"
        );
        setSnack(true);
        setColor(false);
      } else {
        setSnackOpen(true);
        setMessage(error?.response?.error || "Something Went Wrong");
        setSnack(true);
        setColor(false);
      }
    } else {
      setSnackOpen(true);
      setMessage("Something Went Wrong");
      setSnack(true);
      setColor(false);
    }
  };

  return (
    <React.Fragment>
      <Loader backdrop={classes.backdrop} open={open} />
      <PageHeader title="Update Inward" />
      {snack && (
        <CustomSnack
          color={color}
          snackOpen={snackOpen}
          setSnackOpen={setSnackOpen}
          message={message}
        />
      )}
      <Paper className={classes.createIssueFrom}>
        <form onSubmit={handleSubmit} method="post">
          <div className={classes.createIssue}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  inputProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="officeCode"
                  name="officeCode"
                  value={officeCode}
                  onChange={(e) => {
                    setInwardForm({
                      ...inwardForm,
                      officeCode: e.target.value,
                    });
                  }}
                  label="Office Code"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled
                  inputProps={{ shrink: true }}
                  style={{ width: "100%" }}
                  id="officeName"
                  name="officeName"
                  value={officeName}
                  onChange={(e) => {
                    setInwardForm({
                      ...inwardForm,
                      officeName: e.target.value,
                    });
                  }}
                  label="Office Name"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl variant="outlined" className={classes.formControl}>
                  {dynamicLabel.policyType === "Policy Type" && (
                    <InputLabel id="demo-simple-select-outlined-label">
                      Policy Type
                    </InputLabel>
                  )}
                  <Select
                    value={policyType}
                    label="Policy Type"
                    style={{ width: "100%" }}
                    displayEmpty
                    placeholder="Select"
                    onFocus={() => {
                      if (policyType !== "Policy Type") {
                        setLabels({
                          ...dynamicLabel,
                          policyType: "Policy Type",
                        });
                      }
                    }}
                    onBlur={() => {
                      if (policyType === "") {
                        setLabels({
                          ...dynamicLabel,
                          policyType: "Select Policy Type",
                        });
                      }
                    }}
                    onChange={(event) =>
                      setInwardForm({
                        ...inwardForm,
                        policyType: event.target.value,
                      })
                    }
                    renderValue={
                      policyType !== ""
                        ? undefined
                        : () => <Placeholder placeholder={classes.placeholder}>
                          Select Policy Type
                        </Placeholder>
                    }
                    error={
                      activeError &&
                      isValidValue(policyType) &&
                      policyType === "Select Type"
                    }
                    helperText={
                      activeError &&
                        isValidValue(policyType) &&
                        policyType === "Select Type"
                        ? "Policy Type is not valid"
                        : ""
                    }
                  >
                    {policyTypesList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  required
                  id="outlined-select-currency-native"
                  value={inwardType}
                  inputValue={inwardType}
                  options={inwardTypeList}
                  getOptionLabel={(option) => option?.label || ""}
                  onChange={(event, value, reason) => {
                    if (reason === "select-option") {
                      setInwardForm({
                        ...inwardForm,
                        inwardType: value?.value,
                      });
                    } else if (reason === "clear") {
                      setInwardForm({ ...inwardForm, inwardType: "" });
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setInwardForm({ ...inwardForm, inwardType: value });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      onFocus={() =>
                        setLabels({
                          ...dynamicLabel,
                          inwardType: "Inward Type",
                        })
                      }
                      onBlur={() => {
                        if (inwardType === "") {
                          setLabels({
                            ...dynamicLabel,
                            inwardType: "Select Inward Type",
                          });
                        }
                      }}
                      label={dynamicLabel.inwardType}
                      placeholder="Select Inward Type"
                      variant="outlined"
                      error={activeError && inwardType === ""}
                      helperText={
                        activeError && inwardType === ""
                          ? "Inward Type is Mandatory"
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Inward Date"
                      style={{ width: "100%" }}
                      value={inwardDate || null}
                      onChange={(date) =>
                        setInwardForm({ ...inwardForm, inwardDate: date })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{ shrink: true }}
                      openTo="date"
                      placeholder={"MM/DD/YYYY"}
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      required
                      disableFuture
                      error={
                        (activeError &&
                          (inwardDate === null || inwardDate === "")) ||
                        inwardDate > new Date() ||
                        (inwardDate !== null &&
                          inwardDate.toString() === "Invalid Date")
                      }
                      helperText={
                        activeError & (inwardDate === null || inwardDate === "")
                          ? "Inward Date is Mandatory"
                          : inwardDate > new Date()
                            ? "Inward Date should not be in future"
                            : (inwardDate !== null && inwardDate.toString()) ===
                              "Invalid Date"
                              ? "Invalid Date Format"
                              : ""
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Intermediary Received Date"
                      style={{ width: "100%" }}
                      value={intermediaryReceivedDate || null}
                      onChange={(date) =>
                        setInwardForm({
                          ...inwardForm,
                          intermediaryReceivedDate: date,
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputLabelProps={{ shrink: true }}
                      openTo="date"
                      placeholder={"MM/DD/YYYY"}
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      disableFuture
                      error={
                        intermediaryReceivedDate > new Date() ||
                        (intermediaryReceivedDate !== null &&
                          intermediaryReceivedDate.toString() ===
                          "Invalid Date") ||
                        (isValidValueDate(intermediaryReceivedDate) &&
                          isValidValueDate(inwardDate) &&
                          moment(intermediaryReceivedDate).isAfter(inwardDate))
                      }
                      helperText={
                        intermediaryReceivedDate > new Date()
                          ? "Intermediary Received Date should not be in future"
                          : (intermediaryReceivedDate !== null &&
                            intermediaryReceivedDate.toString()) ===
                            "Invalid Date"
                            ? "Invalid Date Format"
                            : isValidValueDate(intermediaryReceivedDate) &&
                              isValidValueDate(inwardDate) &&
                              moment(intermediaryReceivedDate).isAfter(inwardDate)
                              ? "Intermediary date cannot be greater then inward date"
                              : ""
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="propNo"
                  name="propNo"
                  value={proposalNo}
                  inputProps={{
                    maxLength: 23,
                    shrink: true,
                    style: { textTransform: "capitalize" },
                  }}
                  onChange={(e) => {
                    setInwardForm({
                      ...inwardForm,
                      proposalNo: e.target.value?.toLocaleUpperCase(),
                    });
                    if (
                      e.target.value.length >= 23 &&
                      e.target.value?.match(
                        /^[R]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      ) &&
                      inwardInfo?.proposalNumber !== ""
                    ) {
                      setOpen(true);
                      getPolicyInfo(e.target.value?.toLocaleUpperCase());
                    }
                  }}
                  label="Proposal Number"
                  variant="outlined"
                  error={
                    isValidValue(proposalNo)
                      ? !proposalNo.match(
                        /^[R]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                      : false
                  }
                  helperText={
                    isValidValue(proposalNo)
                      ? !proposalNo.match(
                        /^[R]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                        ? "Invalid proposal number"
                        : ""
                      : ""
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  id="polNo"
                  name="polNo"
                  value={policyNo}
                  onChange={(e) => {
                    setInwardForm({
                      ...inwardForm,
                      policyNo: e.target.value?.toLocaleUpperCase(),
                    });
                    if (
                      e.target.value?.length >= 23 &&
                      e.target.value?.match(
                        /^[P]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      ) &&
                      inwardInfo?.proposalNumber !== ""
                    ) {
                      setOpen(true);
                      getPolicyInfo(e.target.value?.toLocaleUpperCase());
                    }
                  }}
                  label="Policy Number"
                  variant="outlined"
                  error={
                    isValidValue(policyNo)
                      ? !policyNo?.match(
                        /^[P]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                      : false
                  }
                  helperText={
                    isValidValue(policyNo)
                      ? !policyNo.match(
                        /^[P]{1}\/[0-9]{6}\/[0-9]{2}\/[0-9]{4}\/[0-9]{6}$/
                      )
                        ? "Invalid policy number"
                        : ""
                      : ""
                  }
                  inputProps={{
                    maxLength: 23,
                    shrink: true,
                    style: { textTransform: "capitalize" },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Premium Receipt Date"
                      style={{ width: "100%" }}
                      value={premiumReceiptDate || null}
                      onChange={(date) =>
                        setInwardForm({
                          ...inwardForm,
                          premiumReceiptDate: date,
                        })
                      }
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      disableFuture
                      InputLabelProps={{ shrink: true }}
                      openTo="date"
                      placeholder={"MM/DD/YYYY"}
                      format="MM/dd/yyyy"
                      views={["year", "month", "date"]}
                      error={
                        premiumReceiptDate > new Date() ||
                        (premiumReceiptDate !== null &&
                          premiumReceiptDate.toString() === "Invalid Date")
                      }
                      helperText={
                        premiumReceiptDate > new Date()
                          ? "Premium Date should not be in future"
                          : (premiumReceiptDate !== null &&
                            premiumReceiptDate.toString()) === "Invalid Date"
                            ? "Invalid Date Format"
                            : ""
                      }
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              {/* <Grid container spacing={6}> */}
              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  disabled={disableField.customerName}
                  id="custName"
                  name="custName"
                  value={customerName}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      customerName: event.target.value,
                    });
                  }}
                  label="Customer Name"
                  variant="outlined"
                  required={Object?.keys(policyInfo)?.length === 0}
                  error={
                    activeError &&
                    Object?.keys(policyInfo)?.length === 0 &&
                    customerName === ""
                  }
                  helperText={
                    activeError &&
                      Object?.keys(policyInfo)?.length === 0 &&
                      customerName === ""
                      ? "Customer Name is Mandatory"
                      : ""
                  }
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  disabled={disableField.customerMobile}
                  id="custMob"
                  name="custMob"
                  value={customerMobile}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      customerMobile: event.target.value,
                    });
                  }}
                  label="Customer Mobile Number"
                  variant="outlined"
                  required={Object?.keys(policyInfo)?.length === 0}
                  error={
                    (activeError &&
                      Object?.keys(policyInfo)?.length === 0 &&
                      customerMobile === "") ||
                    (customerMobile !== "" && customerMobile.length < 10)
                  }
                  helperText={
                    activeError &&
                      Object?.keys(policyInfo)?.length === 0 &&
                      customerMobile === ""
                      ? "Customer Mobile number is Mandatory"
                      : customerMobile.length < 10 &&
                        customerMobile !== "" &&
                        Object?.keys(policyInfo)?.length === 0
                        ? "Mobile number is not valid"
                        : ""
                  }
                  inputProps={{ maxLength: 10, shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.customerCode}
                  style={{ width: "100%" }}
                  id="custCode"
                  name="custCode"
                  value={customerCode}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      customerCode: event.target.value,
                    });
                  }}
                  label="Customer Code"
                  variant="outlined"
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disabled={disableField.productName}
                  id="combo-box-demo"
                  value={productName}
                  inputValue={productName}
                  options={productList}
                  getOptionLabel={(option) => option?.productName || ""}
                  onChange={(event, value, reason) => {
                    if (reason === "select-option") {
                      setInwardForm({
                        ...inwardForm,
                        productName: value?.productName,
                      });
                    } else if (reason === "clear") {
                      setInwardForm({ ...inwardForm, productName: "" });
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setInwardForm({ ...inwardForm, productName: value });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onFocus={() =>
                        setLabels({
                          ...dynamicLabel,
                          productName: "Product Name",
                        })
                      }
                      onBlur={() => {
                        if (productName === "") {
                          setLabels({
                            ...dynamicLabel,
                            productName: "Select Product Name",
                          });
                        }
                      }}
                      label={dynamicLabel.productName}
                      placeholder="Select Product Name"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  type="number"
                  id="amountCollected"
                  name="amountCollected"
                  value={amountCollected}
                  onChange={(e) => {
                    setInwardForm({
                      ...inwardForm,
                      amountCollected: e.target.value,
                    });
                  }}
                  label="Amount Collected"
                  variant="outlined"
                  error={
                    isValidValue(amountCollected) &&
                    amountCollected &&
                    !/^[0-9.,]*$/g?.test(amountCollected)
                  }
                  helperText={
                    isValidValue(amountCollected) &&
                      amountCollected &&
                      !/^[0-9.,]*$/g.test(amountCollected)
                      ? "Invalid Amount"
                      : ""
                  }
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Autocomplete
                  disabled={disableField.modeOfPay}
                  id="combo-box-demo"
                  value={modeOfPay}
                  inputValue={modeOfPay}
                  options={modeOfPayList}
                  getOptionLabel={(option) => option?.label || ""}
                  onChange={(event, value, reason) => {
                    if (reason === "select-option") {
                      setInwardForm({ ...inwardForm, modeOfPay: value?.value });
                    } else if (reason === "clear") {
                      setInwardForm({ ...inwardForm, modeOfPay: "" });
                    }
                  }}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setInwardForm({ ...inwardForm, modeOfPay: value });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onFocus={() =>
                        setLabels({ ...dynamicLabel, modeOfPay: "Mode of Pay" })
                      }
                      onBlur={() => {
                        if (modeOfPay === "") {
                          setLabels({
                            ...dynamicLabel,
                            modeOfPay: "Select Mode of Pay",
                          });
                        }
                      }}
                      label={dynamicLabel.modeOfPay}
                      placeholder="Select Mode of Pay"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.smName}
                  style={{ width: "100%" }}
                  id="Smname"
                  name="Smname"
                  value={SMName}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      SMName: event.target.value,
                    });
                  }}
                  label="SM Name"
                  variant="outlined"
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.smCode}
                  style={{ width: "100%" }}
                  id="SMCode"
                  name="SMCode"
                  value={SMCode}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      SMCode: event.target.value,
                    });
                  }}
                  label="SM Code"
                  variant="outlined"
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.agentName}
                  style={{ width: "100%" }}
                  id="agName"
                  name="agName"
                  value={agentName}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      agentName: event.target.value,
                    });
                  }}
                  label="Agent Name"
                  variant="outlined"

                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={disableField.agentCode}
                  style={{ width: "100%" }}
                  id="agCode"
                  name="agCode"
                  value={agentCode}
                  onChange={(event) => {
                    setInwardForm({
                      ...inwardForm,
                      agentCode: event.target.value,
                    });
                  }}
                  label="Agent Code"
                  variant="outlined"
                  inputProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <TextField
                  style={{ width: "100%" }}
                  multiline
                  rows={4}
                  id="refRemark"
                  required
                  name="refRemark"
                  value={refRemark}
                  onChange={(event) =>
                    setInwardForm({
                      ...inwardForm,
                      refRemark: event.target.value,
                    })
                  }
                  label="Reference Remark"
                  variant="outlined"
                  error={
                    activeError || refRemark !== ""
                      ? !/^[A-Za-z0-9- /\/.,;:]+$/g.test(refRemark)
                      : false
                  }
                  helperText={
                    activeError && refRemark === ""
                      ? "Reference Remark is Mandatory"
                      : refRemark !== "" &&
                        refRemark &&
                        !/^[A-Za-z0-9- /\/.,;:]+$/g.test(refRemark)
                        ? "Invalid Reference Remark"
                        : ""
                  }
                  inputProps={{ maxLength: 100, shrink: true }}
                />
              </Grid>

              <Grid item xs={12} sm={12} style={{ textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                  className={classes.button}
                  color="primary"
                >
                  Update{" "}
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    </React.Fragment>
  );
}