import React from "react";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { Button } from "@material-ui/core";

export const ViewDialog = React.memo(
    ({ fullScreen, openDialog, handleClose, viewDataObj }) => {

        return (

            <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                style={{ overflowX: "hidden" }}
            >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        <Grid container spacing={4}>
                            <Grid item spacing={4} xs={12} sm={6}>
                                <strong>Payment Info</strong>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Grid container spacing={4} style={{ padding: "20px" }}>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Proposal Number
                            </Grid>
                            <Grid>{viewDataObj.proposalNumber || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Premium Amount
                            </Grid>
                            <Grid>{viewDataObj.premiumAmount || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Transaction Amount
                            </Grid>
                            <Grid>{viewDataObj.transactionAmount || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Payment Date
                            </Grid>
                            <Grid>{viewDataObj.paymentDate || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Payment Status
                            </Grid>
                            <Grid>{viewDataObj.paymentStatus || "-"}</Grid>
                        </Grid>
                        
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Policy Number
                            </Grid>
                            <Grid>{viewDataObj.policyNumber || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Policy Status
                            </Grid>
                            <Grid>{viewDataObj.policyStatus || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Policy Date
                            </Grid>
                            <Grid>{viewDataObj.policyDate || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                BankTransaction Id
                            </Grid>
                            <Grid>{viewDataObj.bankTransactionId || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Order Id
                            </Grid>
                            <Grid>{viewDataObj.orderId || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Policy Type
                            </Grid>
                            <Grid>{viewDataObj.policyType || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Product
                            </Grid>
                            <Grid>{viewDataObj.product || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Party Code
                            </Grid>
                            <Grid>{viewDataObj.partyCode || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Proposer Name
                            </Grid>
                            <Grid>{viewDataObj.proposerName || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Proposer Phone
                            </Grid>
                            <Grid>{viewDataObj.proposerPhone || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Proposer Email
                            </Grid>
                            <Grid>{viewDataObj.proposerEmail || "-"}</Grid>
                        </Grid>
                    </Grid>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            OK
            </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    })