import React, { useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone'
import axios from 'axios';
import Box from '@material-ui/core/Box';
import MuiAlert from '@material-ui/lab/Alert';
import { UPLOAD_USER_ROLES } from '../../config/config.js';

import { Button,Typography, Snackbar } from '@material-ui/core';
export default function AdminPage(props) {
  const [files, setFiles] = useState();
  const [snackOpen, setSnackOpen] = useState(false)
  const [snack, setSnack] = useState(false)
  const [message, setMessage] = useState('')
  const [color, setColor] = useState(true)

  const handleClose = (event, reason) => {
    setSnackOpen(false)

  };
  const handleSave = (e) => {
    let formData = new FormData();
    console.log(files)
    formData.append("file", files);
    const url = UPLOAD_USER_ROLES;
    axios({
      'method': 'POST',
      'url': url,
      'Content-Type': 'multipart/form-data',
      'data': formData,
    }).then(response => {
      if (response.data.message === "Updated SuccessFully") {
        setSnackOpen(true)
        setMessage(response.data.message)
        setSnack(true)
        setColor(true)
      }
      else if (response.data.message === "some records failed to upload") {
        setSnackOpen(true)
        setMessage(response.data.message)
        setSnack(true)
        setColor(false)

      }
      else {
        setSnackOpen(true)
        setMessage(response.data.message)
        setSnack(true)
        setColor(false)

      }
    });
  }
  const handleFile = (e) => {
    setFiles(e[0])
  }
  return (

    <div>
      <div>
        <Box display="flex" flexDirection="row" p={2} m={1} bgcolor="#e5e8f1">
          <Typography style={{ fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Upload UserRoles </Typography>
        </Box>
      </div>
      {snack && <Snackbar
        anchorOrigin={{
          vertical: (color) ? 'bottom' : 'top',
          horizontal: 'right',
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleClose}>
        <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
          {message}
        </MuiAlert>

      </Snackbar>
      }
      <DropzoneArea
        onChange={e => { handleFile(e) }}
        acceptedFiles={['image/jpeg', 'image/png', 'image/bmp', 'application/pdf', 'application/vnd.ms-excel', '.xlsx', '.xls', '.csv']} showPreviews={false}
        maxFileSize={5000000}
      />
      <Button variant="contained" style={{ marginTop: 30 }} color="primary" onClick={e => { handleSave(e) }}>
        click here to upload file </Button>
    </div>
  );
}
