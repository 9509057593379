import React from "react";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "@material-ui/core";
import { DialogTitle } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import { Button } from "@material-ui/core";


export const ViewReferralDialog = React.memo(
    ({ fullScreen, openDialog, handleClose, viewDataObj }) => {
        return (
            <Dialog
                fullScreen={fullScreen}
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                style={{ overflowX: "hidden" }}
            >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        <Grid container spacing={4}>
                            <Grid item spacing={4} xs={12} sm={6}>
                                <strong>Referral Info</strong>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <Grid container spacing={4} style={{ padding: "20px" }}>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Referrer Code
                            </Grid>
                            <Grid>{viewDataObj.referrerCode || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Referrer Name
                            </Grid>
                            <Grid>{viewDataObj.partnerName || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Office Code
                            </Grid>
                            <Grid>{viewDataObj.officeCode || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Intermediary Code
                            </Grid>
                            <Grid>{viewDataObj.intermediaryCode || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                FullFiller Code
                            </Grid>
                            <Grid>{viewDataObj.fullFillerCode || "-"}</Grid>
                        </Grid>

                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Created At
                            </Grid>
                            <Grid>{viewDataObj.createdAt || "-"}</Grid>
                        </Grid>
                        {/* <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                First Policy Date
                            </Grid>
                            <Grid>{viewDataObj.firstPolicyDate || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Last Policy Date
                            </Grid>
                            <Grid>{viewDataObj.lastPolicyDate || "-"}</Grid>
                        </Grid> */}
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Email
                            </Grid>
                            <Grid>{viewDataObj.email || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Mobile Number
                            </Grid>
                            <Grid>{viewDataObj.mobileNumber || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                SP Code
                            </Grid>
                            <Grid>{viewDataObj.spCode || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Sub Agent Code
                            </Grid>
                            <Grid>{viewDataObj.subAgentCode || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                SPOC Name
                            </Grid>
                            <Grid>{viewDataObj.spocName || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                SPOC Email
                            </Grid>
                            <Grid>{viewDataObj.spocEmail || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                SPOC Mobile
                            </Grid>
                            <Grid>{viewDataObj.spocMobileNumber || "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Is Proposal Discounted
                            </Grid>
                            <Grid>{viewDataObj.isProposalDiscounted ? "YES" : "NO"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Allowed Products
                            </Grid>
                            <Grid>{viewDataObj.allowedProducts ? viewDataObj.allowedProducts.map(item => {
                                return item + ',' + '\n';
                            }) : "-"}</Grid>
                        </Grid>
                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Discounted Products
                            </Grid>
                            <Grid>{viewDataObj.discountedProducts ? viewDataObj.discountedProducts.map(item => {
                                return item + ',' + '\n';
                            }) : "-"}</Grid>
                        </Grid>

                        <Grid item spacing={4} xs={12} sm={4}>
                            <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Can Download Policy
                            </Grid>
                            <Grid>{viewDataObj.canDownloadPolicy ? "YES" : "NO"}</Grid>
                        </Grid>

                    </Grid>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            OK
            </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        );
    })