process.env.CI = false;
export const GLOBAL_DOMAIN = window.location.origin;
export const REFERAL_GLOBAL_DOMAIN = window.location.origin;
const REFERAL_API_DOMAIN = 'https://vo-api.starhealth.in'
export const VALIDATE_TOKEN = GLOBAL_DOMAIN + "/shdashboard/api/v1/validateToken";
export const CREATE_ISSUE = GLOBAL_DOMAIN + "/shdashboard/api/v1/createIssue";
export const ISSUE_DASHBOARD =
  GLOBAL_DOMAIN + "/shdashboard/api/v1/getAllIssues/";
export const UPDATE_ISSUE = GLOBAL_DOMAIN + "/shdashboard/api/v1/updateIssue";
export const UPDATE_REMARKS =
  GLOBAL_DOMAIN + "/shdashboard/api/v1/updateIssueRemarks";
export const LOGIN = GLOBAL_DOMAIN + "/shdashboard/api/v1/verifyuser";
export const LOGOUT = GLOBAL_DOMAIN + "/shdashboard/api/v1/logoutUser";
export const RESET_PASSWORD =
  GLOBAL_DOMAIN + "/shdashboard/api/v1/resetPassWord";
export const GET_ISSUE = GLOBAL_DOMAIN + "/shdashboard/api/v1/getIssue/";
export const PRAPOSAL_STATUS_ENQUIRY =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/";
export const POLICY_EXPORT =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/policies/export";
export const POLICY_DASHBOARD =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/policies";
export const PAYMENT_DASHBOARD =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/payments";
export const PAYMENT_EXPORT =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/payments/export";
export const REFERAL_LINKS = REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/";
export const GET_CHART_DASHBOARD = REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/";
export const UPLOAD_USER_ROLES =
  GLOBAL_DOMAIN + "/shdashboard/api/v1/uploadUserroles";
export const UPLOAD_MEDIA = GLOBAL_DOMAIN + "/shdashboard/api/v1/uploadFiles";
export const DOWNLOAD_MEDIA = GLOBAL_DOMAIN + "/shdashboard/api/v1/download/";
export const GET_ALL_REFERRERS =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrers";
export const GET_USERS =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrer-users";
export const DISCOUNT_PRODUCTS =
  REFERAL_GLOBAL_DOMAIN + '/referrals/api/v1/discounted-products';
export const DELETE_REFERRER =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrers/";
export const UPDATE_REFERRAL =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrers/";
export const GET_REFERRAL =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrers/";
export const GET_PRODUCTLIST =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/products";
export const GET_PRODUCTLIST_1 =
  REFERAL_API_DOMAIN + "/portal/product/agent/products";
export const CREATE_REFERRAL =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrers";
//Download proposal form
export const DOWNLOAD_PROPOSAL_FORM =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/policies/download-proposal-form";
//Forgot Password API Paths
export const GET_ROLE = GLOBAL_DOMAIN + "/shdashboard/api/v1/getUserRole/";
export const GET_OTP_SSO = GLOBAL_DOMAIN + "/shdashboard/api/v1/sendOtp";
export const VERIFY_OTP_SSO = GLOBAL_DOMAIN + "/shdashboard/api/v1/validateOtp";
export const RESET_PWD_SSO = GLOBAL_DOMAIN + "/shdashboard/api/v1/forgotPassWord";

export const EXPORT_REFERRALS = REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/referrers/export"
export const POLICY_EXPORT_ALL =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/policies/reports/export";
export const PAYMENT_EXPORT_ALL =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/payments/reports/export";
export const CHECK_POLICY_DOWNLOAD =
  REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/policydownload-status";  
//policy download
export const POLICY_DOWNLOAD = REFERAL_GLOBAL_DOMAIN + '/referrals/api/v1/policies/download-policy-doc?policyNumber='

export const PAYMENT_LINK_API = REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/send-proposal-link";
export const EDIT_PROPOSAL_LINK = REFERAL_GLOBAL_DOMAIN + "/referrals/api/v1/send-edit-proposal-link";

//VO API's
export const VO_DOMAIN = "https://vo-api.starhealth.in";
export const FETCH_ALL_PRODUCTS = VO_DOMAIN + '/portal/product/getAllProduct';

//Inward Dashboard
export const UPDATE_INWARD = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/editinwardregister";
export const INWARD_EXPORT = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/export";
export const EDIT_INWARD = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/fetchinwardbyid/"
export const DELETE_INWARD = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/deletebyid/"
export const VIEW_INWARD_DETAILS = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/viewinwarddetails/";
export const FETCH_ALL_INWARDS = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/fetchallinwards/";
export const CREATE_INWARD = GLOBAL_DOMAIN + "/shdashboard/api/v1/inward/createinwardregister";
export const FETCH_INWARD_BYID = GLOBAL_DOMAIN + "shdashboard/api/v1/inward/fetchinwardbyid";
export const CHECK_PROPOSAL = GLOBAL_DOMAIN + '/shdashboard/api/v1/inward/checkProposal?proposalNo=';
export const GET_POLICY_DETAILS = GLOBAL_DOMAIN + '/shdashboard/api/v1/inward/proposaldetails?porposalNo=';

export const APP_PASS_PHRASE = "pAu8qucBAe3PwB6S";
export const APP_SALT_KEY = "77D2D72B999F859C";
export const APP_IV_KEY = "880B9A80B7C02217DBC73D32B04CFA39";

export const APP_PSWDENC_PASS_PHRASE = "INAKAHDUMALEVIPA";
export const APP_PSWDENC_SALT_KEY = "577bd45a17977269694908d80905c32a";
export const APP_PSWDENC_IV_KEY = "9a2b73d130c8796309b776eeb09834b0";
