import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { CREATE_REFERRAL, GET_PRODUCTLIST,GET_PRODUCTLIST_1, DISCOUNT_PRODUCTS } from "../../config/config.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  Button,
  makeStyles,
  Paper,
  Typography,
  Snackbar,
  Input,
  Backdrop,
  CircularProgress,
  useTheme,
  ListItemText,
  Select,
  Checkbox,
  MenuItem
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import { toast } from "react-toastify";

toast.configure();

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
  totalPage: {},
  root: {
    "& > *": {
      margin: theme.spacing(1)
    },
    marginRight: 900
  },

  input: {
    display: "none"
  },
  createReferralFrom: {
    marginLeft: "30px",
    marginRight: "30px"
  },
  createReferral: {
    padding: 20
  },
  button: {
    backgroundColor: "#007bff"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff"
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  }
}));

const MenuProps = {
  PaperProps: {
    style: {
      width: 250
    }
  }
};

export default function ReferrelCreate(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [partnername, setPartnerName] = useState("");
  const [office, setOfficeCode] = useState("");
  const [intermediary, setIntermediaryCode] = useState("");
  const [previousIntermediaryId,setPreviousIntermediaryId] = useState("")
  const [fullfiller, setFullfillerCode] = useState("");
  const [emailid, setPartnerEmailId] = useState("");
  const [mobile, setPartnerMobileNO] = useState("");
  const [sp, setSpCode] = useState("");
  const [selectedProductlist, setProducts] = useState([]);
  const [selectedDiscountProductlist, setDiscountProducts] = useState([]);
  const [subAgent, setSubAgentCode] = useState("");
  const [spocNme, setSpocName] = useState("");
  const [spocemail, setSpocEmailId] = useState("");
  const [activeError, setActiveError] = useState(false);
  const [refprefix, setReferralPrefix] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [listOfProducts, setProductList] = useState([]);
  const [listOfDiscountProducts, setDiscountProductList] = useState([]);
  const [appProducts, setappProduct] = useState([]);
  const [spocMobile, setSpocMobile] = useState("");
  const [downloadPolicyOption, setDownloadPolicyOption] = useState(false)
  const [discounted, setDiscounted] = useState(false)
  const [emailFocus, setEmailFocus] = useState(false);
  const [spocEmailFocus, setSpocEmailFocus] = useState(false);
  const [mobileNumberFocus, setMobileFocus] = useState(false);
  const [spocMobileFocus, setSpocMobileFocus] = useState(false);
  const [state, setState] = React.useState({
    checkedA: false,
    checkedB: false
  });
 const [isDisCountApplicable,setDiscountApplicable]=useState(false)
  const getValue = selectedProductlist => {
    return selectedProductlist
      .filter(item => item.productId)
      .map(item => item.referrerProductName)
      .join(", ");
  };

  const handleChange = event => {
    let value=event.target.value=='true';
    setDiscounted(value);
    setProducts([]);
    setDiscountProducts([]);
  };

  const handleClose = (event, reason) => {
    setSnackOpen(false);
  };

  const handleApplicableProducts = () => {
    let list = [];
    if (discounted === true) {
      list = selectedDiscountProductlist;
    } else {
      list = selectedProductlist;
    }
    list.forEach(obj => {
      appProducts.push(obj.productId);
    });
    const uniqueNames = Array.from(new Set(appProducts));
    uniqueNames.map(item => {
      if (item === 0) {
        uniqueNames.reverse();
        uniqueNames.pop();
        uniqueNames.reverse();
      }
    })
    // setappProduct(uniqueNames);
    return uniqueNames;
  };

  const cancel = () => {
    props.history.push("/refferaldashboard");
  };

  useEffect(function () {
    axios({
      method: "get",
      url: DISCOUNT_PRODUCTS,
      headers: { token: sessionStorage.getItem("token") }
    })
      .then(response => {
        setDiscountProductList([
          {
            productId: 0,
            productName: "SELECT ALL",
            referrerProductName: "SELECT ALL",
            selected: false
          },
          ...response.data
        ]);
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
            props.history.push("/refferaldashboard");
          } else {
            setSnackOpen(true);
            setMessage(error.response);
            setSnack(true);
            setColor(false);
          }
        } else {
          setSnackOpen(true);
          setMessage("Something Went Wrong");
          setSnack(true);
          setColor(false);
          props.history.push("/refferaldashboard");
        }
      });
      let isDisCountApplicable=sessionStorage.getItem("isDiscountApplicable") && JSON.parse(sessionStorage.getItem("isDiscountApplicable"))
      setDiscountApplicable(isDisCountApplicable);
  }, []);

  const getproductList = ()=>{
    setOpen(true);
    axios({
      method: "get",
      url: `${GET_PRODUCTLIST_1}?type=referer&source=referer&intermediaryId=${intermediary}`,
      headers: { token: sessionStorage.getItem("token") }
    })
      .then(response => {
        setProductList([
          {
            productId: 0,
            productName: "SELECT ALL",
            referrerProductName: "SELECT ALL",
            selected: false
          },
          ...response.data.productList
        ],setOpen(false));
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
            props.history.push("/refferaldashboard");
          } else {
            setSnackOpen(true);
            setMessage(error.response);
            setSnack(true);
            setColor(false);
          }
        } else {
          setSnackOpen(true);
          setMessage("Something Went Wrong");
          setSnack(true);
          setColor(false);
          props.history.push("/refferaldashboard");
        }
      });
      // setOpen(false);
  }

  const handleSubmit = event => {
    event.preventDefault();
    let products = handleApplicableProducts();
    let partnerName = partnername;
    let officeCode = office;
    let intermediaryCode = intermediary;
    let fulfillerCode = fullfiller;
    let email = emailid;
    let mobileNumber = mobile;
    let spCode = sp;
    let allowedProducts = products;
    let discountedProducts = discounted ? products : [];
    let subAgentCode = subAgent;
    let spocName = spocNme;
    let spocEmail = spocemail;
    let spocMobileNumber = spocMobile;
    let canDownloadPolicy = downloadPolicyOption;
    let shortName = refprefix;
    let isProposalDiscount = discounted;
    setSnackOpen(false);
    setActiveError(true);
    if (email === "" || mobileNumber === "" || spocemail === "" || spocMobileNumber === "") {
      setSpocEmailFocus(true);
      setEmailFocus(true);
      setMobileFocus(true);
      setSpocMobileFocus(true);
    }
    if (
      partnerName !== "" &&
      intermediaryCode !== "" &&
      mobileNumber !== "" &&
      (/^[0-9 ]*$/g.test(mobile)) &&
      officeCode !== "" &&
      spocName !== "" &&
      spocMobileNumber !== "" && (spocMobileNumber.length >= 10 || spocMobileNumber.length <= 12) &&
      (intermediaryCode.startsWith('CO') ? spCode !== "" : true)
    ) {
      setOpen(true);
      axios({
        method: "post",
        url: CREATE_REFERRAL,
        data: {
          partnerName,
          officeCode,
          intermediaryCode,
          fulfillerCode,
          email,
          mobileNumber,
          spCode,
          allowedProducts,
          subAgentCode,
          spocName,
          spocEmail,
          spocMobileNumber,
          canDownloadPolicy,
          shortName,
          isProposalDiscount,
          discountedProducts
        },
        headers: { token: sessionStorage.getItem("token") }
      })
        .then(response => {
          setOpen(false);
          if (response.status === 201) {
            toast.success("Referrer Created Successfully");
            props.history.push("/refferaldashboard");
          } else {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        })
        .catch(error => {
          setOpen(false);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              alert("session expired");
              sessionStorage.clear();
              props.history.push("/");
            } else {
              setSnackOpen(true);
              setMessage(error.response.data.message);
              setSnack(true);
              setColor(false);
            }
          } else {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        });
    }

  };
  const setCheckValues = e => {
    let selectedArr = e.target.value;
    const prevSelectedArrSelectAllExists = selectedProductlist.find(
      item => item.productId === 0
    )
      ? true
      : false;
    if (
      selectedArr.find(item => item.productId === 0) &&
      !prevSelectedArrSelectAllExists
    ) {
      selectedArr = listOfProducts;
    } else if (
      !selectedArr.find(item => item.productId === 0) &&
      selectedArr.length &&
      prevSelectedArrSelectAllExists
    ) {
      selectedArr = [];
    } else if (
      !selectedArr.find(item => item.productId === 0) &&
      selectedArr.length === listOfProducts.length - 1
    ) {
      selectedArr = listOfProducts;
    } else if (
      !selectedArr.find(item => item.productId === 0) &&
      selectedArr.length
    ) {
      selectedArr = selectedArr;
    } else if (!selectedArr.length) {
      selectedArr = [];
    } else {
      selectedArr = e.target.value.filter(item => item.productId);
    }

    setProducts(selectedArr);
  };
  const setDiscountCheckValues = e => {
    let selectedArr = e.target.value;
    const prevSelectedArrSelectAllExists = selectedDiscountProductlist.find(
      item => item.productId === 0
    )
      ? true
      : false;
    if (
      selectedArr.find(item => item.productId === 0) &&
      !prevSelectedArrSelectAllExists
    ) {
      selectedArr = listOfDiscountProducts;
    } else if (
      !selectedArr.find(item => item.productId === 0) &&
      selectedArr.length &&
      prevSelectedArrSelectAllExists
    ) {
      selectedArr = [];
    } else if (
      !selectedArr.find(item => item.productId === 0) &&
      selectedArr.length === listOfDiscountProducts.length - 1
    ) {
      selectedArr = listOfDiscountProducts;
    } else if (
      !selectedArr.find(item => item.productId === 0) &&
      selectedArr.length
    ) {
      selectedArr = selectedArr;
    } else if (!selectedArr.length) {
      selectedArr = [];
    } else {
      selectedArr = e.target.value.filter(item => item.productId);
    }
    setProducts(selectedArr);

    setDiscountProducts(selectedArr);
  };
  const onChangeIntermediary = (e)=>{
    setIntermediaryCode(e.target.value)
  }

  const onFocusOut = (e)=>{
  if(intermediary.length>0 && intermediary !== previousIntermediaryId){
    getproductList()
    setPreviousIntermediaryId(intermediary)
  }
  }
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Box display="flex" flexDirection="row" p={2} m={1} bgcolor="#e5e8f1">
          <Typography
            style={{
              fontFamily: "Futura",
              fontWeight: "400px",
              fontSize: "1.15rem"
            }}
            variant={"h5"}
          >
            {" "}
            Create Referral{" "}
          </Typography>
        </Box>
      </div>
      {snack && (
        <Snackbar
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right"
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      <Paper className={classes.createReferralFrom}>
        <form>
          <div className={classes.createReferral}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}></Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={partnername}
                  required
                  onChange={e => setPartnerName(e.target.value)}
                  label="Partner Name"
                  variant="outlined"
                  error={activeError & (partnername === "")}
                  helperText={
                    activeError & (partnername === "")
                      ? "Partner Name is Mandatory"
                      : " "
                  }
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={refprefix}
                  required
                  type="text"
                  onChange={e => {
                    setReferralPrefix(e.target.value);
                  }}
                  label="Short Name"
                  variant="outlined"
                  error={
                    (activeError && refprefix === "") ||
                    (refprefix.length < 4 && refprefix.length > 0) ||
                    (!/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                      refprefix
                    ) &&
                      refprefix.length > 0)
                  }
                  helperText={
                    activeError & (refprefix === "")
                      ? "Short Name is Mandatory"
                      : refprefix.length < 4 && refprefix.length > 0
                        ? "Length should be greater then or equal to 4 "
                        : !/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                          refprefix
                        ) && refprefix.length > 0
                          ? "Only Alphanumeric characters are allowed"
                          : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={emailid}
                  required
                  type="email"
                  onChange={e => {
                    setPartnerEmailId(e.target.value);
                  }}
                  onBlur={e => {
                    setEmailFocus(true);
                  }}
                  onFocus={e => setEmailFocus(false)}
                  label="Partner Email"
                  variant="outlined"
                  error={
                    (activeError && emailid === "" && emailFocus) ||
                    emailid.length > 40 ||
                    (emailid.length > 0 && emailFocus &&
                      !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(
                        emailid
                      ))
                  }
                  helperText={
                    activeError && emailid === "" && emailFocus
                      ? "Partner Email is Mandatory"
                      : emailid.length > 0 && emailFocus &&
                        !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(
                          emailid
                        )
                        ? "Invalid Partner Email"
                        : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  required
                  value={mobile}
                  //type="number"
                  className={classes.number}
                  onChange={e => {
                    setPartnerMobileNO(e.target.value)
                  }
                  }
                  onBlur={e => {
                    if (!/^[0-9 ]*$/g.test(mobile)) {
                      setMobileFocus(true);
                    }
                  }}
                  onFocus={e => setMobileFocus(false)}
                  label="Partner Mobile"
                  variant="outlined"
                  error={
                    (activeError && mobile === "" && mobileNumberFocus) ||
                    (mobileNumberFocus && !/^[0-9 ]*$/g.test(
                      mobile
                    ))
                  }
                  helperText={
                    activeError & (mobile === "" && mobileNumberFocus)
                      ? "Partner mobile number is Mandatory"
                      : (mobileNumberFocus && !/^[0-9 ]*$/g.test(mobile))
                        ? "Invalid Character MobileNumber"
                        : ""
                  }
                />{" "}
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={intermediary}
                  required
                  onChange={e => onChangeIntermediary(e)}
                  label="Intermediary Code"
                  variant="outlined"
                  onBlur={(event) => onFocusOut(event)}
                  error={
                    activeError & (intermediary === "") ||
                    !/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                      intermediary
                    )
                  }
                  helperText={
                    activeError & (intermediary === "")
                      ? "Intermediary Code is Mandatory"
                      : intermediary.length > 0 &&
                        !/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                          intermediary
                        )
                        ? "Only Alphanumeric characters are allowed"
                        : " "
                  }
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={office}
                  required
                  type="number"
                  className={classes.number}
                  onChange={e => setOfficeCode(e.target.value)}
                  label="Office Code"
                  variant="outlined"
                  error={activeError & (office === "")}
                  helperText={
                    activeError & (office === "")
                      ? "Office code is Mandatory"
                      : office.length > 0 &&
                        !/^([0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                          office
                        )
                        ? "Only Numbers are allowed"
                        : " "
                  }
                ></TextField>{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={fullfiller}
                  onChange={e => setFullfillerCode(e.target.value)}
                  required
                  label="Fullfiller Code"
                  variant="outlined"
                  error={
                    (activeError && fullfiller === "") ||
                    !/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                      fullfiller
                    )
                  }
                  helperText={
                    activeError & (fullfiller === "")
                      ? "Fullfiller Code is Mandatory"
                      : fullfiller.length > 0 &&
                        !/^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 _.-]*)$/g.test(
                          fullfiller
                        )
                        ? "Only Alphanumeric characters are allowed"
                        : " "
                  }
                ></TextField>{" "}
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  id="spcode"
                  name="spcode"
                  value={sp}
                  onChange={e => setSpCode(e.target.value)}
                  label="SP Code"
                  variant="outlined"
                  required={intermediary.startsWith('CO') ? true : false}
                  error={
                    activeError && intermediary.startsWith('CO') && (sp === '' || sp === null)
                  }
                  helperText={activeError && intermediary.startsWith('CO') && (sp === '' || sp === null) ? 'SP code is mandatory' : ''}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={subAgent}
                  onChange={e => setSubAgentCode(e.target.value)}
                  label="Sub Agent Code"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  value={spocNme}
                  onChange={e => setSpocName(e.target.value)}
                  label="SPOC Name"
                  required
                  variant="outlined"
                  error={activeError & (spocNme === "")}
                  helperText={
                    activeError & (spocNme === "")
                      ? "SPOC Name is Mandatory"
                      : " "
                  }
                />{" "}
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  required
                  id="email"
                  name="email"
                  value={spocemail}
                  onChange={e => {
                    setSpocEmailId(e.target.value);
                  }}
                  onBlur={e => {
                    setSpocEmailFocus(true);
                  }}
                  onFocus={e => setSpocEmailFocus(false)}
                  label="SPOC Email"
                  variant="outlined"
                  error={
                    (activeError && spocemail === "" && spocEmailFocus) ||
                    (spocemail.length > 0 && spocEmailFocus &&
                      !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(
                        spocemail
                      ))
                  }
                  helperText={
                    activeError && spocemail === "" && spocEmailFocus
                      ? "SPOC Email is Mandatory"
                      : spocemail.length > 0 && spocEmailFocus &&
                        !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(
                          spocemail
                        )
                        ? "Invalid SPOC Email ID"
                        : ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  style={{ width: "100%" }}
                  required
                  value={spocMobile}
                  type="number"
                  className={classes.number}
                  onChange={e => {
                    if (
                      e.target.value.length < 12 ||
                      e.target.value.length === 12
                    ) {
                      setSpocMobile(e.target.value);
                    }
                  }}
                  onBlur={e => {
                    setSpocMobileFocus(true);
                  }}
                  onFocus={e => setSpocMobileFocus(false)}
                  label="SPOC Mobile"
                  variant="outlined"
                  error={
                    (activeError && spocMobile === "" && spocMobileFocus) ||
                    spocMobile.length > 12 ||
                    (spocMobileFocus && spocMobile.length < 10 && spocMobile.length > 0)
                  }
                  helperText={
                    activeError & (spocMobile === "" && spocMobileFocus)
                      ? "SPOC mobile number is Mandatory"
                      : spocMobileFocus && spocMobile.length < 10 && spocMobile.length > 0
                        ? "Length should be 10 to 12"
                        : ""
                  }
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField style={{ width: '100%' }} required id="outlined-select-currency-native" value={downloadPolicyOption} onChange={e => setDownloadPolicyOption(e.target.value)} name="policyOption" select label="Policy Download" SelectProps={{ native: true, }} variant="outlined" >
                  <option key={true} value={true}>YES</option>
                  <option key={false} value={false}>NO</option>
                </TextField>
              </Grid>
             {isDisCountApplicable&&( <Grid item xs={12} sm={3}>
                <TextField style={{ width: '100%' }} required id="outlined-select-currency-native" value={discounted} onChange={e => handleChange(e)} name="discounted" select label="Discounted Link" SelectProps={{ native: true, }} variant="outlined" >
                  <option key={true} value={true}>YES</option>
                  <option key={false} value={false}>NO</option>
                </TextField>
              </Grid>)}
              {isDisCountApplicable&& (<>
              {discounted  ? (
                <Grid item xs={12} sm={3}>
                  <span style={{ fontSize: 18 }}>Discounted Products*</span>
                  <Select
                    style={{ width: "100%" }}
                    multiple
                    value={selectedDiscountProductlist}
                    onChange={e => setDiscountCheckValues(e)}
                    input={<Input />}
                    renderValue={selected =>
                      selected
                        .filter(item => item.productId)
                        .map(item => item.referrerProductName)
                        .join(", ")
                    }
                    MenuProps={MenuProps}
                  >
                    {discounted
                      ? listOfDiscountProducts.map(product => (
                        <MenuItem key={product.productId} value={product}>
                          <Checkbox
                            checked={
                              selectedDiscountProductlist.find(
                                item => item.productId === product.productId
                              )
                                ? true
                                : false
                            }
                          />
                          <ListItemText
                            primary={product.referrerProductName}
                          />
                        </MenuItem>
                      ))
                      : ""}
                  </Select>
                  {activeError && selectedDiscountProductlist.length === 0 ? (
                    <p style={{ color: "red" }}>Select atleast one product</p>
                  ) : (
                      ""
                    )}
                </Grid>
              ) : (
                  <Grid item xs={12} sm={3}>
                    <span style={{ fontSize: 18 }}>Discounted Products</span>
                    <TextField disabled></TextField>
                  </Grid>
                )}
                </>)}

              {(discounted &&isDisCountApplicable)? (
                <Grid item xs={12} sm={3}>
                  <span style={{ fontSize: 18 }}>Allowed Products</span>
                  <TextField
                    disabled
                    id="standard-multiline-flexible"
                    value={getValue(selectedProductlist)}
                  ></TextField>
                </Grid>
              ) : (
                  <Grid item xs={12} sm={3}>
                    <span style={{ fontSize: 18 }}>Allowed Products*</span>
                    <Select
                      style={{ width: "100%" }}
                      multiple
                      value={selectedProductlist}
                      onChange={e => {
                        setCheckValues(e);
                      }}
                      input={<Input />}
                      renderValue={selected =>
                        selected
                          .filter(item => item.productId)
                          .map(item => item.referrerProductName)
                          .join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {discounted
                        ? ""
                        : listOfProducts.map(product => (
                          <MenuItem key={product.productId} value={product}>
                            <Checkbox
                              checked={
                                selectedProductlist.find(
                                  item => item.productId === product.productId
                                )
                                  ? true
                                  : false
                              }
                            />
                            <ListItemText
                              primary={product.referrerProductName}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                    {activeError && selectedProductlist.length === 0 ? (
                      <p style={{ color: "red" }}>Select atleast one product</p>
                    ) : (
                        ""
                      )}
                  </Grid>
                )}
              <Grid item xs={12} sm={8}>
                {" "}
              </Grid>
              <Grid item xs={12} sm={2}>
                <div style={{ paddingLeft: "40px", paddingTop: "20px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={cancel}
                    className={classes.button}
                    color="primary"
                  >
                    cancel
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={2}>
                <div style={{ paddingLeft: "20px", paddingTop: "20px" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit}
                    className={classes.button}
                    color="primary"
                  >
                    Create{" "}
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}></Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    </React.Fragment>
  );
}