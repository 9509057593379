import { Grid, Card, CardContent, Typography, Snackbar, makeStyles, Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import axios from 'axios';
import { GET_CHART_DASHBOARD } from '../../config/config';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));

const MyBusiness = (props) => {
    const classes = useStyles();
    let newResponse = {
        key: ["No Business"],
        data: ["1"]
    }

    const [open, setOpen] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false)
    const [snack, setSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState(true)

    const [monthlyData, setMonthlyData] = useState({ key: [], data: [] })
    const [productData, setProductData] = useState({ key: [], data: [] })
    const [weeklyData, setWeeklyData] = useState({ key: [], data: [] })

    let colors = []
    var today = new Date();
    let month = today.toLocaleString('default', { month: 'long' });
    let year = today.getFullYear();
    const handleClose = (event, reason) => {
        setSnackOpen(false)

    };

    useEffect(function () {

        if (sessionStorage.getItem('token') === null) {
            props.history.push('/');
        }
        getMonthlyData();
        getWeeklyData();
        getProductData();
    }, []);

    const getProductData = () => {
        axios({
            method: 'get',
            url: GET_CHART_DASHBOARD + sessionStorage.getItem('userId') + '/product-dashboard',
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            if (response.data.data.length === 0) {
                setProductData(newResponse)
            } else {
                setProductData(response.data)
            }
            setOpen(false)
        }).catch(error => {
            setOpen(false)
            if (error.response !== undefined) {
                if (error.response.status === 403) {
                    alert('session expired')
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage('Something went wrong')
                    setSnack(true)
                    setColor(false)
                }
            }
            else {
                setSnackOpen(true)
                setMessage('Something went wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    const getMonthlyData = () => {
        setOpen(true);
        axios({
            method: 'get',
            url: GET_CHART_DASHBOARD + sessionStorage.getItem('userId') + '/monthly-dashboard',
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            setMonthlyData(response.data)
            setOpen(false)
        }).catch(error => {
            setOpen(false)
            if (error.response !== undefined) {
                if (error.response.status === 403) {
                    alert('session expired')
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage('Something went wrong')
                    setSnack(true)
                    setColor(false)
                }
            }
            else {
                setSnackOpen(true)
                setMessage('Something went wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }

    const getWeeklyData = () => {
        axios({
            method: 'get',
            url: GET_CHART_DASHBOARD + sessionStorage.getItem('userId') + '/weekly-dashboard',
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            setWeeklyData(response.data)
            setOpen(false)
        }).catch(error => {
            setOpen(false)
            if (error.response !== undefined) {
                if (error.response.status === 403) {
                    alert('session expired')
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage('Something went wrong')
                    setSnack(true)
                    setColor(false)
                }
            }
            else {
                setSnackOpen(true)
                setMessage('Something went wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    const getRandomColor = () => {
        if (productData.data.length >= 1 && productData.key[0] !== "No Business") {
            for (let i = 0; i < productData.data.length; i++) {
                colors.push('#' + Math.floor(Math.random() * 16777215).toString(16));
            }
        } else {
            colors.push("#EEEDEC")
        }
        return colors;
    }


    return (
        <div style={{ margin: 30 }}>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Dashboard </Typography>
            {snack && <Snackbar
                anchorOrigin={{
                    vertical: (color) ? 'bottom' : 'top',
                    horizontal: 'right',
                }}
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleClose}>
                <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
                    {message}
                </MuiAlert>

            </Snackbar>
            }
            <Grid container justify="flex-start" style={{ marginTop: '30px' }}>
                <Grid item xs={12} sm={6} >
                    <Card elevation={200} style={{ width: "450px", boxShadow: "2px 4px 4px #ccc" }}>
                        <CardContent>
                            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Weekly Dashboard - {month} </Typography>
                            <Bar
                                style={{ width: 5 }}
                                data={{
                                    labels: weeklyData.key,
                                    datasets: [{
                                        barThickness: 15,
                                        label: 'premium',
                                        backgroundColor: 'rgb(28, 211, 206)',
                                        data: weeklyData.data
                                    },
                                    ],
                                }}
                                options={{
                                    plugins: {
                                        anchor: "start",
                                        align: 20

                                    },
                                    animation: false,
                                    legend: { display: true, position: 'bottom', fontFamily: 'Futura' },
                                    scales: {
                                        xAxes: [{
                                            offset: true,
                                            stacked: true,
                                            gridLines: {
                                                display: false
                                            },
                                            ticks: {
                                                display: true,
                                            }
                                        }],
                                        yAxes: [{
                                            gridLines: {
                                                display: false
                                            },
                                            ticks: {
                                                stepSize: 2500,
                                                suggestedMin: 1000,
                                                display: false
                                            }
                                        }]
                                    }
                                }}

                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card style={{ width: "450px", borderRadius: "3px", boxShadow: "2px 4px 4px #ccc" }}>
                        <CardContent>
                            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Monthly Dashboard -{year} </Typography>
                            <Bar
                                style={{ width: 5 }}
                                data={{
                                    labels: monthlyData.key,
                                    datasets: [{
                                        barThickness: 15,
                                        label: "premium",
                                        backgroundColor: '#f2b43b',
                                        legend: { display: true },
                                        data: monthlyData.data
                                    }],

                                }}
                                options={{
                                    animation: false,
                                    legend: { display: true, position: 'bottom', fontFamily: 'Futura' },
                                    scales: {
                                        xAxes: [{
                                            gridLines: {
                                                display: false
                                            }
                                        }],
                                        yAxes: [{
                                            gridLines: {
                                                display: false
                                            },

                                            ticks: {
                                                suggestedMin: 10,
                                                stepSize: 2500,
                                                display: false
                                            }
                                        }]
                                    }
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Card style={{ width: "450px", marginTop: "30px", boxShadow: "2px 4px 4px #ccc" }}>
                        <CardContent>
                            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Product Dashboard - {month} </Typography>
                            <Pie
                                style={{ width: 5 }}
                                data={{
                                    labels: productData.key,
                                    datasets: [{
                                        label: "premium",
                                        backgroundColor: getRandomColor(),
                                        data: productData.data,
                                    }]
                                }}
                                options={{
                                    animation: {
                                        animateRotate: false
                                    },
                                    legend: {
                                        display: true,
                                        position: 'bottom',
                                        fontFamily: 'Futura',
                                        align: "start"
                                    },
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </div>
    )
}

export default MyBusiness;