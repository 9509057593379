import axios from "axios";
import { GET_ROLE, GET_OTP_SSO, VERIFY_OTP_SSO, RESET_PWD_SSO } from "../config/config";

export const getUserRole = async (referralId) => {
    let responseObj = await axios({
        method: 'get',
        url: GET_ROLE + referralId,
        headers: {
            token: "dfghj"
        }
    }).then(response => {
        return response.data
    }).catch(error => {
        return error.response;
    })
    return responseObj;
}

export const getOtp = async (referralId) => {

    let responseObj = await axios({
        method: 'get',
        url: GET_OTP_SSO,
        headers: {
            
            useridoremailid: referralId
        }
    }).then(response => {
        console.log('response from local api: ', response);
        return response.data
    }).catch(error => {
        return error;
    })
    return responseObj;
}

export const verifyOtp = async (otp, referralId) => {

    let responseObj = await axios({
        method: 'get',
        url: VERIFY_OTP_SSO,
        headers: {
            clientId: 'agent-app',
            useridoremailid: referralId,
            forgotpasswordotp: otp
        }
    })
        .then(response => {
            return response.data;
        }).catch(error => {
            return error.response;
        });
    return responseObj;
}

export const resetPassword = async (otp, referralId, encryptedPwd) => {
    
    let responseObj = await axios({
        method: 'post',
        url: RESET_PWD_SSO,
        data: {
            oldPassword: "",
            newPassword: encryptedPwd
        },
        headers: {
           
            userId: referralId,
            forgotpasswordotp: otp
        },
        
    })
        .then(response => {
            return response.data
        }).catch(error => {
            return error;
        });
    
    return responseObj;
}