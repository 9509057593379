import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import logo from "../../images/logo.png";
import star from "../../images/star.jpg";
import "./Login.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import { LOGIN } from "../../config/config.js";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Link } from "react-router-dom";
import {
  getUserRole,
  getOtp,
  verifyOtp,
  resetPassword,
} from "../../api/forgotPassword";
import AesUtil from "../../util/Crypto";
import { checkPolicDownloadStatus } from "../../api/referralService";
import Footer from "../footer/footer";
const Login = (props) => {
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    email: "",
  });

  const [referralId, setReferralId] = useState("");
  const handleOnChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [enableForgotPassword, setEnableForgotPassword] = useState(false);

  const handleForgotPassword = () => {
    setEnableForgotPassword(true);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    axios({
      method: "post",
      url: LOGIN,
      data: {
        userId: values.email,
        password: AesUtil.encryptPassword(values.password),
        deviceType: "web",
      },
    })
      .then((response) => {
        if (response.data.message === "SUCCESS") {
          sessionStorage.setItem("userId", values.email);
          sessionStorage.setItem("token", response.data.token);
          sessionStorage.setItem("role", response.data.role);
          sessionStorage.setItem("isDiscountApplicable",response.data.discountApplicable);
          if (response.data.role === "Referral") {
            if (response.data.isFirstLogin == "Y") {
              sessionStorage.setItem("firstTimeLogin", true);
            } else {
              sessionStorage.setItem("firstTimeLogin", false);
            }
            console.log("in referral condition: ");
            checkPolicDownloadStatus(values.email)
              .then((response) => {
                if (
                  response.data &&
                  response.data.message === "Can enable Policy download"
                ) {
                  sessionStorage.removeItem("role");
                  sessionStorage.setItem("role", "referrer2");
                  props.history.push("/policyreports");
                } else {
                  sessionStorage.setItem("role", "Referral");
                  props.history.push("/policyreports");
                }
              })
              .catch((error) => {
                sessionStorage.setItem("role", "Referral");
                props.history.push("/policyreports");
              });
          } else if (sessionStorage.getItem("role") === "Admin") {
            props.history.push("/refferaldashboard");
          } else if (
            sessionStorage.getItem("role") === "BranchUser" ||
            sessionStorage.getItem("role") === "CorporateUser"
          ) {
            sessionStorage.setItem("officeCode", response.data.officeCode);
            sessionStorage.setItem("officeName", response.data.officeName);
            props.history.push("/inwardDashBoard");
          } else {
            props.history.push("/dashboard");
          }
        } else {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const [isReferralBlank, setIsReferralBlank] = useState(false);
  const [isReferral, setIsReferral] = useState(false);
  const [verficationFlag, setVerificationFlag] = useState(false);
  const [resetPasswordFlag, setResetPwdFlag] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [mobileNumber, setMobileNo] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [enablePassword, setEnablePassword] = useState(false);
  const [role, setRole] = useState("");
  const [userNotFound, setUserNotFound] = useState(false);
  const handleClickEnablePassword = () => {
    setEnablePassword(!enablePassword);
  };
  const [showUserId, setShowUserId] = useState("");
  const handleSendOTP = async () => {
    setShowUserId(referralId);
    if (referralId === "") {
      setUserNotFound(false);
      setIsReferralBlank(true);
    } else {
      setIsReferralBlank(false);
      let userRoleResponse = await getUserRole(referralId);
      if (userRoleResponse.status === 404) {
        setUserNotFound(true);
      } else if (
        userRoleResponse &&
        (userRoleResponse.role === "Referral" ||
          userRoleResponse.role === "Admin")
      ) {
        setRole(userRoleResponse.role);
        let getOtpResponse = await getOtp(referralId);
        if (getOtpResponse && getOtpResponse.message === "SUCCESS") {
          setMobileNo(getOtpResponse.data.mobile);
          setVerificationFlag(true);
          setIsReferralBlank(false);
        } else {
          setOtpError(true);
          alert("OTP sending failed");
        }
      } else {
        setRole(userRoleResponse.role);
        setIsReferralBlank(false);
        setIsReferral(true);
      }
    }
  };
  const [verifyOtpError, setVerifyOtpErrorFlag] = useState(false);
  const [otpMandatory, setOtpMandatory] = useState(false);
  const handleVerifyOTP = async () => {
    if (otp === "") {
      setVerifyOtpErrorFlag(false);
      setOtpMandatory(true);
    } else {
      let verifyOtpResponse = await verifyOtp(otp, referralId);
      if (verifyOtpResponse && verifyOtpResponse.message === "SUCCESS") {
        setVerificationFlag(false);
        setResetPwdFlag(true);
        setOtpMandatory(false);
        setVerifyOtpErrorFlag(false);
      } else {
        setOtpMandatory(false);
        setVerifyOtpErrorFlag(true);
      }
    }
  };
  const backTologin = () => {
    setReferralId("");
    setEnableForgotPassword(false);
    setIsReferral(false);
    setIsReferralBlank(false);
    setResetPwdFlag(false);
    setNewPassword("");
    setConfirmPassword("");
    setNewPwdFlag(false);
    setPwdSuccessfull(false);
    setOtp("");
    setVerifyOtpErrorFlag(false);
    setPasswordValidationError(false);
    setVerificationFlag(false);
    setEnablePassword(false);
    setOtpMandatory(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    setUserNotFound(false);
  };
  const [newPwdFlag, setNewPwdFlag] = useState(false);
  const [pwdSuccessfull, setPwdSuccessfull] = useState(false);
  const [pwdValidationError, setPasswordValidationError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const passwordValidations = () => {
    if (newPassword === "") {
      setNewPasswordError(true);
    } else if (confirmPassword === "") {
      setConfirmPasswordError(true);
    } else if (newPassword === "" && confirmPassword === "") {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
    }
  };
  const handleResetPassword = async () => {
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    //passwordValidations()
    if (newPassword === "" && confirmPassword === "") {
      setNewPasswordError(true);
      setConfirmPasswordError(true);
    } else if (newPassword === "") {
      setNewPasswordError(true);
    } else if (confirmPassword === "") {
      setConfirmPasswordError(true);
    } else if (newPassword === confirmPassword) {
      setNewPasswordError(false);
      setConfirmPasswordError(false);
      let encryptedPwd = AesUtil.encryptPassword(newPassword);
      let resetPasswordResponse = await resetPassword(
        otp,
        referralId,
        encryptedPwd
      );
      if (resetPasswordResponse.message === "SUCCESS") {
        setPwdSuccessfull(true);
      } else {
        alert("Reset password Failed");
      }
    } else {
      setNewPwdFlag(true);
    }
  };
  return (
    <Grid
      className="login-body"
      container
      spacing={12}
      style={{ zIndex: 3, height: "100vh" }}
    >
      <Grid
        item
        xs={6}

        spacing={12}
        style={{
          zIndex: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <img src={logo} className="logo" alt="logo" />
        </Box>
      </Grid>
      <Grid
        item
        xs={6}
        spacing={12}
        style={{
          zIndex: 3,
          backgroundImage: "linear-gradient(#2252e5,#01afee)",
          backgroundColor: "#1576e9",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Card
          style={{
            backgroundColor: "transparent",
            border : 'none',
            boxShadow : 'none',
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {pwdSuccessfull ? (
            <div style={{ minHeight: 80, marginBottom: "200px" }}>
              <h2
                style={{
                  marginTop: "120px",
                  
                  color: "#fff",
                  fontSize: "1.5625rem",
                  fontWeight: "500",
                  fontFamily: "Futura",
                }}
              >
                Reset Password
              </h2>
              <p
                style={{
                  marginTop: "50px",
                  
                  fontSize: "25px",
                  fontFamily: "Futura",
                  color: "#fff",
                }}
              >
                Password Reset for user {referralId} is successfull
              </p>
              <Button
                style={{
                  display: "inline-block",
                  marginTop: "30px",
                  
                  backgroundColor: "#0c3470",
                  color: "#fff",
                }}
                onClick={backTologin}
                className="btn btn-secondary hvr-ripple-out"
              >
                Back to login
              </Button>
            </div>
          ) : resetPasswordFlag ? (
            <div style={{ minHeight: 80, marginBottom: "200px" }}>
              <h2
                style={{
                  marginTop: "120px",
                  
                  color: "#fff",
                  fontSize: "1.5625rem",
                  fontWeight: "500",
                  fontFamily: "Futura",
                }}
              >
                Reset Password
              </h2>
              <div>
                <input
                  id="password"
                  type={enablePassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  name="newpassword"
                  placeholder="New password"
                  className="passwords input-md"
                  autoComplete="off"
                />
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEnablePassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{ marginLeft: "460px", marginTop: "-50px" }}
                  >
                    {enablePassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                {newPasswordError ? (
                  <p
                    style={{
                      
                      fontSize: "15px",
                      fontFamily: "Futura",
                      color: "#fff",
                    }}
                  >
                    *New password is Mandatory
                  </p>
                ) : null}
              </div>
              <div>
                <input
                  id="confirmpassword"
                  type={enablePassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  className="confirmPassword input-md"
                  autoComplete="off"
                />
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickEnablePassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{ marginLeft: "460px", marginTop: "-50px" }}
                  >
                    {enablePassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                {confirmPasswordError ? (
                  <p
                    style={{
                      
                      fontSize: "15px",
                      fontFamily: "Futura",
                      color: "#fff",
                    }}
                  >
                    *Confirm password is Mandatory
                  </p>
                ) : newPwdFlag ? (
                  <p
                    style={{
                      
                      fontSize: "15px",
                      fontFamily: "Futura",
                      color: "#fff",
                    }}
                  >
                    New password and Confirm Password should be same
                  </p>
                ) : null}
              </div>

              <Button
                style={{
                  display: "inline-block",
                  marginTop: "50px",
                  
                  backgroundColor: "#0c3470",
                  color: "#fff",
                }}
                onClick={handleResetPassword}
                className="btn btn-secondary hvr-ripple-out"
              >
                Reset Password
              </Button>
              <Button
                style={{
                  display: "inline-block",
                  marginTop: "50px",
                  marginLeft: "20px",
                  backgroundColor: "#0c3470",
                  color: "#fff",
                }}
                onClick={backTologin}
                className="btn btn-secondary hvr-ripple-out"
              >
                Back to login
              </Button>
            </div>
          ) : verficationFlag ? (
            <div>
              <div style={{ minHeight: 80, marginBottom: "200px" }}>
                <h2
                  style={{
                    marginTop: "120px",
                    
                    color: "#fff",
                    fontSize: "1.5625rem",
                    fontWeight: "500",
                    fontFamily: "Futura",
                  }}
                >
                  Verification
                </h2>
                <p
                  style={{
                    
                    color: "#fff",
                    fontSize: "16px",
                    fontFamily: "Futura",
                  }}
                >
                  To complete the password reset process,
                  <br />
                  please provide the OTP sent to your mobile no {mobileNumber}.
                </p>
                <input
                  id="text"
                  type="text"
                  value={otp}
                  onChange={(event) => setOtp(event.target.value)}
                  name="otp"
                  placeholder="Enter OTP"
                  className="referralId input-md"
                  autoComplete="off"
                />
                {verifyOtpError ? (
                  <p
                    style={{
                      
                      color: "#fff",
                      fontSize: "16px",
                      fontFamily: "Futura",
                    }}
                  >
                    *Invalid OTP
                  </p>
                ) : otpMandatory ? (
                  <p
                    style={{
                      
                      color: "#fff",
                      fontSize: "16px",
                      fontFamily: "Futura",
                    }}
                  >
                    *OTP is mandatory
                  </p>
                ) : (
                  ""
                )}
                <Button
                  style={{
                    marginTop: "50px",
                    display: "inline-block",
                    
                    backgroundColor: "#0c3470",
                    color: "#fff",
                  }}
                  onClick={handleVerifyOTP}
                  className="btn btn-secondary hvr-ripple-out"
                >
                  Verify OTP
                </Button>
                <Button
                  style={{
                    marginTop: "50px",
                    display: "inline-block",
                    marginLeft: "20px",
                    backgroundColor: "#0c3470",
                    color: "#fff",
                  }}
                  onClick={backTologin}
                  className="btn btn-secondary hvr-ripple-out"
                >
                  Back to login
                </Button>
              </div>
            </div>
          ) : enableForgotPassword ? (
            <div style={{ minHeight: 80, marginBottom: "200px" }}>
              <h2
                style={{
                  marginTop: "120px",
                  
                  color: "#fff",
                  fontSize: "1.5625rem",
                  fontWeight: "500",
                  fontFamily: "Futura",
                }}
              >
                Forgot Password
              </h2>
              <p
                style={{
                  
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: "Futura",
                }}
              >
                A password reset OTP will be sent to your mobile number
              </p>
              <form className="mt-4">
                <div style={{ width: "auto" }}>
                  <input
                    id="Email"
                    type="text"
                    value={referralId}
                    onChange={(event) => setReferralId(event.target.value)}
                    name="email"
                    placeholder="Referral ID"
                    className="referralId input-md"
                    autoComplete="off"
                  />
                  {isReferralBlank ? (
                    <p
                      style={{
                        
                        fontSize: "15px",
                        fontFamily: "Futura",
                        color: "#fff",
                      }}
                    >
                      Referral Id can't be blank
                    </p>
                  ) : isReferral ? (
                    <p
                      style={{
                        
                        color: "#fff",
                        fontSize: "15px",
                        fontFamily: "Futura",
                      }}
                    >
                      This functionality is not supported for {role} role
                    </p>
                  ) : userNotFound ? (
                    <p
                      style={{
                        
                        color: "#fff",
                        fontSize: "16px",
                        fontFamily: "Futura",
                      }}
                    >
                      User Id : {showUserId} entered is not Found
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <Button
                  style={{
                    display: "inline-block",
                    marginTop: "60px",
                    
                    backgroundColor: "#0c3470",
                    color: "#fff",
                  }}
                  onClick={handleSendOTP}
                  className="btn btn-secondary hvr-ripple-out"
                >
                  Send
                </Button>
                <Button
                  style={{
                    display: "inline-block",
                    marginTop: "60px",
                    marginLeft: "20px",
                    backgroundColor: "#0c3470",
                    color: "#fff",
                  }}
                  onClick={backTologin}
                  className="btn btn-secondary hvr-ripple-out"
                >
                  Back to login
                </Button>
              </form>
            </div>
          ) : (
            <div style={{ minHeight: 80, marginBottom: "200px" }}>
              <h1
                style={{
                  textAlign: "center",
                  marginTop: "120px",
                  color: "#fff",
                  fontSize: "1.5625rem",
                  fontWeight: "500",
                  fontFamily: "Futura",
                }}
              >
                Welcome to Star health
              </h1>
              <form className="mt-5" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    id="Email"
                    type="text"
                    value={values.email}
                    onChange={handleOnChange("email")}
                    name="email"
                    placeholder="User ID"
                    className="form-control input-md"
                    autoComplete="off"
                  />
                </div>
                <div
                  style={{
                    marginTop: "50px",
                    position: "relative",
                  }}
                >
                  <div className="form-group" style={{position :'relative'}}>
                    <input
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleOnChange("password")}
                      name="password"
                      placeholder="Enter Password"
                      className="form-control input-md"
                    />
                    <InputAdornment position="end" style={{position : 'absolute' , right : 0 , top : '1.5rem'}}>
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        style={{}}
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>

                <div></div>
                <div className="d-flex align-items-center">
                  <Button
                    style={{
                      display: "inline-block",
                      marginTop: "25px",
                      
                      backgroundColor: "#0c3470",
                      color: "#fff",
                    }}
                    type="submit"
                    className="btn btn-secondary hvr-ripple-out"
                  >
                    Login
                  </Button>
                  <Link
                    style={{ marginLeft: "230px", color: "#fff" }}
                    onClick={handleForgotPassword}
                  >
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </div>
          )}
        </Card>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Login;
