import moment from "moment";
import { createMuiTheme } from "@material-ui/core/styles";

export const checkValid = (date) => {
  return moment(date).isValid();
};

export const formateDateDDMMYYYY = (value) => {
  return checkValid(value) ? moment(value).format("DD/MM/YYYY") : value;
};

export const checkDateInterval = (fromDate, toDate) => {
  var start = moment(fromDate, "DD/MM/YYYY");
  var end = moment(toDate, "DD/MM/YYYY");
  const daysDiff = moment.duration(end.diff(start)).asDays();
  if (daysDiff <= 60) return true;
  return false;
};

export const isValidValue = (value) => {
  return value !== "" && value !== undefined && value !== null;
};

export const formatAndValidate = (from, to, days) => {
  if (isValidValue(from) && isValidValue(to)) {
    const start = moment(from).format("MM/DD/YYYY");
    const end = moment(to).format("MM/DD/YYYY");
    const daysDiff = moment(end).diff(start, "days");
    if (daysDiff <= days) return true;
    return false;
  }
  return true;
};

export const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableSelectCell: {
        root: {
          backgroundColor: "#ffff !important",
          width: "2000px",
        },
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: "#DCDCDC !important",
          width: "2000px",
          fontFamily: "FuturaLight",

          fontSize: "0.815rem",
          textTransform: "uppercase",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          fontFamily: "TableData",
          fontSize: "0.815rem",
        },
      },
      MuiTypography: {
        body1: {
          fontFamily: "TableData",
          fontSize: "0.815rem",
        },
      },
    },
  });

export const getDefaultFromDate = (toDate, days) => {
  return moment(toDate).subtract(days, "days").toDate();
};

export const subtractDate = (toDate, days) => {
  return isValidValue(toDate)
    ? moment(toDate).subtract(days, "days").toDate()
    : toDate;
};

export const addDate = (fromDate, days) => {
  return isValidValue(fromDate)
    ? moment(fromDate).add(days, "days").toDate()
    : fromDate;
};

export const filterDateValidation = (fromDate, toDate) => {
  const start = moment(fromDate).format("MM/DD/YYYY");
  const end = moment(toDate).format("MM/DD/YYYY");
  const validateField = checkValid(fromDate) && checkValid(toDate);
  const daysDiff = moment(end).diff(start, "days");
  const frmValidate =
    isValidValue(toDate) &&
    isValidValue(fromDate) &&
    moment(fromDate)?.isBefore(toDate) &&
    !moment(toDate).isSame(fromDate);
  const toValidate =
    isValidValue(toDate) && isValidValue(fromDate) && moment(toDate).isSameOrBefore(new Date());
  const futureValidate =
    (isValidValue(fromDate) && moment(fromDate).isBefore(new Date())) ||
    (isValidValue(toDate) && moment(toDate).isSameOrBefore(new Date()));
  if (
    validateField &&
    daysDiff <= 30 &&
    frmValidate &&
    toValidate &&
    futureValidate
  )
    return true;
  return false;
};

export const nameExportFilename = (fromDate, toDate) => {
  const fDate = moment(fromDate, "MM/DD/YYYY").format("DD-MM-YYYY");
  const tDate = moment(toDate, "MM/DD/YYYY").format("DD-MM-YYYY");
  const currentTime = moment().format("HH_mm_ss");
  const hourFormat = `${fDate}To${tDate}_${currentTime}`;
  return {
    local_delete_export: `DeletedInwardReports_${hourFormat}.csv`,
    local_inward_export: `InwardReports_${hourFormat}.csv`,
    network_inward_export: `InwardReports_${hourFormat}.xlsx`,
    network_delete_export: `DeletedInwardReports_${hourFormat}.xlsx`,
  };
};
