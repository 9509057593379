export const initialState = {
  agentCode: "",
  agentName: "",
  chequeNumber: "",
  convertPolicyNo: "",
  convertedDate: "",
  customerCode: "",
  customerMobileNo: "",
  customerName: "",
  entryDate: "",
  gst: "",
  instrumentDate: "",
  inwardDate: "",
  inwardNumber: "",
  inwardType: "",
  modeOfPay: "",
  officeCode: "",
  officeName: "",
  policyFromDate: "",
  policyNumber: "",
  policyToDate: "",
  policyType: "",
  premium: "",
  premiunReceiptDate: "",
  productName: "",
  proposalAcceptedDate: "",
  proposalNumber: "",
  queryDate: "",
  querySubmittedDate: "",
  referenceRemark: "",
  smCode: "",
  smName: "",
  status: "",
  toDate: "",
  totalPremium: "",
  deleteReason: ""
};

export const deleteDialogInitialState = {
  inwardNumber: "",
  deletedBy: "",
  deleteReason: "",
};
