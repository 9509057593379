import React, { useState,useEffect } from "react";
import axios from "axios";
import Box from "@material-ui/core/Box";
import MuiAlert from "@material-ui/lab/Alert";
import { POLICY_DOWNLOAD } from "../../config/config.js";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import {
  Button,
  makeStyles,
  Paper,
  Typography,
  Snackbar
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import RefreshIcon from "@material-ui/icons/Refresh";
import TextField from "@material-ui/core/TextField";
import { set } from "date-fns";

const useStyles = makeStyles(theme => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  textField: {
    marginTop: 10,
    marginBottom: 10,
    [`& fieldset`]: {
      borderRadius: 30,
      backgroundColor: "#e5e8f1"
    },
    [`& input`]: {
      zIndex: 3
    }
  },

  box: {
    marginLeft: "30px",
    marginTop: "80px"
  },

  createIssueFrom: {
    width: "18rem",
    height: "calc(100vh - 64px)",
    backgroundColor: "#e5e8f1"
  },
  createIssue: {
    marginLeft: "30"
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#007bff"
  }
}));
export default function PolicyDownload(props) {
  const [files, setFiles] = useState();
  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [disable, setDisable] = useState(false);
  const classes = useStyles();
  useEffect(function () {

    if (sessionStorage.getItem('token') === null) {
        props.history.push('/');
    }
  })
  const setProposal = e => {    setError(false);
    if (e.target.value.length > 0) {
      setEmpty(false);
    }
    setSearchQuery(e.target.value);
  };

  const onClickSubmit = () => {
    if (searchQuery.replace(/\s/g,'') === "") {
      setEmpty(true);
    } else {
      setDisable(true);
      var mySubString = searchQuery.substring(searchQuery.lastIndexOf("/") + 1);
      console.log(mySubString);
      searchQuery &&
        axios({
          method: "get",
          url: POLICY_DOWNLOAD + searchQuery,
          headers: { token: sessionStorage.getItem("token") },
          responseType: "blob"
        })
          .then(function(response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function() {
              var base64data = reader.result;
              const link = document.createElement("a");
              link.href = base64data;
              link.setAttribute("download", `Policy_${mySubString}.pdf`);
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              setDisable(false);
            };
          })
          .catch(error => {
            var b = new Blob([JSON.stringify({ test: "toast" })], {
                type: "application/json"
              }),
              fr = new FileReader();
            fr.onload = function() {
              var result = JSON.parse(this.result);
              if (result.code === 404 || result.code === 400) {
                setError(true);
                setErrorResponse(result.message);
                setDisable(false);
              } else if (result.code === 403) {
                if (result.message === "Invalid token / Token not active") {
                  alert("session expired");
                  props.history.push("/");
                } else {
                  setError(true);
                  setErrorResponse(result.message.substring(result.message.lastIndexOf(",") + 1));
                  setDisable(false);
                }
              } else if(result.code === 500 && result.message ==="Error while retrieving Policy document"){
                setSnackOpen(true);
                setMessage(result.message);
                setSnack(true);
                setColor(false);
                setDisable(false);
              }
              else{
                setSnackOpen(true);
                setMessage("Something Went Wrong");
                setSnack(true);
                setColor(false);
                setDisable(false);
              }
            };

            fr.readAsText(error.response.data);
            var reader = new FileReader();
            reader.onload = function() {};
            reader.readAsText(error.response.data);
          });
    }
  };

  const handleClose = (event, reason) => {
    setSnackOpen(false);
  };
 
  const reset = () => {
    setSearchQuery("");
  };
  return (
    <div>
      <div>
        <Box display="flex" flexDirection="row" p={2} m={1} bgcolor="#e5e8f1">
          <Typography
            style={{
              fontFamily: "Futura",
              fontWeight: "400px",
              fontSize: "1.15rem"
            }}
            variant={"h5"}
          >
            {" "}
            Policy Document Download
          </Typography>
        </Box>
      </div>
      {snack && (
        <Snackbar
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right"
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleClose}
        >
          <MuiAlert
            onClose={handleClose}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      <Grid>
        <FormControl className={classes.box} component="fieldset">
          <Grid container spacing={12} style={{ textAlign: "right" }}>
            <Grid item xs={12} sm={2}>
              <Typography
                style={{
                  fontFamily: "Futura",
                  fontWeight: "100px",
                  fontSize: "1.15rem",
                  textAlign: "left",
                  marginTop: "17px",
                  marginLeft: "20px"
                }}
                variant={"h5"}
              >
                Policy Number
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              {" "}
              <TextField
                style={{ width: "360px" }}
                variant="outlined"
                size="small"
                required
                placeholder="Policy Number"
                value={searchQuery}
                onChange={e => setProposal(e)}
                className={classes.textField}
                error={empty || error}
                helperText={
                  empty
                    ? "Proposal number mandatory"
                    : error
                    ? errorResponse
                    : ""
                }
              />
            </Grid>{" "}
            <Grid
              item
              xs={12}
              sm={3}
              style={{ width: "450px", marginLeft: "30px", marginTop: "5px" }}
            >
              <Button
                variant="contained"
                className={classes.button}
                disabled={disable}
                color="primary"
                onClick={onClickSubmit}
              >
                <CloudDownloadIcon
                  style={{ marginRight: "10px" }}
                ></CloudDownloadIcon>{" "}
                Download{" "}
              </Button>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
    </div>
  );
}
