import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { IconButton, Backdrop, CircularProgress } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { LOGOUT } from '../../config/config.js';
import axios from 'axios';
import './header.css';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadEscalate } from '../../api/downloadEscalate.js';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({

    root: {
        display: 'flex',
    },
    appBar: {
        backgroundColor: '#2252e5',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        boxShadow: 'none'
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    paper: {
        marginRight: theme.spacing(2)
    },
    listItemText: {
        fontSize: "0.9rem", //Insert your required size
        color: "rgb(0, 143, 225)"
    },
    menuStyles: {
        fontSize: "2rem !important",
        height: "30px",
        color: "rgb(0, 143, 225)"
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff"
    }
}));

export default function PersistentDrawerLeft(props) {
    const classes = useStyles();
    const [open] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElEscalate, setAnchorElEscalate] = React.useState(null);
    let UserName = sessionStorage.getItem('userId');
    const [openPogress, setOpenProgress] = React.useState(false);
    const logout = () => {
        setAnchorEl(null);
        axios({
            method: 'get',
            url: LOGOUT,
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            sessionStorage.clear();
            props.history.push('/');
        }).catch(exception => {
            sessionStorage.clear();
            props.history.push('/');
        })
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseEscalate = () => {

        setAnchorElEscalate(null);
    };

    const downloadEscalateDocument = () => {
        setOpenProgress(true);
        downloadEscalate("Digital & Underwriting - IT SPOC-V6 - Ext.pdf").then(
            function (response) {
                var reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                    var base64data = reader.result;
                    const link = document.createElement('a');
                    link.href = base64data;
                    link.setAttribute('download', 'Digital & Underwriting - IT SPOC-V6 - Ext.pdf');
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    setOpenProgress(false);
                }
            }

        ).catch(error => {
            setOpenProgress(false);
            console.log('error in downloading escalation matrix document')
        })
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <Backdrop className={classes.backdrop} open={openPogress}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <div style={{ width: '60%', textAlign: 'left', fontSize: '1rem', fontFamily: 'Date' }}>
                        Today, <span>{moment().format("MMM DD YYYY")}</span></div>

                    <div style={{ width: '35%', textAlign: 'right', fontSize: '1rem', position: 'relative' }}>
                        <div style={{ display: 'inline-block', position: 'absolute', right: 58, top: 20, fontFamily: 'Date' }}>{"Escalate"}</div><div style={{ display: 'inline-block' }}></div>
                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={downloadEscalateDocument}>
                            <GetAppIcon fontSize='large' />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorElEscalate}
                            keepMounted
                            open={Boolean(anchorElEscalate)}
                            onClose={handleCloseEscalate} >
                            <MenuItem >Escalate Matrix</MenuItem>
                        </Menu>
                    </div>
                    <div style={{ width: '20%', textAlign: 'right', fontSize: '1rem', position: 'relative' }}>
                        <div style={{ display: 'inline-block', position: 'absolute', right: 58, top: 20, fontFamily: 'Date' }}>{UserName}</div><div style={{ display: 'inline-block' }}></div>

                        <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                            <AccountCircleIcon fontSize='large' /> </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose} >
                            <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}