import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  button: {
    padding: theme.spacing(1, 8.5, 1, 8.5),
    fontSize: "16px",
    textTransform: "none",
    backgroundColor: "#007bff",
  },
  margin: {
    margin: theme.spacing(1),
    height: 30,
    textTransform: 'none'
  },
  margin1: {
    height: 30,
    textTransform: 'none'
  },
  titleText: {
    textAlign: "left",
    marginBottom: 18,
    fontFamily: "Futura",
    fontWeight: "400px",
    fontSize: "1.15rem",
  },
}));

export default useStyles;
