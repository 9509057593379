import React, { useEffect } from 'react';

const LoginRedirect = (props) =>{
    if(props.match.path === '/reportIssue/:userId/:token'){
        sessionStorage.setItem('userId', props.match.params.userId)
        sessionStorage.setItem('token', props.match.params.token.replace('Bearer ', ''))
        sessionStorage.setItem('role', 'Agent')  
        props.history.push('/createIssue');
      }
    useEffect(() => {

    }, [])
    return(
        <></>
    )
}

export default LoginRedirect;