import axios from "axios";
import { DOWNLOAD_MEDIA } from "../config/config";
export const downloadEscalate = async (filename) => {

    let responseObj = axios({
        method: 'get',
        url: DOWNLOAD_MEDIA,
        params: {
            fileName: filename,
        },
        headers: { token: sessionStorage.getItem('token') },
        responseType: 'blob'
    });

    return responseObj;

}