import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Button from "@material-ui/core/Button";
import {
  Typography,
  TextField,
  makeStyles,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  DialogContent,
  Link,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ISSUE_DASHBOARD, DOWNLOAD_MEDIA, UPLOAD_MEDIA } from "../../config/config.js";
import { UPDATE_ISSUE, GET_ISSUE, UPDATE_REMARKS } from "../../config/config.js";
import {
  MuiThemeProvider,
  createMuiTheme,
  useTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MuiAlert from "@material-ui/lab/Alert";
import "./dashboard.css";
import { getFileExtension3 } from "../../common/getExtensionFile.js";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let selectedRows = [];
let rowIndexDup = null;
let colIndexDup = null;
let dataIndexDup = null;
let inputValue = null;
let currentPage = 0;
let numberOfRows = 10;
toast.configure();
const DashBoard = (props) => {
  const [isL1User, setIsL1User] = useState(false);
  const columns = [
    {
      name: "issueId",
      label: "Issue\u00a0Id",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title="Click To View" interactive>
              <Link style={{ cursor: "pointer" }}>{value}</Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "proposalNo",
      label: "Proposal\u00a0No",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "transactionId",
      label: "Transaction\u00a0Id",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={false}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "transactionDate",
      label: "Transaction\u00a0Date",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "customerName",
      label: "Customer\u00a0Name",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "issueType",
      label: "Issue\u00a0Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "issueStatus",
      label: "Issue\u00a0Status",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "issueId",
      label: "Update\u00a0Support\u00a0Remarks",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title="Click To Update" interactive>
              {isL1User ? (
                <Link style={{ cursor: "pointer" }} value={tableMeta}>Click Here </Link>
              ) : (
                  <Link
                    style={{ color: "#868282", pointerEvents: "none" }}
                    value={tableMeta}
                  >
                    Click Here
                  </Link>
                )}
            </Tooltip>
          );
        },
      },
    },
    {
      name: "supportUserRemarks",
      label: "Support\u00a0Remarks",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Tooltip title={value}>
            <Typography style={{cursor: "pointer"}} component={"span"} noWrap={true}>
              {(value !== null && value !== "" && value.length > 50) ? value.substring(0, 40) + "..." : value}
            </Typography>
          </Tooltip>
        ),
      },
    },

    {
      name: "createdBy",
      label: "Agent\u00a0Id",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "agentPhoneNumber",
      label: "Agent\u00a0PhoneNumber",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "agentEmailId",
      label: "Agent\u00a0Email",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "updatedBy",
      label: "Picked\u00a0By",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "productName",
      label: "Product\u00a0Name",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },

    {
      name: "purchaseType",
      label: "Purchase\u00a0Type",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "application",
      label: "Application",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "agentRemarks",
      label: "Agent\u00a0Remarks",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title={value}>
              <Typography style={{cursor: "pointer"}} component={"span"} noWrap={true}>
                {(value !== null && value !== "" && value.length > 50) ? value.substring(0, 40) + "..." : value}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "createdDate",
      label:
        "\u00a0\u00a0\u00a0\u00a0Created\u00a0Date\n(DD/MM/YY\u00a0HH:MM:SS)",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "closedDate",
      label:
        "\u00a0\u00a0\u00a0\u00a0Closed\u00a0Date\n(DD/MM/YY\u00a0HH:MM:SS)",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "ageing",
      label: "Ageing",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Typography component={"span"} noWrap={true}>
              {value}
            </Typography>
          );
        },
      },
    },
  ];
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);


  const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          root: {
            backgroundColor: "#ffff !important",
            width: "2000px",
          },
        },
        MuiTableCell: {
          root: {
            padding: ` 1px 10px !important`,
            borderBottom: 'none',
          },
          head: {
            height: '10px !important',
          },
          body: {
            height: '10px !important',
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: "#DCDCDC !important",
            width: "2000px",
            fontFamily: "FuturaLight",

            fontSize: "0.815rem",
            textTransform: "uppercase",
          },
        },
        MUIDataTableBodyCell: {
          root: {
            fontFamily: "TableData",
            fontSize: "0.815rem",
          },
        },
        MuiTypography: {
          body1: {
            fontFamily: "TableData",
            fontSize: "0.815rem",
          },
        },
      },
    });

  const [snackOpen, setSnackOpen] = useState(false);
  const [snack, setSnack] = useState(false);
  const [message, setMessage] = useState("");
  const [color, setColor] = useState(true);
  const [data, setData] = useState();

  const handleSnackClose = (event, reason) => {
    setSnackOpen(false);
  };

  let userId = sessionStorage.getItem("userId");
  let role = sessionStorage.getItem("role");
  useEffect(function () {
    selectedRows = [];
    if (role === "L1User" || role === "L2User") {
      setIsL1User(true);
    }
    setSnackOpen(false);
    setOpen(true);
    if (sessionStorage.getItem("token") === null) {
      props.history.push("/");
    }
    axios({
      method: "get",
      url: ISSUE_DASHBOARD + userId + "/" + role,
      headers: { token: sessionStorage.getItem("token") },
    })
      .then((response) => {
        setData(response.data.data);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        if (error.response !== undefined) {
          if (error.response.status === 403 || error.response.status === 400) {
            alert("session expired");
            sessionStorage.clear();
            props.history.push("/");
          } else {
            setSnackOpen(true);
            setMessage(error.response.data.error);
            setSnack(true);
            setColor(false);
          }
        } else {
          setSnackOpen(true);
          setMessage("Something Went Wrong");
          setSnack(true);
          setColor(false);
        }
      });
  }, []);

  const onRowsSelect = (currentRowsSelected, allRowsSelected) => {
    selectedRows = [...allRowsSelected];
  };

  const onRowsApprove = (statusFlag) => {
    setTimeout(() => {
      let updatingData = [];
      let dataNew = data;
      let count = 0;
      let updateFlag = true;

      selectedRows.map((id) => {
        let obj = {};
        if (
          dataNew[id.dataIndex].updatedBy !== userId &&
          (dataNew[id.dataIndex].issueStatus === "L1-In Progress" || dataNew[id.dataIndex].issueStatus === "L2-In Progress")
        ) {
          ++count;
          updateFlag = false;
        } else {
          obj.issueId = parseInt(dataNew[id.dataIndex].issueId);
          obj.updateRemarks = dataNew[id.dataIndex].supportUserRemarks;
          updatingData.push(obj);
        }
      });
      if (updateFlag) {
        setOpen(true);
        axios({
          method: "put",
          url: UPDATE_ISSUE,
          data: {
            updateCreateIssueRequest: updatingData,
            userRoles: sessionStorage.getItem("role"),
            issueStatus: statusFlag,
            updatedBy: sessionStorage.getItem("userId"),
          },
          headers: { token: sessionStorage.getItem("token") },
        })
          .then((response) => {
            if (response.data.message === "Issue Updated Successfully") {
              setSnackOpen(true);
              setMessage(response.data.message);
              setSnack(true);
              setColor(true);
              axios({
                url: ISSUE_DASHBOARD + userId + "/" + role,
                headers: { token: sessionStorage.getItem("token") },
              }).then((response) => {
                dataNew = response.data.data;
                setData([...dataNew]);
                setOpen(false);
              }).catch(error =>{
                setOpen(false);
                if (error.response !== undefined) {
                  if (error.response.status === 403 || error.response.status === 400) {
                    alert("session expired");
                    sessionStorage.clear();
                    props.history.push("/");
                  } else {
                    setSnackOpen(true);
                    setMessage(error.response.data.error);
                    setSnack(true);
                    setColor(false);
                  }
                } else {
                  setSnackOpen(true);
                  setMessage("Something Went Wrong");
                  setSnack(true);
                  setColor(false);
                }
              })
            }
          })
          .catch((error) => {
            setOpen(false);
            if (error.response !== undefined) {
              if (
                error.response.status === 403 ||
                error.response.status === 400
              ) {
                alert("session expired");
                sessionStorage.clear();
                props.history.push("/");
              } else {
                setSnackOpen(true);
                setMessage(error.response.data.error);
                setSnack(true);
                setColor(false);
              }
            } else {
              setSnackOpen(true);
              setMessage("Something Went Wrong");
              setSnack(true);
              setColor(false);
            }
          });
        selectedRows = [];
      } else {
        toast.info(
          count +
          " issues from the selected list are being worked by another user"
        );
      }
      count = 0;
      updateFlag = false;
    });
  };
  const downloadFile = (fileName) => {
    axios({
      method: "get",
      url: DOWNLOAD_MEDIA,
      params: {
        fileName,
      },
      responseType: "blob",
    }).then(function (response) {
      var reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function () {
        var base64data = reader.result;
        const link = document.createElement("a");
        link.href = base64data;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      };
    });
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClose = () => {
    setOpenDialog(false);
    setViewDataObj(initialState);
  };
  const initialState = {
    issueId: "",
    transactionId: "",
    proposalNo: "",
    agentEmailId: "",
    customerName: "",
    productName: "",
    issueType: "",
    agentRemarks: "",
    supportUserRemarks: "",
    purchaseType: "",
    application: "",
    issueStatus: "",
    createdBy: "",
    updatedBy: "",
    updatedByAgent: "",
    createdDate: '',
    closedDate: '',
    ageing: "",
    files: [
      {
        fileName: "",
      },
    ],
  };
  let fileInput = {};
  fileInput.value = '';
  const [viewDataObj, setViewDataObj] = useState(initialState);
  const [fileNames, setFileNames] = useState([]);
  const [noteStatus, setNoteStatus] = useState("");
  const [filesList, setFilesList] = useState([])
  const [fileNamesNew, setFileNamesNew] = useState([])
  const [maxFileSize, setFileSize] = useState([])
  const viewDetails = (colData, cellMeta) => {
    axios({
      method: "get",
      url: GET_ISSUE + colData.props.children.props.children,
      headers: { token: sessionStorage.getItem("token") },
    })
      .then((response) => {
        setViewDataObj(response.data.data);
        setNoteStatus(response.data.data.issueStatus);
        setFileNames(response.data.data.files);
        setOpenDialog(true);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            alert("Session Expired");
            props.history.push("/");
          }
        } else {
          setSnackOpen(true);
          setMessage("Something Went Wrong");
          setSnack(true);
          setColor(false);
        }
      });
  };
  const [updateAgentDialog, setUpdateAgentDialog] = useState(false);
  const [supportUserDialog, setSupportUserDialog] = useState(false);
  const [updatingId, setUpdatingId] = useState("");
  const [latestSupportRemarks, setLatestSupportRemarks] = useState("");
  const [latestAgentRemarks, setLatestAgentRemarks] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [issueId, setIssueId] = useState(0)
  const [activeError, setActiveError] = useState(false)

  const updateSupportRemarks = (colData, cellMeta) => {
    setUpdatingId(colData.props.children.props.value.rowData[0]);
    setLatestSupportRemarks(colData.props.children.props.value.rowData[8]);
    setCurrentStatus(colData.props.children.props.value.rowData[6]);
    setSupportUserDialog(true);

  };

  const handleUpdateAgentRemarks = () => {
    setUpdateAgentDialog(true);
    setFilesList([])
    setFileNames([])
  };

  const updateAgentRemarks = (latestRemarks) => {
    let dataNew = data;
    let agentRemarksId = 0;
    let currentStatus = "";
    selectedRows.map((id) => {
        agentRemarksId = dataNew[id.dataIndex].issueId;
        currentStatus = dataNew[id.dataIndex].issueStatus;
    });
    var formData = new FormData();
    setActiveError(true)
    formData.append('issueId', agentRemarksId)
    filesList.map(item => formData.append('mediaName', item))
    if (filesList.length < 1 && (latestRemarks !== "" && latestRemarks !== null)) {
      setOpen(true);
      axios({
        method: "put",
        url: UPDATE_REMARKS,
        data: {
          updateCreateIssueRequest: [
            {
              issueId: agentRemarksId,
              updateRemarks: latestRemarks,
            },
          ],
          userRoles: sessionStorage.getItem("role"),
          issueStatus: currentStatus,
          updatedBy: sessionStorage.getItem("userId"),
        },
        headers: { token: sessionStorage.getItem("token") },
      })
        .then((response) => {
          if (response.data.message === "Remarks Updated Successfully") {
            setSnackOpen(true);
            setMessage(response.data.message);
            setSnack(true);
            setColor(true);
            axios({
              url: ISSUE_DASHBOARD + userId + "/" + role,
              headers: { token: sessionStorage.getItem("token") },
            }).then((response) => {
              dataNew = response.data.data;
              setData([...dataNew]);
              setOpen(false);
            });
          }


        })
        .catch((error) => {
          setOpen(false);
          if (error.response !== undefined) {
            if (error.response.status === 403 || error.response.status === 400) {
              alert("session expired");
              sessionStorage.clear();
              props.history.push("/");
            } else {
              setSnackOpen(true);
              setMessage(error.response.data.error);
              setSnack(true);
              setColor(false);
            }
          } else {
            setSnackOpen(true);
            setMessage("Something Went Wrong");
            setSnack(true);
            setColor(false);
          }
        });
      setActiveError(false)
      setUpdateAgentDialog(!updateAgentDialog);
      selectedRows = [];
    }
    else if (!(filesList.length > 5) && getFileMethod(filesList) && latestRemarks !== "" && latestRemarks !== "") {
      setOpen(true);
      axios({
        method: 'post',
        url: UPLOAD_MEDIA,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(response => {
        if (response && response.data.message === 'media uploaded successfully') {

          axios({
            method: "put",
            url: UPDATE_REMARKS,
            data: {
              updateCreateIssueRequest: [
                {
                  issueId: agentRemarksId,
                  updateRemarks: latestRemarks,
                },
              ],
              userRoles: sessionStorage.getItem("role"),
              issueStatus: currentStatus,
              updatedBy: sessionStorage.getItem("userId"),
            },
            headers: { token: sessionStorage.getItem("token") },
          })
            .then((response) => {
              if (response.data.message === "Remarks Updated Successfully") {
                setSnackOpen(true);
                setMessage(response.data.message);
                setSnack(true);
                setColor(true);
                setFilesList([])
                setFileNames([])
                axios({
                  url: ISSUE_DASHBOARD + userId + "/" + role,
                  headers: { token: sessionStorage.getItem("token") },
                }).then((response) => {
                  dataNew = response.data.data;
                  setData([...dataNew]);
                  setOpen(false);
                });
              }

            })
            .catch((error) => {
              setOpen(false);
              if (error.response !== undefined) {
                if (error.response.status === 403 || error.response.status === 400) {
                  alert("session expired");
                  sessionStorage.clear();
                  props.history.push("/");
                } else {
                  setSnackOpen(true);
                  setMessage(error.response.data.error);
                  setSnack(true);
                  setColor(false);
                }
              } else {
                setSnackOpen(true);
                setMessage("Something Went Wrong");
                setSnack(true);
                setColor(false);
              }
            });
        }
      }).catch(error => {
        setOpen(false)
        setSnackOpen(true)
        setMessage("Could not upload the files")
        setSnack(true)
        setColor(false)
      })
      setActiveError(false)
      setUpdateAgentDialog(!updateAgentDialog);
      selectedRows = [];
    }


  };
  const handleDialogClose = () => {
    setSupportUserDialog(false);
    setUpdateAgentDialog(false);
    setActiveError(false)
    selectedRows = [];
  };
  const handleCloseRemarksDialog = () => {
    let dataNew = data;
    setOpen(true);
    axios({
      method: "put",
      url: UPDATE_REMARKS,
      data: {
        updateCreateIssueRequest: [
          {
            issueId: updatingId,
            updateRemarks: latestSupportRemarks,
          },
        ],
        userRoles: sessionStorage.getItem("role"),
        issueStatus: currentStatus,
        updatedBy: sessionStorage.getItem("userId"),
      },
      headers: { token: sessionStorage.getItem("token") },
    })
      .then((response) => {
        axios({
          url: ISSUE_DASHBOARD + userId + "/" + role,
          headers: { token: sessionStorage.getItem("token") },
        }).then((response) => {
          dataNew = response.data.data;
          setData([...dataNew]);
          setOpen(false);
        });
      })
      .catch((error) => {
        setOpen(false);
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            alert("session expired");
            sessionStorage.clear();
            props.history.push("/");
          } else {
            setSnackOpen(true);
            setMessage(error.response.data.error);
            setSnack(true);
            setColor(false);
          }
        }
      });
    setSupportUserDialog(false);
    setLatestSupportRemarks("");
  };

  const getFileMethod = (files) => {
    let flag = 0;
    files.map(item => {
      if (item.size / 1000 > 5000) {
        flag = 1;
      } else {
        flag = 0;
      }
    })
    if (flag === 0) {
      return true;
    } else {
      return false;
    }
  }

  const removeFiles = (file) => {
    var newFiles = filesList;
    var index = newFiles.indexOf(file)
    if (index !== -1) {
      newFiles.splice(index, 1)
      setFilesList([...newFiles])
    }

  }

  const handlefile = (e) => {
    let listOfNames = [];
    let listOfFiles = [];
    let fileSizes = [];
    for (var i = 0; i < e.target.files.length; ++i) {
      listOfNames.push(e.target.files[i].name)
      listOfFiles.push(e.target.files[i]);
      fileSizes.push(e.target.files[i].size / 1000);
      updateStatesMethod(listOfFiles, listOfNames, fileSizes)
    }
  }
  async function updateStatesMethod(listOfFiles, listOfNames, fileSizes) {
    await setFilesList([...filesList, ...listOfFiles])
    await setFileNamesNew([...fileNamesNew, ...listOfNames]);
    await setFileSize([...maxFileSize, ...fileSizes])
  }


  const options = {
    filterType: "multiselect",
    rowsPerPage: 5,
    onRowsSelect: onRowsSelect,
    rowsPerPageOptions: [5, 10, 15],
    sort: false,
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
      },
    },
    selectableRows: "single",
    viewColumns: false,
    rowsSelected: selectedRows.map((item) => item.dataIndex),
    customToolbarSelect: () => {
      if (sessionStorage.getItem("role") === "L1User" || sessionStorage.getItem("role") === "L2User") {
        return (
          <div style={{ textAlign: "right", paddingTop: 10, paddingRight: 18 }}>
            <Button
              style={{ marginRight: 10, backgroundColor: "#007bff" }}
              variant="contained"
              color="primary"
              onClick={() => onRowsApprove(sessionStorage.getItem("role") === "L1User" ? "L1-In Progress" : "L2-In Progress")}
            >
              In Progress
            </Button>
            <Button
              style={{ marginRight: 10, backgroundColor: "#007bff" }}
              variant="contained"
              color="primary"
              onClick={() => onRowsApprove(sessionStorage.getItem("role") === "L1User" ? "L2" : "L1")}
            >
              {sessionStorage.getItem("role") === "L1User" ? "Assign To L2" : "Assign To L1"}
            </Button>
            <Button
              style={{ marginRight: 10, backgroundColor: "#007bff" }}
              variant="contained"
              color="primary"
              onClick={() => onRowsApprove("Irrelevant")}
            >
              Irrelevant
            </Button>
            <Button
              style={{ backgroundColor: "#007bff" }}
              variant="contained"
              color="primary"
              onClick={() => onRowsApprove("Closed")}
            >
              Closed
            </Button>
          </div>
        );
      } else if (sessionStorage.getItem("role") === "Agent") {
        return (
          <div>
            <Button
              style={{ marginRight: 15, backgroundColor: "#007bff" }}
              variant="contained"
              color="primary"
              onClick={handleUpdateAgentRemarks}
            >
              Add Remarks
            </Button>
          </div>
        );
      }
    },
    onCellClick: (colData, cellMeta) => {
      if (cellMeta.colIndex === 0) {
        if (colData.props.children.props) {
          viewDetails(colData, cellMeta);
        }
      }
      if (
        cellMeta.colIndex === 7 &&
        (sessionStorage.getItem("role") === "L1User" || sessionStorage.getItem("role") === "L2User")
      ) {
        updateSupportRemarks(colData, cellMeta);
      }
    },
    onChangePage: (currentPageNo) => {
      currentPage = currentPageNo;
    },
    onChangeRowsPerPage: (numberOfRows) => {
      numberOfRows = numberOfRows;
    },
  };
  return (
    <div id={"dashboard-mui-table"} style={{ margin: 30 }}>
      {snack && (
        <Snackbar
          anchorOrigin={{
            vertical: color ? "bottom" : "top",
            horizontal: "right",
          }}
          open={snackOpen}
          autoHideDuration={2000}
          onClose={handleSnackClose}
        >
          <MuiAlert
            onClose={handleSnackClose}
            elevation={6}
            variant="filled"
            severity={color ? "success" : "error"}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      )}
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        style={{ overflowX: "hidden" }}
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            <Grid container spacing={4}>
              <Grid item spacing={4} xs={12} sm={6}>
                <strong>IssueDetails - </strong>
                {viewDataObj.issueId}{" "}
              </Grid>
              {noteStatus === "Irrelevant" ? (
                <Grid
                  item
                  spacing={4}
                  xs={12}
                  sm={6}
                  style={{ color: "red", fontSize: "15px" }}
                >
                  Note: This Ticket is Irrelevant, Please Conatct Agency
                </Grid>
              ) : null}
            </Grid>
          </DialogTitle>
          <Grid container spacing={4} style={{ padding: "20px" }}>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Transaction Id
              </Grid>
              <Grid>{viewDataObj.transactionId}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Transaction Date
              </Grid>
              <Grid>{viewDataObj.transactionDate}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Proposal No</Grid>
              <Grid>{viewDataObj.proposalNo}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Agent Email</Grid>
              <Grid>{viewDataObj.agentEmailId}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Customer Name
              </Grid>
              <Grid>{viewDataObj.customerName}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Product Name</Grid>
              <Grid>{viewDataObj.productName}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Issue Type</Grid>
              <Grid>{viewDataObj.issueType}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Agent Remarks
              </Grid>
              {viewDataObj.agentRemarks.split('-').map(i => {
                return (
                  <Grid>{i.slice(0, 40) + "\n" + i.slice(40, 80) + "\n" + i.slice(80, 120) + "\n" + i.slice(120, 160) + "\n" + i.slice(160, 200) + "\n" + i.slice(200, 240) + "\n" + i.slice(240, i.length)}</Grid>)
              })}
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Support Remarks
              </Grid>
              {viewDataObj.supportUserRemarks.split('-').map(i => {
                return (
                  <Grid>{i.slice(0, 40) + "\n" + i.slice(40, 80) + "\n" + i.slice(80, 120) + "\n" + i.slice(120, 160) + "\n" + i.slice(160, 200) + "\n" + i.slice(200, 240) + "\n" + i.slice(240, i.length)}</Grid>)
              })}
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Purchase Type
              </Grid>
              <Grid>{viewDataObj.purchaseType}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Application</Grid>
              <Grid>{viewDataObj.application}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Issue Status</Grid>
              <Grid>{viewDataObj.issueStatus}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Created By</Grid>
              <Grid>{viewDataObj.createdBy}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Updated By L1</Grid>
              <Grid>{viewDataObj.updatedBy}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Updated By Agent</Grid>
              <Grid>{viewDataObj.updatedByAgent}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Ageing</Grid>
              <Grid>{viewDataObj.ageing}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Created Date</Grid>
              <Grid>{viewDataObj.createdDate}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Closed Date</Grid>
              <Grid>{viewDataObj.closedDate}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={12}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Attachments</Grid>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {viewDataObj.files.map((item) => {
                  return (
                    <Grid>
                      <div
                        style={{
                          display: "flex",
                          paddingTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        {getFileExtension3(item.fileName)}
                        <Link
                          style={{ cursor: "pointer" }}
                          onClick={() => downloadFile(item.fileName)}
                        >
                          {item.fileName}
                        </Link>
                      </div>
                    </Grid>
                  );
                })}
              </div>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {supportUserDialog ? (
        <Dialog
          open={supportUserDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Update Support Remarks -{updatingId}
            <Button
              simple
              key="close"
              aria-label="Close"
              onClick={() => handleDialogClose()}
              style={{
                color: `#000`,
                float: `right`,
                marginLeft: "20px",
              }}
            >
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Add Remarks"
              type="text"
              onBlur={(e) => setLatestSupportRemarks(e.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseRemarksDialog}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {updateAgentDialog ? (
        <Dialog
          open={updateAgentDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Update Agent Remarks
            <Button
              simple
              key="close"
              aria-label="Close"
              onClick={() => handleDialogClose()}
              style={{
                color: `#000`,
                float: `right`,
                marginLeft: "20px",
              }}
            >
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Add Remarks"
              type="text"
              onBlur={(e) => setLatestAgentRemarks(e.target.value)}
              fullWidth={true}
              maxWidth={'md'}
              error={(activeError & latestAgentRemarks === "" || latestAgentRemarks === null)} helperText={activeError & (latestAgentRemarks === "" || latestAgentRemarks === null) ? "Please Enter Remarks" : ""}
            />
            {filesList.length > 5 ? <p style={{ color: "red", marginTop: 50 }}>Number Of Attachments should not exceed 5</p> : null}
            {filesList.map(item => item.size / 1000 > 5000 ? <p style={{ color: "red" }}>File Size should not exceed 5MB</p> : null)}
            <input
              type="file"
              style={{ display: "none" }}
              ref={ref => (fileInput = ref)}
              onChange={handlefile}
              multiple={true}
              accept=".png, .jfif, .pjpeg, .jpeg, .pjp, .jpg, .xls, .xlsx, .doc, .docx, .pdf, .odt"
            />
            <Button title={'click to add attachment'} variant="contained" className={classes.button} style={{ marginLeft: 0, marginTop: 25 }} color="primary" onClick={() => fileInput.click()}>Add Attachments</Button>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {filesList ? filesList.map(item => <div style={{ display: "flex", paddingTop: "20px", textAlign: "center" }}>{getFileExtension3(item.name)}<span>{item.name}</span><CloseIcon style={{ cursor: 'pointer' }} onClick={() => removeFiles(item)} /> </div>) : null}

            </div>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ marginTop: 50 }}
              onClick={() => updateAgentRemarks(latestAgentRemarks)}
              color="primary"
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      <Typography
        style={{
          textAlign: "left",
          marginBottom: 18,
          fontFamily: "Futura",
          fontWeight: "400px",
          fontSize: "1.15rem",
        }}
        variant={"h5"}
      >
        {" "}
        Issue Dashboard{" "}
      </Typography>
      <div>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable data={data} columns={columns} options={options} />
        </MuiThemeProvider>
      </div>
    </div>
  );
};

export default DashBoard;
