import React from "react";
import {
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  DialogContent,
  Snackbar,
  DialogContentText,
  TextField,
  Typography,
  FormControlLabel, Checkbox, Button, MuiThemeProvider
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {
  formatAndValidate,
  isValidValue,
  getMuiTheme,
  subtractDate,
  addDate,
  checkValid,
} from "./helper";
import useStyles from "./style";

export const Loader = React.memo(({ open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});

export const CustomSnackBar = React.memo(
  ({ color, snackOpen, setSnackOpen, message }) => {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: color ? "bottom" : "top",
          horizontal: "right",
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={() => setSnackOpen(false)}
      >
        <MuiAlert
          onClose={() => setSnackOpen(false)}
          elevation={6}
          variant="filled"
          severity={color ? "success" : "error"}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    );
  }
);

export const ReasonDeleteDialog = React.memo(
  ({
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteError,
    reasonToDelete,
    setDeleteReason,
    deleteRecord,
    inwardNoToDelete,
    setDeleteError,
  }) => {
    return (
      <Dialog
        open={openDeleteDialog}
        fullWidth={true}
        maxWidth={"sm"}
        disableEnforceFocus
      >
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Delete Reason - {inwardNoToDelete || ""}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="reasonDelete"
            type="text"
            fullWidth
            multiline
            rows={5}
            required
            name="reasonDelete"
            // value={reasonToDelete}
            onBlur={(e) => setDeleteReason(e.target.value)}
            label="Reason to Delete"
            variant="outlined"
            error={deleteError && reasonToDelete === ""}
            helperText={
              deleteError && reasonToDelete === ""
                ? "Reason for delete is Mandatory"
                : ""
            }
            inputProps={{ maxLength: 100 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setOpenDeleteDialog(false);
              setDeleteError(false);
              setDeleteReason("");
            }}
            color="primary"
          >
            no
          </Button>
          <Button autoFocus onClick={deleteRecord} color="primary">
            yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const ViewDialog = React.memo(
  ({ fullScreen, openDialog, handleClose, viewDataObj, showDeletedRecords }) => {
    return (
      <Dialog
        fullScreen={fullScreen}
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        style={{ overflowX: "hidden" }}
      >
        <DialogContent>
          <DialogTitle id="alert-dialog-title">
            <Grid container spacing={4}>
              <Grid item spacing={4} xs={12} sm={6}>
                <strong>Inward Details</strong>
                <strong> - {viewDataObj?.inwardNumber || " "}</strong>
              </Grid>
            </Grid>
          </DialogTitle>
          <Grid container spacing={4} style={{ padding: "20px" }}>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Inward Number
              </Grid>
              <Grid>{viewDataObj?.inwardNumber || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Entry Date</Grid>
              <Grid>{viewDataObj?.entryDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Office Code</Grid>
              <Grid>{viewDataObj?.officeCode || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Office Name</Grid>
              <Grid>{viewDataObj?.officeName || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Inward Type (premium/proposal/documents)
              </Grid>
              <Grid>{viewDataObj?.inwardType || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Inward Date (premium/proposal/documents received date)
              </Grid>
              <Grid>{viewDataObj?.inwardDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Intermediary Received Date
              </Grid>
              <Grid>{viewDataObj?.intermediaryReceivedDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Premium Receipt Date
              </Grid>
              <Grid>{viewDataObj?.premiunReceiptDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Customer/Proposer Name
              </Grid>
              <Grid>{viewDataObj?.customerName || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Customer Mobile Number
              </Grid>
              <Grid>{viewDataObj?.customerMobileNo || "-"}</Grid>
            </Grid>

            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Customer/Proposer Code
              </Grid>
              <Grid>{viewDataObj?.customerCode || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Policy Type</Grid>
              <Grid>{viewDataObj?.policyType || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Policy Number
              </Grid>
              <Grid>{viewDataObj?.policyNumber || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Proposal Number
              </Grid>
              <Grid>{viewDataObj?.proposalNumber || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Product Name</Grid>
              <Grid>{viewDataObj?.productName || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>SM Name</Grid>
              <Grid>{viewDataObj?.smName || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>SM Code</Grid>
              <Grid>{viewDataObj?.smCode || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Agent Name</Grid>
              <Grid>{viewDataObj?.agentName || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Agent Code</Grid>
              <Grid>{viewDataObj?.agentCode || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Reference Remark
              </Grid>
              <Grid>{viewDataObj?.referenceRemark || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Premium</Grid>
              <Grid>{viewDataObj?.premium || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>GST</Grid>
              <Grid>{viewDataObj?.gst || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Total Premium
              </Grid>
              <Grid>{viewDataObj?.totalPremium || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Amount Collected
              </Grid>
              <Grid>{viewDataObj?.amountCollected || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Mode of Pay</Grid>
              <Grid>{viewDataObj?.modeOfPay || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Cheque/DD No</Grid>
              <Grid>{viewDataObj?.chequeNumber || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Instrument Date
              </Grid>
              <Grid>{viewDataObj?.instrumentDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Query Date, wherever applicable
              </Grid>
              <Grid>{viewDataObj?.queryDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Query submitted date
              </Grid>
              <Grid>{viewDataObj?.querySubmittedDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Proposal accepted date (CMU referred case)
              </Grid>
              <Grid>{viewDataObj?.proposalAcceptedDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Converted date
              </Grid>
              <Grid>{viewDataObj?.convertedDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Converted Policy / ENDT Number
              </Grid>
              <Grid>{viewDataObj?.convertPolicyNo || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Policy From Date
              </Grid>
              <Grid>{viewDataObj?.policyFromDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                Policy To Date
              </Grid>
              <Grid>{viewDataObj?.policyToDate || "-"}</Grid>
            </Grid>
            <Grid item spacing={4} xs={12} sm={4}>
              <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Status</Grid>
              <Grid>{viewDataObj?.status || "-"}</Grid>
            </Grid>
            {showDeletedRecords &&
              <Grid item spacing={4} xs={12} sm={4}>
                <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Deleted By</Grid>
                <Grid>{viewDataObj?.updatedBy || "-"}</Grid>
              </Grid>
            }
            {showDeletedRecords &&
              <Grid item spacing={4} xs={12} sm={4}>
                <Grid style={{ color: "rgba(0, 0, 0, 0.54)" }}>Deleted Reason</Grid>
                <Grid>{viewDataObj?.deleteReason || "-"}
                </Grid>
              </Grid>
            }
          </Grid>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  }
);

export const ExportFilter = React.memo(
  ({
    fromDate,
    setFromDate,
    listError,
    toDate,
    setToDate,
    exportReport,
    getDashboardData,
    deleteChecked,
    toogleDeleteChecbox, isCorporateUser
  }) => {
    const classes = useStyles();
    return (
      <>
        {checkValid(toDate) &&
          checkValid(fromDate) &&
          moment(toDate).isSame(fromDate) &&
          moment(fromDate).isBefore(new Date()) && (
            <Typography variant={"p"} style={{ color: "red" }}>
              From & To Date cannot be same
            </Typography>
          )}
        {(checkValid(fromDate) && fromDate >= new Date()) ||
          (checkValid(toDate) && toDate > new Date() && (
            <Typography variant={"p"} style={{ color: "red" }}>
              From and To Date cannot be future date.
            </Typography>
          ))}
        {checkValid(fromDate) &&
          checkValid(toDate) &&
          moment(fromDate)?.isAfter(toDate) && (
            <Typography variant={"p"} style={{ color: "red" }}>
              To date should be greater than From date
            </Typography>
          )}

        <Grid container direction="row" alignItems="center">
          <Grid item xs={6} sm={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-between">
                <KeyboardDatePicker
                  autoOk
                  margin="normal"
                  id="date-picker-dialog"
                  label="From Date"
                  style={{ width: "90%" }}
                  value={fromDate}
                  onChange={(date) => {
                    setFromDate(date);
                    // if (date) {
                    //   setToDate(addDate(date, 30));
                    // }
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={
                    isValidValue(toDate)
                      ? subtractDate(toDate, 180)
                      : subtractDate(new Date(), 180)
                  }
                  maxDate={
                    isValidValue(toDate)
                      ? subtractDate(toDate, 1)
                      : subtractDate(new Date(), 1)
                  }
                  disableFuture
                  InputLabelProps={{ shrink: true }}
                  openTo="date"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                  views={["year", "month", "date"]}
                  error={
                    (listError &&
                      fromDate !== "" &&
                      !formatAndValidate(fromDate, toDate, 30)) ||
                    (listError && !isValidValue(fromDate)) ||
                    (isValidValue(fromDate) && !checkValid(fromDate))
                  }
                  helperText={
                    listError && !isValidValue(fromDate)
                      ? "From Date is Mandatory"
                      : isValidValue(fromDate) && !checkValid(fromDate)
                        ? "Invalid date"
                        : listError &&
                          fromDate !== "" &&
                          toDate !== "" &&
                          !formatAndValidate(fromDate, toDate, 30)
                          ? "From and To Date should be within 30 days "
                          : ""
                  }
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-between">
                <KeyboardDatePicker
                  autoOk
                  margin="normal"
                  id="date-picker-dialog"
                  label="To Date"
                  style={{ width: "90%" }}
                  value={toDate}
                  defaultValue={toDate}
                  onChange={(date) => {
                    setToDate(date);
                  }}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  disableFuture
                  minDate={
                    isValidValue(fromDate)
                      ? addDate(fromDate, 1)
                      : addDate(new Date(), 1)
                  }
                  maxDate={
                    isValidValue(fromDate)
                      ? addDate(fromDate, 30)
                      : addDate(new Date(), 30)
                  }
                  InputLabelProps={{ shrink: true }}
                  openTo="date"
                  format="dd/MM/yyyy"
                  placeholder="DD/MM/YYYY"
                  views={["year", "month", "date"]}
                  error={
                    (listError &&
                      toDate !== "" &&
                      !formatAndValidate(fromDate, toDate, 30)) ||
                    (listError && !isValidValue(toDate)) ||
                    (isValidValue(toDate) && !checkValid(toDate))
                  }
                  helperText={
                    listError && !isValidValue(toDate)
                      ? "To Date is Mandatory"
                      : isValidValue(toDate) && !checkValid(toDate)
                        ? "Invalid date"
                        : listError &&
                          fromDate !== "" &&
                          toDate !== "" &&
                          !formatAndValidate(fromDate, toDate, 30)
                          ? "From and To Date should be within 30 days "
                          : ""
                  }
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.margin}
            onClick={getDashboardData}
          >
            Go
          </Button>

          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.margin}
            onClick={exportReport}
          >
            Export
          </Button>
          {isCorporateUser && (
            <div style={{ flex: 1 }}>
              <FormControlLabel
                style={{ float: "right" }}
                control={
                  <Checkbox
                    checked={deleteChecked}
                    name="checkedA"
                    color="primary"
                    onChange={toogleDeleteChecbox}
                  />
                }
                label="View Deleted Records"
              />
            </div>
          )}
        </Grid>
      </>
    );
  }
);

export const DashboardTable = React.memo(({ data, columns, options }) => {
  return (
    <div>
      <MuiThemeProvider theme={getMuiTheme()}>
        <MUIDataTable data={data} columns={columns} options={options} />
      </MuiThemeProvider>
    </div>
  );
});

export const DeleteReasonDialog = React.memo(
  ({ showDialog, closeDialog, inwardNumber, deletedBy, deleteReason }) => {
    return (
      <Dialog
        open={showDialog}
        fullWidth={true}
        maxWidth={"sm"}
        disableEnforceFocus
      >
        <DialogContent>
          <DialogContentText style={{ textAlign: "center" }}>
            Delete Reason - {inwardNumber}
            {"   "}Deleted By - {deletedBy}
          </DialogContentText>
          <Typography variant={"h5"}> {deleteReason} </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export const defaultTableColumns = [
  {
    name: "inwardDate",
    label: "Inward\u00a0Date",
    options: {
      filter: true,
      search: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value}
          </Typography>
        );
      },
    },
  },
  {
    name: "officeCode",
    label: "Office\u00a0Code",
    options: {
      search: false,
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={false}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "officeName",
    label: "Office\u00a0Name",
    options: {
      filter: false,
      search: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "inwardType",
    label: "Inward\u00a0Type",
    options: {
      filter: true,
      search: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "customerName",
    label: "Customer\u00a0Name",
    options: {
      search: true,
      filter: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "customerMobileNumber",
    label: "Customer\u00a0Mobile\u00a0Number",
    options: {
      filter: false,
      search: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "policyNumber",
    label: "Policy\u00a0Number",
    options: {
      filter: true,
      search: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "proposalNumber",
    label: "Proposal\u00a0Number",
    options: {
      filter: true,
      search: true,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "agentName",
    label: "Agent\u00a0Name",
    options: {
      filter: true,
      search: false,
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <Typography component={"span"} noWrap={true}>
            {value || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "agentCode",
    label: "Agent\u00a0Code",
    options: {
      filter: true,
      search: false,
      customBodyRender: (value, tableMeta, updateValue) => (
        <Typography component={"span"} noWrap={true}>
          {value || "-"}
        </Typography>
      ),
    },
  },
];
