import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, makeStyles, Paper, Typography, Snackbar, Backdrop, CircularProgress, } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import { CREATE_ISSUE, UPLOAD_MEDIA } from '../../config/config.js';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { getFileExtension3 } from "../../common/getExtensionFile.js";
const issues = [
  { value: 'Not Converted to Policy', label: 'Not Converted to Policy' },
  { value: 'Proposal Creation Issue', label: 'Proposal Creation Issue' },
  { value: 'Login Issue', label: 'Login Issue' },
  { value: 'Other', label: 'Other' },
];
const purchaseTypes = [{ value: 'Self', label: 'Self' }, { value: 'Assisted', label: 'Assisted' }, { value: 'Branch', label: 'Branch' }, { value: 'Other', label: 'Other' }]

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  totalPage: {
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    marginRight: 900
  },
  input: {
    display: 'none',
  },
  createIssueFrom: {
    marginLeft: '30px',
    marginRight: '30px'
  },
  uploadFrom: {
    marginLeft: '30px',
    marginRight: '30px',
    marginTop: '30px',
    marginBottom: '30px'

  },
  createIssue: {
    padding: 25
  },
  button: {
    //margin: theme.spacing(1),
    backgroundColor: '#007bff'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
}));

export default function CreateIssue(props) {
  const classes = useStyles();
  let fileInput = {};
  fileInput.value = '';
  const [open, setOpen] = useState(false);
  const [trxnId, setTxnId] = useState('');
  const [custName, setCustomerName] = useState('');
  const [prodName, setProductName] = useState('');
  const [propNo, setProposalNo] = useState('');
  const [app, setApplication] = useState('');
  const [purchase, setPurchaseType] = useState('Self');
  const [remark, setRemarks] = useState('');
  const [issue, setIssueType] = useState('Not Converted to Policy');
  const [email, setEmail] = useState('');
  const [activeError, setActiveError] = useState(false)
  const [issueId, setIssueId] = useState(0)
  const [snackOpen, setSnackOpen] = useState(false)
  const [snack, setSnack] = useState(false)
  const [message, setMessage] = useState('')
  const [color, setColor] = useState(true)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [txnDate, setTransactionDate] = useState(null);
  const [filesList, setFilesList] = useState([])
  const [fileNames, setFileNames] = useState([])
  const [maxFileSize, setFileSize] = useState([])
  const [emailVal, setEmailVal] = useState(false)
  const [custNameVal, setCustNameVal] = useState(false)
  const handleClose = (event, reason) => {
    setSnackOpen(false)

  };

  function getFormattedString(d) {
    if (d!==null){
    return d.getFullYear() + "-" + (d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)) + "-" + (d.getDate() >= 10 ? d.getDate() : "0" + d.getDate()) + 'T' + d.toString().split(' ')[4] + ".000Z";
    }
  }
  
  const handleSubmit = (event) => {
    event.preventDefault();
    let transactionId = trxnId;
    let customerName = custName;
    let productName = prodName;
    let proposalNo = propNo;
    let application = app;
    let purchaseType = purchase;
    let agentRemarks = remark;
    let issueType = issue;
    let agentEmailId = email;
    let agentPhoneNumber = phoneNumber;
    let transactionDate = getFormattedString(txnDate);

    let createdBy = sessionStorage.getItem('userId');
    let updatedBy = sessionStorage.getItem('userId');
    setSnackOpen(false)
    setActiveError(true)

    if (customerName !== '' && customerName.length <= 40 && custNameVal && (/^([a-zA-Z]+\s)*[a-zA-Z]+$/g.test(custName)) && productName !== '' && productName.length <= 255
      && agentPhoneNumber !== '' && agentPhoneNumber.length === 10 && filesList.length <= 5 && agentEmailId !== '' && agentEmailId.length <= 40 && emailVal && (/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(email)) && transactionDate !== '' && transactionDate !== null  && (txnDate !== null && txnDate.toString() !== "Invalid Date") &&
      application !== '' && getFileMethod(filesList) && application.length <= 255 && agentRemarks !== '' && agentRemarks.length <= 255 && (proposalNo.length <= 40 || transactionId.length <= 40) && (proposalNo !== '' || transactionId !== '')) {
      setOpen(true)
      axios({
        method: 'post',
        url: CREATE_ISSUE,
        data: {
          agentRemarks,
          agentPhoneNumber,
          application,
          createdBy,
          agentEmailId,
          customerName,
          issueType,
          productName,
          proposalNo,
          purchaseType,
          transactionId,
          transactionDate,
          updatedBy
        },
        headers: { token: sessionStorage.getItem('token') }
      }).then(response => {
        if (response.data.status === "SUCCESS") {
          if (filesList.length > 0) {
            handleUploadFile(response.data.id)
          } else {
            setSnackOpen(true)
            setOpen(false)
            setMessage("Issue created Successfully")
            setSnack(true)
            setColor(true)
            setTxnId('');
            setCustomerName('')
            setProductName('')
            setProposalNo('')
            setApplication('')
            setPurchaseType('')
            setRemarks('')
            setIssueType('')
            setEmail('')
            setEmailVal(false)
            setCustNameVal(false)
            setTransactionDate(null)
            setPhoneNumber('')
            setIssueType('Not Converted to Policy')
            setPurchaseType('Self')
            setActiveError(false)
            setFilesList([])
            setFileNames([])
            setIssueId(response.data.id)
          }
        } else {
          setOpen(false)
          setSnackOpen(true)
          setMessage(response.data.msg)
          setSnack(true)
          setColor(false)

        }
      }).catch(error => {
        setOpen(false)
        if (error.response !== undefined) {
          if (error.response.status === 403) {
            alert('session expired');
            sessionStorage.clear();
            props.history.push('/');
          } else if (error.response.status === 400) {
            setSnackOpen(true)
            setMessage(error.response.data.errorMessages[0])
            setSnack(true)
            setColor(false)

          } else {
            setSnackOpen(true)
            setMessage('Something went wrong')
            setSnack(true)
            setColor(false)
          }
        }
        else {
          setSnackOpen(true)
          setMessage("Something Went Wrong")
          setSnack(true)
          setColor(false)
        }
      })
    }
  }

  const getFileMethod = (files) => {
    let flag = 0;
    files.map(item => {
      if (item.size / 1000 > 5000) {
        flag = 1;
      } else {
        flag = 0;
      }
    })
    if (flag === 0) {
      return true;
    } else {
      return false;
    }
  }

  const removeFiles = (file) => {
    var newFiles = filesList;
    var index = newFiles.indexOf(file)
    if (index !== -1) {
      newFiles.splice(index, 1)
      setFilesList([...newFiles])
    }

  }


  const handlefile = (e) => {
    let listOfNames = [];
    let listOfFiles = [];
    let fileSizes = [];
    for (var i = 0; i < e.target.files.length; ++i) {
      listOfNames.push(e.target.files[i].name)
      listOfFiles.push(e.target.files[i]);
      fileSizes.push(e.target.files[i].size / 1000);
      updateStatesMethod(listOfFiles, listOfNames, fileSizes)
    }
  }
  async function updateStatesMethod(listOfFiles, listOfNames, fileSizes) {

    await setFilesList([...filesList, ...listOfFiles])
    await setFileNames([...fileNames, ...listOfNames]);
    await setFileSize([...maxFileSize, ...fileSizes])

  }

  const handleUploadFile = (uploadId) => {
    var formData = new FormData();
    formData.append('issueId', uploadId)
    filesList.map(item => formData.append('mediaName', item))

    axios({
      method: 'post',
      url: UPLOAD_MEDIA,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      if (response && response.data.message === 'media uploaded successfully') {
        setSnackOpen(true)
        setOpen(false)
        setMessage("Issue Created Successfully with Attachments")
        setSnack(true)
        setColor(true)
        setTxnId('');
        setCustomerName('')
        setProductName('')
        setProposalNo('')
        setApplication('')
        setPurchaseType('')
        setRemarks('')
        setIssueType('')
        setEmail('')
        setEmailVal(false)
        setCustNameVal(false)
        setTransactionDate(null)
        setPhoneNumber('')
        setIssueType('Not Converted to Policy')
        setPurchaseType('Self')
        setActiveError(false)
        setFilesList([])
        setFileNames([])
        setIssueId(uploadId)
      } else {
        setOpen(false)
        setSnackOpen(true)
        setMessage("Could not upload the files")
        setSnack(true)
        setColor(false)
      }
    }).catch(error => {
        setOpen(false)
        setSnackOpen(true)
        setMessage("Could not upload the files")
        setSnack(true)
        setColor(false)
    })
  }

  useEffect(function () {
    if (sessionStorage.getItem('token') === null) {
      props.history.push('/');
    }
  }, []);

  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={open} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Box display="flex" flexDirection="row" p={2} m={1} bgcolor="#e5e8f1">
          <Typography style={{ fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Create Issue </Typography>
        </Box>
      </div>
      {snack && <Snackbar
        anchorOrigin={{
          vertical: (color) ? 'bottom' : 'top',
          horizontal: 'right',
        }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleClose}>
        <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
          {message}
        </MuiAlert>

      </Snackbar>
      }
      <Paper className={classes.createIssueFrom}>
        <form onSubmit={handleSubmit} method='post'>
          <div className={classes.createIssue}>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} id="txnId" name="txnId" value={trxnId} onChange={e => { setTxnId(e.target.value) }} label="Transaction Id" variant="outlined" error={activeError & (propNo === "" && trxnId === "") || (propNo.length > 40 && trxnId.length > 40)} helperText={activeError & (propNo === "" && trxnId === "") ? 'Transaction ID or Proposal No is Mandatory' : (propNo.length > 40 && trxnId.length > 40) ? 'Length should not exceed 40' : ' '} /> </Grid>
              <Grid item xs={12} sm={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justify="space-between">
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Transaction Date *"
                      value={txnDate}
                      onChange={date => setTransactionDate(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      disableFuture
                      openTo="year"
                      format="dd/MM/yyyy"
                      views={["year", "month", "date"]}
                      maxDate={new Date()}
                      error={(activeError & txnDate === null || txnDate > new Date() || (txnDate !== null && txnDate.toString() === "Invalid Date"))} helperText={activeError & txnDate === null ? 'Transaction Date is Mandatory' : txnDate > new Date() ? 'Invalid Date' : (txnDate !== null && txnDate.toString()) === "Invalid Date" ? 'Invalid Date Format' : ''}
                    />
                  </Grid>
                </MuiPickersUtilsProvider></Grid>
              <Grid item xs={12} sm={4}></Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} id="customerName" name="customerName" value={custName} onChange={e => { setCustomerName(e.target.value); setCustNameVal(true) }} label="Customer Name*" variant="outlined" error={(activeError & custName === "" || custName.length > 40 || custNameVal && !(/^([a-zA-Z]+\s)*[a-zA-Z]+$/g.test(custName)))} helperText={activeError & (custName.length < 0 || custName === "") ? 'Customer Name is Mandatory' : custName.length > 40 ? 'Length should not exceed 40' : custNameVal && !(/^([a-zA-Z]+\s)*[a-zA-Z]+$/g.test(custName)) ? 'Invalid Name' : ''} /></Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="productName" name="productName" value={prodName} onChange={e => setProductName(e.target.value)} label="Product Name" variant="outlined" error={(activeError & prodName === "" || prodName.length > 255)} helperText={activeError & prodName === "" ? 'Product Name is Mandatory' : prodName.length > 255 ? 'Length should not exceed 255' : ' '} /> </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} id="proposalNo" name="proposalNo" value={propNo} onChange={e => { setProposalNo(e.target.value) }} label="Proposal No" variant="outlined" error={activeError & (propNo === "" && trxnId === "") || (propNo.length > 40 && trxnId.length > 40)} helperText={activeError & (propNo === "" && trxnId === "") ? 'Proposal No or Transaction Id is Mandatory' : (propNo.length > 40 && trxnId.length > 40) ? 'Length should not exceed 40' : ' '} /> </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="application" name="application" value={app} onChange={e => setApplication(e.target.value)} label="Application" variant="outlined" error={(activeError & app === "" || app.length > 255)} helperText={activeError & app === "" ? 'Application Name is Mandatory' : app.length > 255 ? 'Length should not exceed 255' : ' '} /> </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="outlined-select-currency-native" value={issue} onChange={e => setIssueType(e.target.value)} name="issueType" select label="Issue Types" SelectProps={{ native: true, }} variant="outlined" >
                  {issues.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField> </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="Purchase-type" name="purchaseType" value={purchase} onChange={e => setPurchaseType(e.target.value)} select label="Purchase Type" SelectProps={{ native: true, }} variant="outlined" >
                  {purchaseTypes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))} </TextField> </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="email" name="email" value={email} onChange={e => { setEmail(e.target.value); setEmailVal(true) }} label="Agent Email" variant="outlined" error={(activeError && email === "" || email.length > 40 || emailVal && !(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(email)))} helperText={activeError && email === "" ? 'Email is Mandatory' : email.length > 40 ? 'Length should not exceed 40' : emailVal && !(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}/g.test(email)) ? 'Invalid Email ID' : ''} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="phoneNumber" type="number" className={classes.number} name="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} label="Agent Phone Number" variant="outlined" error={activeError & phoneNumber === "" || phoneNumber.length > 10 || (phoneNumber.length < 10 && phoneNumber.length > 0)} helperText={activeError & phoneNumber === "" ? 'Phone Number is Mandatory' : phoneNumber.length > 10 ? 'Length should not exceed 10' : (phoneNumber.length < 10 && phoneNumber.length > 0) ? 'Length should equal to 10' : ""} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField style={{ width: '100%' }} required id="remarks" name="remarks" value={remark} onChange={e => setRemarks(e.target.value)} label="Add Remarks" variant="outlined" error={(activeError & remark === "" || remark.length > 255)} helperText={activeError & remark === "" ? 'Add Remarks is Mandatory' : remark.length > 255 ? 'Length should not exceed 255' : ' '} />
              </Grid>
              <Grid item xs={12} sm={6}>
                {filesList.length > 5 ? <p style={{ color: "red" }}>Number Of Attachments should not exceed 5</p> : null}
                {filesList.map(item => item.size / 1000 > 5000 ? <p style={{ color: "red" }}>File Size should not exceed 5MB</p> : null)}
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={ref => (fileInput = ref)}
                  onChange={handlefile}
                  multiple={true}
                  accept=".png, .jfif, .pjpeg, .jpeg, .pjp, .jpg, .xls, .xlsx, .doc, .docx, .pdf, .odt"
                />

                <Button title={'click to add attachment'} variant="contained" className={classes.button} style={{ marginLeft: 0 }} color="primary" onClick={() => fileInput.click()}>Add Attachments</Button>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {filesList ? filesList.map(item => <div style={{ display: "flex", paddingTop: "20px", textAlign: "center" }}>{getFileExtension3(item.name)}<span>{item.name}</span><CloseIcon style={{ cursor: 'pointer' }} onClick={() => removeFiles(item)} /> </div>) : null}

                </div>
              </Grid>
              <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                <Button type="submit" variant="contained" onClick={handleSubmit} className={classes.button} color="primary">Create </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Paper>
    </React.Fragment>
  );
}