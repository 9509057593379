import MUIDataTable from "mui-datatables";
import { Button, Typography, Grid, Backdrop, CircularProgress, TextField, Select, MenuItem, InputLabel, FormControl, Snackbar, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";
import axios from 'axios';
import MuiAlert from '@material-ui/lab/Alert';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { PAYMENT_DASHBOARD, GET_USERS, DOWNLOAD_PROPOSAL_FORM, PAYMENT_EXPORT_ALL } from '../../config/config.js';
import { PAYMENT_EXPORT } from '../../config/config.js';
import '../dashboard/dashboard.css';
import { Link } from "@material-ui/core";
import { ViewDialog } from "./viewPayment.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        minWidth: 249,
    },
}));


const PaymentdashBoard = (props) => {
    const columns = [
        {
            name: "proposalId", label: "Proposal\u00a0Id",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="Click To View" interactive>
                            <Link onClick={() => handleViewDetails(tableMeta)} style={{ cursor: "pointer" }}>{value}</Link>
                        </Tooltip>
                    )
                }
            }
        },
        {
            name: "proposalNumber", label: "Proposal\u00a0Number",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "premiumAmount", label: "Premium\u00a0Amount",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "transactionAmount", label: "Transaction\u00a0Amount",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "paymentDate", label: "Payment\u00a0Date",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "paymentStatus", label: "Payment\u00a0Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyNumber", label: "Policy\u00a0Number",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyStatus", label: "Policy\u00a0Status",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyDate", label: "Policy\u00a0Date",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "bankTransactionId", label: "Bank\u00a0TransacionId",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "orderId", label: "Order\u00a0Id",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "policyType", label: "Policy\u00a0Type",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "product", label: "Product",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "partyCode", label: "Party\u00a0Code",
            options: {
                display: false,
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposerName", label: "Proposer\u00a0Name",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "mobileNumber", label: "Proposer\u00a0Phone",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposerEmail", label: "Proposer\u00a0Email",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Typography component={'span'} noWrap={true}>
                            {value}
                        </Typography>
                    )
                }
            }
        },
        {
            name: "proposalNumber", label: "Download",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Tooltip title="Download Proposal Form" interactive>
                            <IconButton value={value}>
                                <CloudDownloadIcon />
                            </IconButton>
                        </Tooltip>
                    )
                }
            }
        },
    ]

    const [open, setOpen] = React.useState(false);

    const classes = useStyles();
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MUIDataTableSelectCell: {
                    root: {
                        backgroundColor: "#ffff !important",
                    }
                },
                MuiTableCell: {
                    root: {
                        padding: ` 1px 10px !important`,
                        borderBottom: 'none',
                    },
                    head: {
                        height: '10px !important',
                    },
                    body: {
                        height: '10px !important',
                    },
                },
                MUIDataTableHeadCell: {
                    root: {
                        backgroundColor: "#DCDCDC !important",
                        textTransform: "uppercase",
                        fontFamily: 'FuturaLight',

                        fontSize: '0.815rem',
                    }
                },
                MuiTypography: {
                    body1: {
                        fontFamily: 'TableData',
                        fontSize: '0.815rem',

                    }
                },
            }
        });

    const [data, setData] = useState([]);

    const [user, setUser] = useState(sessionStorage.getItem('userId'));
    const [discountedReferrals, setDiscountedReferrals] = useState([]);
    const [nonDiscountedReferrals, setNonDiscountedReferrals] = useState([]);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    let mydate = new Date();
    const [fromDate, setFromDate] = useState(new Date(mydate.setMonth(mydate.getMonth(), mydate.getDate() - 3)));
    const [toDate, setToDate] = useState(new Date());
    const [showSelectUser, setShowSelect] = useState(false)
    const [value, setFilterValue] = useState('');
    const [selectCustomerDetails, setSelectCustomerDetails] = useState('');
    const [snackOpen, setSnackOpen] = useState(false)
    const [snack, setSnack] = useState(false)
    const [message, setMessage] = useState('')
    const [color, setColor] = useState(true)
    const [policyStatus, setPolicyStatus] = useState('all');
    const [viewDataObj, setViewDataObj] = useState({});
    const [openDialog, setOpenDialog] = useState(false);
    const handleClose = (event, reason) => {
        setSnackOpen(false)

    };
    const handleDialog = () => {
        setOpenDialog(false);
    }
    let startTime = ' 00:00:00';
    let endTime = ' 23:59:59';
    const customerDetails = [{ value: '', label: '' }, { value: 'CustomerName', label: 'CustomerName' }, { value: 'Email', label: 'CustomerEmail' }, { value: 'PhoneNumber', label: 'PhoneNumber' }]
    const Results = () => (
        <Grid item xs={12} sm={3}>
            <TextField style={{ width: '100%' }} id="outlined-select-currency-native" value={user} onChange={e => setUser(e.target.value)} name="issueType" select label="Select User" SelectProps={{ native: true, }} variant="standard" >
                <option></option>
                <option key={"all"} value={"all"} >
                    ALL
                </option>
                <optgroup label='DisocuntedLinks'>
                    <option key={"discounted"} value={"discounted"} >
                        ALL Discounted
                </option>
                    {discountedReferrals.sort().map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </optgroup>
                <optgroup label='NonDisocuntedLinks'>
                    <option key={"nonDiscounted"} value={"nonDiscounted"} >
                        ALL Non-Discounted
                    </option>
                    {nonDiscountedReferrals.sort().map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </optgroup>
            </TextField>
        </Grid>
    )

    useEffect(function () {
        if (sessionStorage.getItem('token') === null) {
            props.history.push('/');
        }
        if (sessionStorage.getItem('role') === 'L1User' || sessionStorage.getItem('role') === 'Admin' || sessionStorage.getItem('role') === 'L2User') {
            setShowSelect(true)
            getReferralUsers();
        } else {
            fetchPolicies();
        }
    }, []);

    const handleViewDetails = (tableMeta) => {
        let viewData = {
            proposalId: tableMeta.rowData[0],
            proposalNumber: tableMeta.rowData[1],
            premiumAmount: tableMeta.rowData[2],
            transactionAmount: tableMeta.rowData[3],
            paymentDate: tableMeta.rowData[4],
            paymentStatus: tableMeta.rowData[5],
            policyNumber: tableMeta.rowData[6],
            policyStatus: tableMeta.rowData[7],
            policyDate: tableMeta.rowData[8],
            bankTransactionId: tableMeta.rowData[9],
            orderId: tableMeta.rowData[10],
            policyType: tableMeta.rowData[11],
            product: tableMeta.rowData[12],
            partyCode: tableMeta.rowData[13],
            proposerName: tableMeta.rowData[14],
            proposerPhone: tableMeta.rowData[15],
            proposerEmail: tableMeta.rowData[16],

        }
        setOpenDialog(true);
        setViewDataObj(viewData);
    }
    const getReferralUsers = () => {
        setOpen(true)
        axios({
            method: 'get',
            url: GET_USERS,
            headers: { token: sessionStorage.getItem('token') }
        }).then(response => {
            let discountedIds = [];
            let nonDiscountedIds = [];
            if (response.data) {
                response.data.map(item => {
                    if (item.isDiscountedLink) {
                        discountedIds.push(item.referrerCode)
                    } else {
                        nonDiscountedIds.push(item.referrerCode)
                    }
                })
            }
            setDiscountedReferrals(discountedIds);
            setNonDiscountedReferrals(nonDiscountedIds);
            setOpen(false);
        }).catch(error => {
            setOpen(false);
            if (error.response !== undefined) {
                if (error.response.status === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.error)
                    setSnack(true)
                    setColor(false)
                }
            }
            else {
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }
    const cancelAction = () => {

    }
    const downloadProposalForm = (value) => {
        axios({
            method: 'get',
            url: DOWNLOAD_PROPOSAL_FORM,
            params: {
                proposalNumber: value.props.children.props.value,

            },
            headers: { token: sessionStorage.getItem('token') },
            responseType: 'blob'
        }).then(function (response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                const link = document.createElement('a');
                link.href = base64data;
                link.setAttribute('download', value.props.children.props.value + '_ProposalForm');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        }).catch(error => {
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.message)
                    setSnack(true)
                    setColor(false)
                }
                setOpen(false);
            } else {
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    
    const [activeError, setActiveError] = useState(false);
    const fetchPolicies = () => {

        let diffDays = Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24);
        if (diffDays > 180) {
            setActiveError(true);
        } else {
            setOpen(true);
            axios({
                method: 'get',
                url: PAYMENT_DASHBOARD,
                params: {
                    partyCode: user,
                    fromDate: convert(fromDate) + startTime,
                    toDate: convert(toDate) + endTime,
                    filterValue: value !== "" ? value : null,
                    filterParam: selectCustomerDetails !== "" ? selectCustomerDetails : null,
                    policyStatus: policyStatus === 'all' ? null : policyStatus
                },
                headers: { token: sessionStorage.getItem('token') }
            }).then(response => {
                setData(response.data);
                setOpen(false)
            }).catch(error => {
                setOpen(false)
                if (error.response !== undefined) {
                    if (error.response.data.code === 403) {
                        alert('session expired')
                        sessionStorage.clear();
                        props.history.push('/')
                    }
                    else {
                        setSnackOpen(true)
                        setMessage(error.response.data.message)
                        setSnack(true)
                        setColor(false)
                    }
                } else {
                    setSnackOpen(true)
                    setMessage('Something Went Wrong')
                    setSnack(true)
                    setColor(false)
                }
            })
        }
    }
    const options = {
        filterType: 'multiselect',
        rowsPerPage: 25,
        rowsPerPageOptions: [25, 40, 50],
        sort: false,
        download: false,
        viewColumns: false,
        selectableRows: 'single',
        onCellClick: (colData, cellMeta, rowData) => {
            if (cellMeta.colIndex === 17) {
                downloadProposalForm(colData);
            }
        },
        customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
            return (
                null
            );
        },

    }
    const exportFunction = () => {
        setOpen(true)
        axios({
            method: 'get',
            url: PAYMENT_EXPORT,
            params: {
                fromDate: convert(fromDate) + startTime,
                toDate: convert(toDate) + endTime,
                partyCode: user,
                filterValue: value !== "" ? value : null,
                filterParam: selectCustomerDetails !== "" ? selectCustomerDetails : null,
                policyStatus: policyStatus === 'all' ? null : policyStatus
            },
            headers: { token: sessionStorage.getItem('token') },
            responseType: 'blob'
        }).then(function (response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                const link = document.createElement('a');
                link.href = base64data;
                link.setAttribute('download', `PaymentReports_${user}_${policyStatus}_${convert(fromDate)}_${convert(toDate)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setOpen(false)
            }
        }).catch(error => {
            setOpen(false)
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.error)
                    setSnack(true)
                    setColor(false)
                }
            } else {
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }
    const goToExport = () => {
        if (user === 'all' || user === 'discounted' || user === 'nonDiscounted') {
            exportAllFunction();
        } else {
            exportFunction();
        }
    }
    const exportAllFunction = () => {
        setOpen(true)
        axios({
            method: 'get',
            url: PAYMENT_EXPORT_ALL,
            params: {
                fromDate: convert(fromDate) + startTime,
                toDate: convert(toDate) + endTime,
                linkType: user === 'all' ? null : user,
                filterValue: value !== "" ? value : null,
                filterParam: selectCustomerDetails !== "" ? selectCustomerDetails : null,
                policyStatus: policyStatus === 'all' ? null : policyStatus
            },
            headers: { token: sessionStorage.getItem('token') },
            responseType: 'blob'
        }).then(function (response) {
            var reader = new FileReader();
            reader.readAsDataURL(response.data);
            reader.onloadend = function () {
                var base64data = reader.result;
                const link = document.createElement('a');
                link.href = base64data;
                link.setAttribute('download', `PaymentReports_${user}_${policyStatus}_${convert(fromDate)}_${convert(toDate)}.xlsx`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setOpen(false)
            }
        }).catch(error => {
            setOpen(false)
            if (error.response !== undefined) {
                if (error.response.data.code === 403) {
                    alert('session expired')
                    sessionStorage.clear();
                    props.history.push('/')
                }
                else {
                    setSnackOpen(true)
                    setMessage(error.response.data.error)
                    setSnack(true)
                    setColor(false)
                }
            } else {
                setSnackOpen(true)
                setMessage('Something Went Wrong')
                setSnack(true)
                setColor(false)
            }
        })
    }

    return (
        <div id={'dashboard-mui-table'} style={{ margin: 30 }}>
            <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {snack && <Snackbar
                anchorOrigin={{
                    vertical: (color) ? 'bottom' : 'top',
                    horizontal: 'right',
                }}
                open={snackOpen}
                autoHideDuration={2000}
                onClose={handleClose}>
                <MuiAlert onClose={handleClose} elevation={6} variant="filled" severity={(color) ? 'success' : 'error'}>
                    {message}
                </MuiAlert>

            </Snackbar>
            }
            <ViewDialog
                fullScreen={fullScreen}
                openDialog={openDialog}
                handleClose={handleDialog}
                viewDataObj={viewDataObj}
            />
            <Typography style={{ textAlign: 'left', marginBottom: 18, fontFamily: "Futura", fontWeight: "400px", fontSize: "1.15rem" }} variant={'h5'}> Payment DashBoard </Typography>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                    {showSelectUser ? <Results /> : null}
                    <Grid item xs={12} sm={3}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="From Date"
                            maxDate={new Date()}
                            value={fromDate}
                            error={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 180)}
                            helperText={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 180) ? "Select dates within range of 6 months" : null}
                            onChange={date => setFromDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ marginTop: '0px', marginBottom: '0px' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="To Date"
                            maxDate={new Date()}
                            value={toDate}
                            onChange={date => setToDate(date)}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                            style={{ marginTop: '0px', marginBottom: '0px' }}
                            error={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 180)}
                            helperText={activeError & (Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24) > 180) ? "Select dates within range of 6 months" : null}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3} >
                        <TextField style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            value={policyStatus}
                            onChange={e => setPolicyStatus(e.target.value)}
                            name="policyStatus"
                            select
                            label="Select Policy Status"
                            SelectProps={{ native: true, }}
                            variant="standard">
                            <option key="all" value="all">ALL</option>
                            <option key="converted" value="converted">Converted</option>
                            <option key="nonConverted" value="nonConverted">Non Converted</option>
                        </TextField>
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
            <div style={{ paddingBottom: 10 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} >
                        {!showSelectUser ? <TextField style={{ width: '100%' }} id="outlined-select-currency-native" value={selectCustomerDetails} onChange={e => setSelectCustomerDetails(e.target.value)} name="selectCustomerDetails" select label="Search By" SelectProps={{ native: true, }} variant="standard" >
                            {
                                customerDetails.map((option) => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                ))
                            }
                        </TextField> : null}
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {!showSelectUser ? <TextField
                            style={{ width: '235px' }}
                            id="filterValue"
                            name="filterValue"
                            value={value}
                            onChange={e => setFilterValue(e.target.value)}
                            label="FilterValue"
                            variant="standard" >
                        </TextField> : null}
                    </Grid>
                    {sessionStorage.getItem('role') === 'Referral' ? null : <Grid item xs={12} sm={3}></Grid>}
                    <Grid item xs={12} sm={1}>
                        <Button
                            style={{ marginTop: '12px' }}
                            type="submit"
                            variant="contained"
                            className={classes.button}
                            onClick={fetchPolicies}
                            disabled={user === 'all' || user === 'discounted' || user === 'nonDiscounted' ? true : false}
                            color="primary">Go </Button>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Button
                            style={{ marginTop: '12px', marginLeft: '25px' }}
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<CloudDownloadIcon />}
                            onClick={goToExport}
                        >
                            EXPORT</Button></Grid>
                </Grid>
            </div>

            <div >
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={options} />
                </MuiThemeProvider>
            </div>
        </div>
    )
}

export default PaymentdashBoard;